import { Box, Stack } from "@mui/material";
import React, { FC } from "react";

import { Button } from "~/components/uiParts";

type Props = {
  editing: boolean;
  submitting: boolean;
  isSubmitDisabled?: boolean;
  renderEditor: () => React.ReactNode;
  renderViewer: () => React.ReactNode;
  onClickEditButton: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  editButtonText: string;
};

export const ViewerAndEditorManager: FC<Props> = ({
  editing,
  renderEditor,
  renderViewer,
  submitting,
  isSubmitDisabled,
  onClickEditButton,
  onCancel,
  onSubmit,
  editButtonText,
}) => {
  return (
    <Stack rowGap="16px" alignItems="start">
      {editing ? renderEditor() : renderViewer()}
      {editing ? (
        <EditModeButtons
          onCancel={onCancel}
          submitting={submitting}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
        />
      ) : (
        <ViewerModeButton onClickEditButton={onClickEditButton} editButtonText={editButtonText} />
      )}
    </Stack>
  );
};

const EditModeButtons = ({
  onCancel,
  submitting,
  isSubmitDisabled,
  onSubmit,
}: Pick<Props, "onCancel" | "submitting" | "isSubmitDisabled" | "onSubmit">) => (
  <Box display="flex" columnGap="16px">
    <Button
      variant="outlined"
      borderRadius="circle"
      color="default"
      onClick={onCancel}
      disabled={submitting}
    >
      キャンセル
    </Button>
    <Button
      variant="contained"
      borderRadius="circle"
      color="primary"
      onClick={onSubmit}
      disabled={submitting || isSubmitDisabled}
    >
      設定保存
    </Button>
  </Box>
);

const ViewerModeButton = ({
  onClickEditButton,
  editButtonText,
}: Pick<Props, "onClickEditButton" | "editButtonText">) => (
  <Button color="primary" variant="outlined" onClick={onClickEditButton} borderRadius="circle">
    {editButtonText}
  </Button>
);
