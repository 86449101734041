import { MultipleSelectField } from "../../types";
import { BaseOnnEventEvaluationField } from "../BaseOnnEventEvaluationField/BaseOnnEventEvaluationField";

import { OnnEventEvaluationMultipleSelectOption } from "./OnnEventEvaluationMultipleSelectOption";

import {
  IOnnEventEvaluationMultipleSelectField,
  onnEventEvaluationMultipleSelectFieldSchema,
} from "./schema";

export class OnnEventEvaluationMultipleSelectField
  extends BaseOnnEventEvaluationField
  implements IOnnEventEvaluationMultipleSelectField
{
  static validator = onnEventEvaluationMultipleSelectFieldSchema;

  static readonly type = MultipleSelectField;
  type = OnnEventEvaluationMultipleSelectField.type;

  options: OnnEventEvaluationMultipleSelectOption[];

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationMultipleSelectField>, "type">) {
    const parsedInit = OnnEventEvaluationMultipleSelectField.validator.parse(init);

    super(parsedInit);

    this.type = OnnEventEvaluationMultipleSelectField.type;

    this.options = parsedInit.options.map(
      (options) => new OnnEventEvaluationMultipleSelectOption(options)
    );
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationMultipleSelectField>,
      "createdAt" | "updatedAt" | "options"
    > & { options: Array<Pick<OnnEventEvaluationMultipleSelectOption, "id" | "label">> }
  ): OnnEventEvaluationMultipleSelectField {
    return new OnnEventEvaluationMultipleSelectField({
      ...params,
      id: params.id,
      options: params.options.map((option, index) =>
        OnnEventEvaluationMultipleSelectOption.createNewWithGeneratedId({
          ...option,
          tenantId: params.tenantId,
          onnEventEvaluationMultipleSelectFieldId: params.id,
          order: index + 1, // 1から始まる
        })
      ),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationMultipleSelectField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt" | "options"
      >
    > & {
      options: Array<Pick<OnnEventEvaluationMultipleSelectOption, "id" | "label">>;
    }
  ): OnnEventEvaluationMultipleSelectField {
    return new OnnEventEvaluationMultipleSelectField({
      ...this,
      ...params,
      options: params.options.map((option, index) => {
        const order = index + 1; // 1から始まる
        const sameOption = this.options.find((o) => o.id === option.id);

        return sameOption
          ? sameOption.update({ label: option.label, order })
          : OnnEventEvaluationMultipleSelectOption.createNewWithGeneratedId({
              id: option.id,
              onnEventEvaluationMultipleSelectFieldId: this.id,
              tenantId: this.tenantId,
              label: option.label,
              order,
            });
      }),
      updatedAt: new Date(),
    });
  }
}
