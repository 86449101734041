import { v4 } from "uuid";

import { MultipleSelectField } from "../../types";

import {
  IOnnEventEvaluationMultipleSelectValue,
  onnEventEvaluationMultipleSelectValueSchema,
} from "./schema";

export class OnnEventEvaluationMultipleSelectValue
  implements IOnnEventEvaluationMultipleSelectValue
{
  static validator = onnEventEvaluationMultipleSelectValueSchema;
  type = MultipleSelectField;
  id: string;
  tenantId: string;
  onnEventEvaluationMultipleSelectFieldId: string;
  onnEventEvaluationMultipleSelectOptionId: string;

  employeeId: string;
  createdEmployeeId?: string;
  updatedEmployeeId?: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationMultipleSelectValue>, "type">) {
    const parsedInit = OnnEventEvaluationMultipleSelectValue.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationMultipleSelectFieldId =
      parsedInit.onnEventEvaluationMultipleSelectFieldId;
    this.onnEventEvaluationMultipleSelectOptionId =
      parsedInit.onnEventEvaluationMultipleSelectOptionId;
    this.employeeId = parsedInit.employeeId;
    this.createdEmployeeId = parsedInit.createdEmployeeId;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドバリューデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationMultipleSelectValue>,
      "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationMultipleSelectValue {
    return new OnnEventEvaluationMultipleSelectValue({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationMultipleSelectValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationMultipleSelectValue {
    return new OnnEventEvaluationMultipleSelectValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<OnnEventEvaluationMultipleSelectValue, "updatedEmployeeId">
  ): OnnEventEvaluationMultipleSelectValue {
    return new OnnEventEvaluationMultipleSelectValue({
      ...this,
      updatedEmployeeId: params.updatedEmployeeId,
      updatedAt: new Date(),
    });
  }

  getFieldId(): string {
    return this.onnEventEvaluationMultipleSelectFieldId;
  }
}
