import { useState } from "react";

import { useSearchNewGraduateIds } from "~/hooks/employee";
import { useSearchNewGraduatesPerPage } from "~/hooks/employee/useSearchNewGraduatesPerPage";
import { useDebouncedCallback } from "~/hooks/shared";
import { useNewGraduateSearchState } from "~/hooks/shared/useNewGraduateSearchState";

export const useNewGraduateTable = () => {
  const {
    conditions,
    validConditions,
    logicType,
    validConditionsCount,
    keywordsString: _keywordsString,
    keywordsStringSearchErrorText,
    onResetSearchConditions,
    onSearchConfirm,
    onChangeKeywordsString: _onChangeKeywordsString,
  } = useNewGraduateSearchState();

  // NOTE: API連打を回避するためフリーテキスト検索はDebounceを挟む
  const [keywordsString, setKeywordsString] = useState("");
  const handleChangeKeywordsString = useDebouncedCallback(_onChangeKeywordsString, 500);
  const onChangeKeywordsString = (value: string) => {
    setKeywordsString(value);
    handleChangeKeywordsString(value);
  };

  const { data: newGraduatesSearchIds, mutate: mutateSearchNewGraduateIds } =
    useSearchNewGraduateIds({
      type: logicType,
      conditions: validConditions,
      keywordsString: _keywordsString,
    });

  const {
    data: searchNewGraduatesPerPageData,
    loadNextPage,
    mutate: mutateAllPagesOfSearchNewGraduatesPerPageData,
    mutateWithOptimisticUpdate,
  } = useSearchNewGraduatesPerPage({
    conditions: validConditions,
    logicType,
    keywordsString: _keywordsString,
    onFetch: () => {
      mutateSearchNewGraduateIds();
    },
  });

  return {
    conditions,
    logicType,
    keywordsString,
    keywordsStringSearchErrorText,
    searchNewGraduatesPerPageData,
    searchResultAllIds: newGraduatesSearchIds,
    searchResultCount: newGraduatesSearchIds?.size,
    validConditionsCount,
    onSearchConfirm,
    onResetSearchConditions,
    onChangeKeywordsString,
    onLoadMore: () => {
      loadNextPage();
    },
    mutateAllPagesOfSearchNewGraduatesPerPage: {
      mutate: mutateAllPagesOfSearchNewGraduatesPerPageData,
      mutateWithOptimisticUpdate,
    },
  };
};

export type MutateSearchNewGraduatesPerPageFunctions = {
  mutateAllPagesOfSearchNewGraduatesPerPage: {
    mutate: ReturnType<typeof useSearchNewGraduatesPerPage>["mutate"];
    mutateWithOptimisticUpdate: ReturnType<
      typeof useSearchNewGraduatesPerPage
    >["mutateWithOptimisticUpdate"];
  };
};
