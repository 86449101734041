import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEvent, OnnEventEvaluationFileField, OnnEventEvaluationRankV2 } from "@onn/common";

import { UseFormReturn, useForm as useRHF } from "react-hook-form";

import { useNavigate, useSearchParams } from "react-router-dom";

import { AnyOnnEventEvaluationFieldWithValueCount } from "../../types";

import { InputState } from "./InputState";
import { generateFormSchema } from "./schema";

import { useGenerateDefaultValue } from "./useGenerateDefaultValue";

import { useModal } from "~/hooks/modal";
import { useCheckIfOnnEventEvaluationSettingsToDeleteAlreadyUsed } from "~/hooks/onnEventEvaluation/useCheckIfOnnEventEvaluationSettingsToDeleteAlreadyUsed";
import { useEditOnnEventEvaluationSetting } from "~/hooks/onnEventEvaluation/useEditOnnEventEvaluationSetting";
import { useMutateOnnEventEvaluationRanksPerOnnEvent } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanksPerOnnEvent";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useForm = ({
  onnEventEvaluationRanks,
  onnEventEvaluationFields,
  onnEventId,
  onnEventEvaluationFileField,
  onnEventType,
}: {
  onnEventEvaluationRanks: OnnEventEvaluationRankV2[];
  onnEventEvaluationFields: AnyOnnEventEvaluationFieldWithValueCount[];
  onnEventId: string;
  onnEventEvaluationFileField?: OnnEventEvaluationFileField;
  onnEventType: OnnEvent["type"];
}) => {
  const isFromCreatePage = useIsFromCreatePage();
  const { generateDefaultValues } = useGenerateDefaultValue();

  const form = useRHF<InputState>({
    defaultValues: generateDefaultValues(onnEventEvaluationFields, onnEventEvaluationRanks),
    resolver: zodResolver(generateFormSchema()),
    mode: "all",
  });

  const { submit } = useSubmit({
    form,
    onnEventId,
    onnEventEvaluationFileField,
    onnEventType,
  });

  const { isSubmitting, isDirty, isValid } = form.formState;
  const isSubmitButtonDisabled =
    isSubmitting ||
    !isValid ||
    // NOTE: 新規作成画面からの遷移時はフォームに変更点がなくても常にtrue
    (isFromCreatePage ? false : !isDirty);

  return {
    RHForm: form,
    isSubmitButtonDisabled,
    submit,
  };
};

const useSubmit = ({
  form,
  onnEventId,
  onnEventEvaluationFileField,
  onnEventType,
}: {
  form: UseFormReturn<InputState>;
  onnEventId: string;
  onnEventEvaluationFileField?: OnnEventEvaluationFileField;
  onnEventType: OnnEvent["type"];
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const isFromCreatePage = useIsFromCreatePage();

  const { checkIfOnnEventEvaluationSettingsToDeleteAlreadyUsed } =
    useCheckIfOnnEventEvaluationSettingsToDeleteAlreadyUsed();
  const { mutateOnnEventEvaluationRanksPerOnnEvent } =
    useMutateOnnEventEvaluationRanksPerOnnEvent();
  const { editOnnEventEvaluationSetting } = useEditOnnEventEvaluationSetting();
  const submit = form.handleSubmit(async (inputData) => {
    const onnEventEvaluationRanks = inputData.evaluationRanks.map((rank) => {
      return {
        id: rank.id,
        label: rank.label,
      };
    });
    const onnEventEvaluationForm = {
      fields: [
        ...inputData.evaluationFields.flatMap((field) => {
          switch (field.type) {
            case "TextField": {
              return {
                ...field,
                inputTemplate: field.inputTemplate || "",
              };
            }
            case "MultipleSelectField":
            case "SingleSelectField": {
              return {
                ...field,
                options: field.options.map((o) => {
                  return {
                    id: o.optionId,
                    label: o.label,
                  };
                }),
              };
            }
            case "FileField": {
              // NOTE: ファイルタイプは固定値なのでRHFでは管理しない
              return [];
            }
            default: {
              const _exhaustiveCheck: never = field;
              return _exhaustiveCheck;
            }
          }
        }),
        ...[onnEventEvaluationFileField || []],
      ].flat(),
    };
    const editEvaluationSetting = async () => {
      try {
        await editOnnEventEvaluationSetting({
          onnEventId,
          onnEventEvaluationSetting: {
            onnEventEvaluationRanks,
            onnEventEvaluationForm,
          },
        });
        enqueueSnackbar("イベント評価設定を更新しました。", { variant: "success" });
        mutateOnnEventEvaluationRanksPerOnnEvent({ onnEventId });
        // NOTE: 通常イベントの場合、イベント作成時は配信設定画面に遷移する
        if (onnEventType === "normal" && isFromCreatePage) {
          navigation(`/events/${onnEventId}/delivery_setting?from_page=create`);
        } else {
          navigation(`/events/${onnEventId}`);
        }
      } catch (_) {
        enqueueSnackbar("イベント評価設定の更新に失敗しました。", { variant: "error" });
        captureException({
          error: new Error("イベント評価設定の更新に失敗しました。"),
          extras: {
            onnEventId,
            inputData,
          },
          tags: {
            type: "submit",
          },
        });
      }
    };

    const { isAlreadyUsed } = await checkIfOnnEventEvaluationSettingsToDeleteAlreadyUsed({
      onnEventId,
      onnEventEvaluationFormInput: onnEventEvaluationForm,
    });

    if (isAlreadyUsed) {
      handleModal({
        name: "confirmModal",
        args: {
          title: "評価フォーム削除",
          onClickAccept: editEvaluationSetting,
          mainContent:
            "削除した項目・選択肢はすでに評価に使われています。\n変更を保存する場合、該当のデータが候補者の評価データから失われます。",
          acceptButtonColor: "secondary",
          subContent: `この操作は取り消すことができません。`,
        },
      });
      return;
    }

    editEvaluationSetting();
    return;
  });

  return {
    submit,
  };
};

const useIsFromCreatePage = () => {
  const [searchParams] = useSearchParams();

  const fromPage = searchParams.get("from_page");

  return fromPage === "create";
};
