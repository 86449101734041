import { ListItemIcon, ListItemText, ListItem as MuiListItem, Tooltip } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Chip, Icon, Typography } from "~/components/uiParts";

type CustomProps = {
  label: string;
  to: string;
  badgeLabel?: string;
  targetBlank?: boolean;
  externalLink?: boolean;
  onClick?: () => void;
  isAccessible?: boolean;
} & Partial<Pick<ComponentProps<typeof Icon>, "icon">>;

export const ListItem: FC<CustomProps> = React.memo(({ isAccessible = true, ...props }) => {
  const StyledListItemComponent = React.memo(() => {
    return (
      <StyledListItem button onClick={props.onClick} disabled={!isAccessible}>
        {props.icon && (
          <ListItemIcon>
            <Icon icon={props.icon} size="md" color="grey" />
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            <Typography variant="body1" bold color="textSecondary">
              {props.label}
              {props.badgeLabel && <StyledChip color="secondary" label={props.badgeLabel} bold />}
            </Typography>
          }
        />
      </StyledListItem>
    );
  });

  return (
    <Tooltip
      title={isAccessible ? "" : "権限がありません。アクセスするには管理者に連絡してください"}
      placement="left"
      arrow
    >
      <span>
        {props.externalLink ? (
          <StyledAnchor
            href={props.to}
            target={props.targetBlank ? "_blank" : ""}
            $isAccessible={isAccessible}
          >
            <StyledListItemComponent />
          </StyledAnchor>
        ) : (
          <StyledLink
            to={props.to}
            target={props.targetBlank ? "_blank" : ""}
            $isAccessible={isAccessible}
          >
            <StyledListItemComponent />
          </StyledLink>
        )}
      </span>
    </Tooltip>
  );
});

const StyledAnchor = styled.a<{ $isAccessible: boolean }>`
  text-decoration: none;
  color: inherit;
  pointer-events: ${({ $isAccessible }) => ($isAccessible ? "auto" : "none")};
`;

const StyledLink = styled(Link)<{ $isAccessible: boolean }>`
  text-decoration: none;
  color: inherit;
  pointer-events: ${({ $isAccessible }) => ($isAccessible ? "auto" : "none")};
`;

const StyledListItem = styled(MuiListItem)`
  &.MuiButtonBase-root.MuiListItem-root {
    height: 40px;
    padding: 8px;
  }
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: fit-content;
    width: fit-content;
    padding: 0 8px;
    margin-left: 8px;
    border-radius: 8px;

    & > .MuiChip-label {
      padding: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
