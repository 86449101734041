import {
  RecruitmentProcessConditionFieldKey,
  RecruitmentStatusConditionFieldKey,
} from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import { GroupKey, MenuItem, SelectedFieldKey } from "./type";
import {
  convertFieldToRequest,
  initSelectableFieldsMap,
  initSelectedFieldsMap,
  isNestedField,
} from "./utils";

import { EmployeeInformationGroupData } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

export const useFieldSelection = (employeeInformationGroupData: EmployeeInformationGroupData) => {
  const [selectableFieldsMap] = useState<Record<GroupKey, MenuItem[]>>(
    initSelectableFieldsMap(employeeInformationGroupData)
  );
  const [selectedFieldsMap, setSelectedFieldsMap] = useState<Map<SelectedFieldKey, boolean>>(
    initSelectedFieldsMap(employeeInformationGroupData)
  );

  const selectAll = useCallback(
    (groupKey: GroupKey) => {
      const targetFieldGroup = selectableFieldsMap[groupKey];
      if (!targetFieldGroup) return;
      setSelectedFieldsMap((prev) => {
        const newMap = new Map(prev);
        targetFieldGroup.forEach((field) => {
          isNestedField(field)
            ? field.detailItems.forEach((v) => newMap.set(v.key, true))
            : newMap.set(field.key, true);
        });
        return newMap;
      });
    },
    [selectableFieldsMap]
  );

  const unselectAll = useCallback(
    (groupKey: GroupKey) => {
      const targetFieldGroup = selectableFieldsMap[groupKey];
      if (!targetFieldGroup) return;
      setSelectedFieldsMap((prev) => {
        const newMap = new Map(prev);
        targetFieldGroup.forEach((field) => {
          isNestedField(field)
            ? field.detailItems.forEach((v) => newMap.set(v.key, false))
            : newMap.set(field.key, false);
        });
        return newMap;
      });
    },
    [selectableFieldsMap]
  );

  const toggleIsSelected = useCallback((key: SelectedFieldKey) => {
    setSelectedFieldsMap((prev) => {
      const newMap = new Map(prev);
      newMap.set(key, !newMap.get(key));
      return newMap;
    });
  }, []);

  const selectAllDetailItems = useCallback(
    (
      detailItemKeys: Array<
        RecruitmentStatusConditionFieldKey | RecruitmentProcessConditionFieldKey
      >
    ) => {
      setSelectedFieldsMap((prev) => {
        const newMap = new Map(prev);
        detailItemKeys.forEach((key) => {
          newMap.set(key, true);
        });
        return newMap;
      });
    },
    []
  );

  const unselectAllDetailItems = useCallback(
    (
      detailItemKeys: Array<
        RecruitmentStatusConditionFieldKey | RecruitmentProcessConditionFieldKey
      >
    ) => {
      setSelectedFieldsMap((prev) => {
        const newMap = new Map(prev);
        detailItemKeys.forEach((key) => {
          newMap.set(key, false);
        });
        return newMap;
      });
    },
    []
  );

  const selectedFieldKeys = Array.from(selectedFieldsMap.entries())
    .filter(([, isSelected]) => isSelected)
    .map(([key]) => key);

  const convertedFieldToRequest = useMemo(() => {
    return convertFieldToRequest({
      selectableFieldsMap,
      selectedFieldsMap,
    });
  }, [selectableFieldsMap, selectedFieldsMap]);

  return {
    selectableFieldsMap,
    selectedFieldsMap,
    selectedFieldKeys,
    selectAll,
    unselectAll,
    selectAllDetailItems,
    unselectAllDetailItems,
    convertedFieldToRequest,
    toggleIsSelected,
    isNestedField,
  };
};
