import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

export const useDisplayScenariosWithRecruitmentStatuses = (includeRejectedAndWithdrew: boolean) => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const displayScenariosWithRecruitmentStatuses = useMemo(() => {
    return scenariosWithRecruitmentStatuses.flatMap((scenarioWithRecruitmentStatuses) => {
      const { recruitmentStatuses } = scenarioWithRecruitmentStatuses;
      const filteredStatuses = includeRejectedAndWithdrew
        ? recruitmentStatuses
        : recruitmentStatuses.filter(
            (recruitmentStatus) =>
              recruitmentStatus.type !== "rejected" && recruitmentStatus.type !== "withdrew"
          );

      // NOTE: 一つも表示するステータスがない場合は表示しない
      if (isEmpty(filteredStatuses)) {
        return [];
      }

      return {
        scenario: scenarioWithRecruitmentStatuses.scenario,
        recruitmentStatuses: filteredStatuses,
      };
    });
  }, [includeRejectedAndWithdrew, scenariosWithRecruitmentStatuses]);

  return { displayScenariosWithRecruitmentStatuses };
};
