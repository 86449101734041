import { useCallback } from "react";

import { DropResult } from "react-beautiful-dnd";
import { useFieldArray } from "react-hook-form";

import { v4 } from "uuid";

/**
 * 評価項目フィールドの操作に関するカスタムフック
 * - 評価項目の追加
 * - 評価項目の削除
 * - 評価項目のドラッグアンドドロップ
 */
export const useOnnEventRankArray = () => {
  const {
    move: moveRanks,
    append: appendRanks,
    remove: removeRanks,
    fields,
  } = useFieldArray({
    name: "evaluationRanks",
    keyName: "_id",
  });

  const onAddRank = useCallback(() => {
    appendRanks({
      id: v4(),
      label: "",
    });
  }, [appendRanks]);

  const onRemoveRank = useCallback(
    (fieldIndex: number) => {
      removeRanks(fieldIndex);
    },
    [removeRanks]
  );

  const onDragRankEnd = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      moveRanks(sourceIndex, destinationIndex);
    },
    [moveRanks]
  );

  return {
    onAddRank,
    onRemoveRank,
    onDragRankEnd,

    fields,
  };
};
