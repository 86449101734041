import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { SystemRegisteredDateRangeCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { CommonProps } from "./type";

import { DatePickerV2, Typography } from "~/components/uiParts";

type Props = CommonProps<SystemRegisteredDateRangeCondition>;

const TARGET = "systemRegisteredDateRange";

export const SystemRegisteredDateRangeConditionSelector = ({
  index,
  condition,
  onChangeTarget,
  onChangeCondition,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
        />
      </Box>
      <Box
        flex={9}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gridGap: "16px",
        }}
      >
        <StyledDatePicker
          fullWidth
          placeholder="開始日"
          value={condition.fromDate || null}
          onChange={(date) => {
            onChangeCondition(index, {
              target: "systemRegisteredDateRange",
              fromDate: date || undefined,
              untilDate: condition.untilDate,
            });
          }}
        />
        <Box>
          <Typography color={"textSecondary"}>〜</Typography>
        </Box>
        <StyledDatePicker
          fullWidth
          placeholder="終了日"
          value={condition.untilDate || null}
          onChange={(date) => {
            onChangeCondition(index, {
              target: "systemRegisteredDateRange",
              fromDate: condition.fromDate,
              untilDate: date || undefined,
            });
          }}
        />
      </Box>
    </Box>
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    padding: 8px 12px 8px 12px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;
