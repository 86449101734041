import { RecruitmentStatus } from "./RecruitmentStatus";

export class RecruitmentStatusWithRelatedInfo extends RecruitmentStatus {
  isRelatedEmployee: boolean;
  isRelatedScenario: boolean;
  isRelatedTriggerForDeletedStatus: boolean;
  isRelatedActionForDeletedStatus: boolean;
  relatedNumberOfEmployee: number;
  constructor(init: ExcludeMethods<RecruitmentStatusWithRelatedInfo>) {
    super(init);
    this.isRelatedEmployee = init.isRelatedEmployee;
    this.isRelatedScenario = init.isRelatedScenario;
    this.isRelatedTriggerForDeletedStatus = init.isRelatedTriggerForDeletedStatus;
    this.isRelatedActionForDeletedStatus = init.isRelatedActionForDeletedStatus;
    this.relatedNumberOfEmployee = init.relatedNumberOfEmployee;
  }

  static convertRecruitmentStatusToWithRelatedInfo(recruitmentStatus: RecruitmentStatus) {
    return new RecruitmentStatusWithRelatedInfo({
      ...recruitmentStatus,
      isRelatedEmployee: false,
      isRelatedScenario: false,
      isRelatedTriggerForDeletedStatus: false,
      isRelatedActionForDeletedStatus: false,
      relatedNumberOfEmployee: 0,
    });
  }
}
