import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { AnyOnnEventEvaluationField, OnnEventEvaluationRankV2 } from "@onn/common";
import { captureException } from "@sentry/react";
import React, { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { FieldValues } from "../hooks/form/FieldValues";

import { OnnEventEvaluationFieldAndValue } from "./components/OnnEventEvaluationFieldAndValue";

import { OnnEventEvaluationRankSelector } from "./components/OnnEventEvaluationRankSelector";

import { Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";

export const EditOnnEventEvaluationContent: FC<{
  onnEventEvaluationSetting: {
    onnEventEvaluationRanks: OnnEventEvaluationRankV2[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  isUploadingFile: boolean;
  setIsUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  sumHeightOfHeaderAndFooter?: number; // スクロール時のずれを調整するための px
}> = ({
  onnEventEvaluationSetting: { onnEventEvaluationRanks, onnEventEvaluationFields },
  isUploadingFile,
  setIsUploadingFile,
  sumHeightOfHeaderAndFooter = 400,
}) => {
  const { control } = useFormContext<FieldValues>();
  const { fields: evaluationRHFFields } = useFieldArray<FieldValues>({
    control,
    name: "onnEventEvaluationValues",
  });

  return (
    <>
      <Stack spacing={"40px"}>
        <Stack spacing={"24px"}>
          <Stack
            spacing={"24px"}
            pr={"16px"} // NOTE: スクロールバーが表示されるとスクロールバーとの距離が近すぎため、右側にpaddingを追加
            sx={{
              overflowY: "scroll",
              maxHeight: `calc(100vh - ${sumHeightOfHeaderAndFooter}px)`,
            }}
          >
            <Box width={"212px"} mt="8px">
              <Box display="flex" alignItems="center" gridColumnGap={8} mb="8px">
                <Typography variant="body2" color="textPrimary" bold>
                  評価ランク
                </Typography>
                <Tag color="secondary" size="sm" text="必須" />
              </Box>
              <OnnEventEvaluationRankSelector
                selectableOnnEventEvaluationRanks={onnEventEvaluationRanks}
              />
            </Box>
            <Stack spacing={"16px"}>
              {evaluationRHFFields.map((evaluationRHFField, index) => {
                const onnEventEvaluationFieldId = evaluationRHFField.fieldId;
                const onnEventEvaluationField = onnEventEvaluationFields.find(
                  (field) => field.id === onnEventEvaluationFieldId
                );
                if (!onnEventEvaluationField) {
                  // NOTE: フロント側のバグなのでエラー通知する
                  captureException({
                    error: new Error("評価フィールドが見つかりませんでした"),
                    tags: { type: "EditOnnEventEvaluationContent" },
                    extras: { onnEventEvaluationFieldId },
                  });
                  return null;
                }
                return (
                  <OnnEventEvaluationFieldAndValue
                    key={evaluationRHFField.id}
                    fieldIndex={index}
                    onnEventEvaluationField={onnEventEvaluationField}
                    isUploadingFile={isUploadingFile}
                    setIsUploadingFile={setIsUploadingFile}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
