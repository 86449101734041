import { z } from "zod";

import { OnnEventEvaluationDraftSchema } from "../../../_gen/zodSchema/index";
import { onnEventEvaluationFileValueSchema } from "../OnnEventEvaluationForm/OnnEventEvaluationValue/OnnEventEvaluationFileValue";
import { onnEventEvaluationMultipleSelectValueSchema } from "../OnnEventEvaluationForm/OnnEventEvaluationValue/OnnEventEvaluationMultipleSelectValue";
import { onnEventEvaluationSingleSelectValueSchema } from "../OnnEventEvaluationForm/OnnEventEvaluationValue/OnnEventEvaluationSingleSelectValue";
import { onnEventEvaluationTextValueSchema } from "../OnnEventEvaluationForm/OnnEventEvaluationValue/OnnEventEvaluationTextValue";
import {
  FileField,
  MultipleSelectField,
  SingleSelectField,
  TextField,
} from "../OnnEventEvaluationForm/types";

const onnEventEvaluationValuesCommonSchema = z.object({
  fieldId: z.string(),
});

export const onnEventEvaluationTextFieldValueDraftSchema = z
  .object({ type: z.literal(TextField) })
  .merge(onnEventEvaluationValuesCommonSchema)
  .merge(onnEventEvaluationTextValueSchema.pick({ value: true }));

export const onnEventEvaluationFileFieldValueDraftSchema = z
  .object({ type: z.literal(FileField) })
  .merge(onnEventEvaluationValuesCommonSchema)
  .merge(onnEventEvaluationFileValueSchema.pick({ filePaths: true }));

export const onnEventEvaluationSingleSelectFieldValueDraftSchema = z
  .object({ type: z.literal(SingleSelectField) })
  .merge(onnEventEvaluationValuesCommonSchema)
  .merge(
    onnEventEvaluationSingleSelectValueSchema.pick({
      onnEventEvaluationSingleSelectOptionId: true,
    })
  );

export const onnEventEvaluationMultipleSelectFieldValueDraftSchema = z
  .object({ type: z.literal(MultipleSelectField) })
  .merge(onnEventEvaluationValuesCommonSchema)
  .merge(
    onnEventEvaluationMultipleSelectValueSchema.pick({
      onnEventEvaluationMultipleSelectOptionId: true,
    })
  );

const onnEventEvaluationValueDraftSchema = z.union([
  onnEventEvaluationTextFieldValueDraftSchema,
  onnEventEvaluationFileFieldValueDraftSchema,
  onnEventEvaluationSingleSelectFieldValueDraftSchema,
  onnEventEvaluationMultipleSelectFieldValueDraftSchema,
]);

export const onnEventEvaluationDraftSchema = OnnEventEvaluationDraftSchema.merge(
  z.object({
    onnEventEvaluationRankId: z.string().optional(),
    onnEventEvaluationValues: z.array(onnEventEvaluationValueDraftSchema),
  })
);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationDraft extends z.infer<typeof onnEventEvaluationDraftSchema> {}

export type OnnEventEvaluationValueDraft = z.infer<typeof onnEventEvaluationValueDraftSchema>;
export type OnnEventEvaluationTextFieldValueDraft = z.infer<
  typeof onnEventEvaluationTextFieldValueDraftSchema
>;
export type OnnEventEvaluationFileFieldValueDraft = z.infer<
  typeof onnEventEvaluationFileFieldValueDraftSchema
>;
export type OnnEventEvaluationSingleSelectFieldValueDraft = z.infer<
  typeof onnEventEvaluationSingleSelectFieldValueDraftSchema
>;
export type OnnEventEvaluationMultipleSelectFieldValueDraft = z.infer<
  typeof onnEventEvaluationMultipleSelectFieldValueDraftSchema
>;
