import { Box } from "@material-ui/core";
import {
  AllContactRoom,
  ContactMessage,
  ContactMessageDraft,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  Employee,
} from "@onn/common";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { KeyedMutator } from "swr";

import { ContactMessageTemplateMenu, NewGraduateSidebar } from "../parts";

import { useContactMessageTemplatesMenuHandling } from "../parts/ContactMessageTemplateMenu/useContactMessageTemplatesMenuHandling";
import { LineStampPopover } from "../parts/LineStampPopover";

import {
  ContactMessagesForDisplay,
  MessageListView,
} from "../parts/MessageListView/MessageListView";

import { useContactRoomPin } from "../parts/useContactRoomPin";

import { useContactMessagesAndLogs } from "./useContactMessagesOrLogs";

import { useLatestAlreadyReadUsersInfoV2 } from "~/components/domains/contactRooms/hooks";

import {
  Button,
  Icon,
  IconButton,
  Loading,
  TextareaAutosizeAttachableFile,
  Toggle,
  Typography,
  UserIcon,
  UserIconMenu,
} from "~/components/uiParts";
import { useOptimisticCreateMessage } from "~/hooks/contactMessage";
import { usePostLineStampMessage } from "~/hooks/contactMessage/usePostLineStampMessage";
import {
  useContactRoom,
  useUpdateAssigneeAndFollowers,
  useUpdateReadLogOfContactRoom,
} from "~/hooks/contactRoom";
import { useCurrentUser, useEmployee, useEmployees } from "~/hooks/employee";
import { useNewGraduate } from "~/hooks/employee/useNewGraduate";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useFileUrl } from "~/hooks/file";
import { useModal } from "~/hooks/modal";
import { useDebouncedCallback } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { NotFound } from "~/pages/NotFound";

type Props = {
  contactRoomId: string;
  contactMessageDraft?: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
};

export const NewGraduatePCContactRoomItem: FC<Props> = (props) => {
  const contactRoomId = props.contactRoomId;

  const { currentUser } = useCurrentUser();
  const { contactRoomAndMessages, isLoading: isLoadingContactRoomAndMessages } =
    useContactMessagesAndLogs({
      contactRoomId,
    });
  const {
    data: contactRoom,
    isLoading: isLoadingContactRooms,
    mutate: contactRoomsMutate,
  } = useContactRoom({
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom: true,
    contactRoomId,
  });

  const { switchSpaceTemporary } = useCurrentSpace();
  useEffect(() => {
    if (!contactRoom?.spaceId) return;

    switchSpaceTemporary(contactRoom.spaceId);
  }, [switchSpaceTemporary, contactRoom]);

  if (isLoadingContactRoomAndMessages || isLoadingContactRooms)
    return <Loading size={"large"} fullHeight />;
  if (!contactRoomAndMessages.contactRoom || !contactRoom) return <NotFound />;

  return (
    <PCContactRoomItem
      {...props}
      contactRoom={contactRoom}
      contactMessages={
        contactRoomAndMessages.messages as (ContactMessage & {
          creatorName: string;
          creatorImageUrl: string | undefined;
        })[]
      }
      contactRoomsMutate={contactRoomsMutate}
      contactMessageDraft={
        props.contactMessageDraft ??
        ContactMessageDraft.create({
          tenantId: currentUser.tenantId,
          createdUserId: currentUser.id,
          contactRoomId: contactRoom.id,
          text: "",
        })
      }
    />
  );
};

type PCContactRoomItemProps = {
  contactRoom: AllContactRoom;
  contactMessages: ContactMessagesForDisplay[];
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  contactRoomsMutate: KeyedMutator<AllContactRoom>;
};

const PCContactRoomItem: FC<PCContactRoomItemProps> = ({
  contactRoom,
  contactMessages,
  contactMessageDraft,
  saveContactMessageDraft,
  contactRoomsMutate,
}) => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();

  const { postMessage, isSending, isFileSending, messageId } = useOptimisticCreateMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
  });

  const [newMessage, setNewMessage] = useState("");
  const [isOfficialName, setIsOfficialName] = useState(false);
  useEffect(() => {
    setNewMessage(contactMessageDraft?.text ?? "");
  }, [contactMessageDraft]);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  const [messageListRef, setMessageListRef] = useState<HTMLDivElement>();
  const { data: followers = [] } = useEmployees(
    contactRoom.employee?.supportMemberEmployeeIds ?? []
  );
  const { data: assignee } = useEmployee(contactRoom.employee?.mentorUserId ?? "");

  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  const {
    data: newGraduate,
    isLoading: isLoadingEmployee,
    mutate: newGraduateMutate,
  } = useNewGraduate({
    newGraduateId: contactRoom.employeeId,
  });

  const { data: employeeInformation, isLoading: isLoadingEmployeeInformation } =
    useEmployeeInformation(contactRoom.employeeId);

  const { updateAssigneeAndFollowers } = useUpdateAssigneeAndFollowers();
  const { handleModal } = useModal();

  const isDataLoading =
    isLoadingEmployeeTagsData || isLoadingEmployee || isLoadingEmployeeInformation;

  const { updateReadLogOfContactRoom } = useUpdateReadLogOfContactRoom();

  const { latestAlreadyReadUsersInfo } = useLatestAlreadyReadUsersInfoV2(
    contactRoom,
    currentUser.id,
    [...followers, ...(assignee ? [assignee] : [])],
    contactMessages.filter((m) => m instanceof ContactMessage) as ContactMessage[]
  );

  const isRoomTargetEmployeeDeleted = contactRoom.employee && contactRoom.employee.deleted;
  useEffect(() => {
    const fn = async () => {
      await updateReadLogOfContactRoom(currentUser.id, contactRoom.id);
    };

    fn();
    // ページアクセス時のみの実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, contactRoom.id]);

  useEffect(() => {
    if (!messageListRef) {
      return;
    }

    messageListRef.scrollTop = messageListRef.scrollHeight;
    // messageに変更があったときに一番したまでスクロールする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListRef, contactMessages]);

  const handleClickAddMember = useCallback(
    (followers: Employee[]) => {
      handleModal({
        name: "addFollowersModal",
        args: {
          newHire: contactRoom.employee,
          followers,
          onSubmit: async (followerIds: string[]) => {
            await updateAssigneeAndFollowers(
              contactRoom.employeeId,
              contactRoom.employee?.mentorUserId,
              followerIds
            );
            await contactRoomsMutate();
            await newGraduateMutate();
          },
        },
      });
    },
    [
      contactRoom.employee,
      contactRoom.employeeId,
      contactRoomsMutate,
      newGraduateMutate,
      handleModal,
      updateAssigneeAndFollowers,
    ]
  );

  const handleClickChangeAssignee = useCallback(
    async (assignee?: Employee) => {
      handleModal({
        name: "changeAssigneeModal",
        args: {
          assignee,
          newHire: contactRoom.employee,
          onSubmit: async (newAssignee) => {
            // 担当者をフォロワーから選ぶ場合は、フォロワーから該当ユーザーを落とす
            const followerIds: string[] =
              (newAssignee
                ? contactRoom.employee?.supportMemberEmployeeIds.filter(
                    (id) => id !== newAssignee.id
                  )
                : contactRoom.employee?.supportMemberEmployeeIds) || [];

            await updateAssigneeAndFollowers(contactRoom.employeeId, newAssignee?.id, followerIds);
            await contactRoomsMutate();
            await newGraduateMutate();
          },
        },
      });
    },
    [
      handleModal,
      contactRoom.employee,
      contactRoom.employeeId,
      updateAssigneeAndFollowers,
      contactRoomsMutate,
      newGraduateMutate,
    ]
  );

  const handleClickManageButton = useCallback(() => {
    handleModal({
      name: "manageContactTeamModal",
      args: {
        assignee,
        followers,
        onSubmit: async (followerIds: string[]) => {
          await updateAssigneeAndFollowers(
            contactRoom.employeeId,
            contactRoom.employee?.mentorUserId,
            followerIds
          );
          await contactRoomsMutate();
        },
        onClickAddFollower: handleClickAddMember,
        onChangeAssignee: handleClickChangeAssignee,
      },
    });
  }, [
    handleModal,
    assignee,
    followers,
    handleClickAddMember,
    handleClickChangeAssignee,
    updateAssigneeAndFollowers,
    contactRoom.employeeId,
    contactRoom.employee?.mentorUserId,
    contactRoomsMutate,
  ]);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 500);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessage(e.target.value);
      handleDebounceCallback(() => {
        if (!contactMessageDraft) return;
        saveContactMessageDraft(
          new ContactMessageDraft({
            id: contactMessageDraft.id,
            contactRoomId: contactMessageDraft.contactRoomId,
            createdUserId: contactMessageDraft.createdUserId,
            text: e.target.value,
            tenantId: currentUser.tenantId,
          })
        );
      });
    },
    [contactMessageDraft, currentUser.tenantId, handleDebounceCallback, saveContactMessageDraft]
  );

  const [lineStampPopoverOpen, setLineStampPopoverOpen] = useState(false);
  const messageAreaRef = useRef<HTMLDivElement>(null);

  const { trigger: postLineStampMessage } = usePostLineStampMessage({
    tenantId: currentUser.tenantId,
    contactRoomId: contactRoom.id,
    employee: currentUser,
  });

  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    contactMessageDraft,
    setNewMessage,
    saveContactMessageDraft,
    currentMessage: newMessage,
  });

  const { contactRoomPin } = useContactRoomPin({
    selectedContactRoomId: contactRoom.id,
  });
  // NOTE: LINEコンタクトルームの場合、ブロックされているかどうかの判定
  const isUnFollow = "lineUser" in contactRoom && contactRoom.lineUser.isUnFollow;

  // NOTE: メッセージを送信できる設定かどうか
  const canSendMessage = !isRoomTargetEmployeeDeleted && !isUnFollow && !contactRoom.isClosed;

  const { data: logoUrl } = useFileUrl(`public/uploads/logo/${currentUser.tenantId}`);

  if (isDataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Box display="flex" height="100%" width="100%" style={{ overflowX: "auto" }}>
        <Box width="100%" display="flex" flexDirection="column" flex="1 0 360px">
          <Box
            display="flex"
            flexDirection="column"
            gridGap="32px"
            padding="40px"
            height="100%"
            style={{ overflowY: "auto" }}
            // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
            // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
            {...{ ref: (node: HTMLDivElement) => setMessageListRef(node) }}
            onClick={() => setLineStampPopoverOpen(false)}
          >
            <MessageListView
              contactMessages={contactMessages}
              newGraduate={newGraduate || undefined}
              messageId={messageId}
              isFileSending={isFileSending}
              tenantName={tenant.tenantName}
              latestAlreadyReadUsersInfo={latestAlreadyReadUsersInfo}
              contactRoom={contactRoom}
              isRoomTargetEmployeeDeleted={isRoomTargetEmployeeDeleted}
              isContactRoomClosed={contactRoom.isClosed}
            />
          </Box>
          {canSendMessage && (
            <Box
              mt="auto"
              p="24px"
              bgcolor="white"
              position="relative"
              {...{ ref: messageAreaRef }}
            >
              <TextareaAutosizeAttachableFile
                ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
                value={newMessage}
                fullWidth
                placeholder={`${contactRoom.getHonorificRoomName()}へのメッセージを入力してください`}
                onChange={onChange}
                minRows={1}
                maxRows={5}
                accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
                onChangeFiles={(newFiles: (File | Pick<File, "name">)[]) => {
                  setLineStampPopoverOpen(false);
                  setNewMessageFiles(
                    newFiles.filter((v): v is File => {
                      return v instanceof File;
                    })
                  );
                }}
                attachedFiles={newMessageFiles}
                isFileSending={isFileSending}
                footerLeftActions={
                  <>
                    {/* LINEスタンプ送信ボタン */}
                    {newGraduate?.selectedAuthenticationFlowType === "line" && (
                      <Toggle
                        pressed={lineStampPopoverOpen}
                        onPressedChange={setLineStampPopoverOpen}
                      >
                        <Icon icon="emojiSmile" size="sm" color="inherit" />
                      </Toggle>
                    )}
                    {/* メッセージテンプレートボタン */}
                    <IconButton
                      icon="document"
                      size="sm"
                      color="grey"
                      borderRadius="regular"
                      onClick={contactMessageTemplatesMenuHandling.handleOpen}
                    />
                  </>
                }
                footerButtons={[
                  <UserIconMenu
                    key="userIconMenu"
                    username={!isOfficialName ? currentUser.getName() : tenant.tenantName}
                    imageUrl={!isOfficialName ? currentUser.profileIconImageUrl : logoUrl}
                    title="送信元アカウント"
                    menuItemBoxes={[
                      {
                        content: (
                          <Typography variant="body2" color="textPrimary">
                            企業アカウント
                          </Typography>
                        ),
                        onClick: () => setIsOfficialName(true),
                      },
                      {
                        content: (
                          <Box display="flex" alignItems="center" gridGap={4}>
                            <UserIcon
                              username={currentUser.getName()}
                              profileIconImageUrl={currentUser.profileIconImageUrl}
                              size="extraSmall"
                              circular
                            />
                            <Typography variant="body2" color="textPrimary" noWrap>
                              {currentUser.getName()}
                            </Typography>
                          </Box>
                        ),
                        onClick: () => setIsOfficialName(false),
                      },
                    ]}
                  />,
                  <Button
                    key="submit"
                    onClick={async () => {
                      setLineStampPopoverOpen(false);
                      if (contactMessageDraft) {
                        saveContactMessageDraft(
                          new ContactMessageDraft({
                            ...contactMessageDraft,
                            text: "",
                          })
                        );
                      }
                      await postMessage({
                        newMessageText: newMessage,
                        newMessageFiles,
                        employee: currentUser,
                        isOfficialName,
                      });
                      setNewMessageFiles([]);
                    }}
                    borderRadius="regular"
                    variant="contained"
                    color="primary"
                    disabled={!newMessage.trim() || isSending}
                  >
                    送信
                  </Button>,
                ]}
              />
              <LineStampPopover
                anchorEl={messageAreaRef.current}
                open={lineStampPopoverOpen}
                onClose={() => {
                  setLineStampPopoverOpen(false);
                }}
                onSelect={async (lineStamp) => {
                  setLineStampPopoverOpen(false);
                  await postLineStampMessage({ stickerId: lineStamp.stickerId, isOfficialName });
                }}
              />
            </Box>
          )}
        </Box>
        {newGraduate && (
          <NewGraduateSidebar
            newGraduate={newGraduate}
            employeeInformation={employeeInformation || undefined}
            contactRoom={contactRoom}
            tags={
              employeeTagsData?.employeeTags?.filter((tag) =>
                newGraduate.employeeTagIds.includes(tag.id)
              ) ?? []
            }
            assignee={assignee}
            followers={followers}
            contactRoomPin={contactRoomPin}
            handleClickManageButton={handleClickManageButton}
          />
        )}
      </Box>
      {/*  メッセージのテンプレート管理用メニュー */}
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
    </Box>
  );
};
