import { Box, Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Icon, Loading, Typography } from "~/components/uiParts";

export const DraftSavingStatus = ({ isSavingDraft }: { isSavingDraft?: boolean }) => {
  if (isSavingDraft === undefined) {
    return null;
  }

  return isSavingDraft ? (
    <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={8}>
      <Loading size={"small"} color="primary" />
      <Typography variant="body2" color="textSecondary">
        下書きを保存しています。
      </Typography>
    </Box>
  ) : (
    <SavedWithFadeOut />
  );
};

const SavedWithFadeOut = () => {
  const [isFadeIn, setIsFadeIn] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFadeIn(false);
    }, 2000);
    return () => clearTimeout(timer);
  });

  return (
    <Fade timeout={{ appear: 0, enter: 0, exit: 2000 }} in={isFadeIn}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={8}>
        <Icon color="primary" icon="checkCircle" size="sm" />
        <Typography variant="body2" color="textSecondary">
          下書きを保存しました。
        </Typography>
      </Box>
    </Fade>
  );
};
