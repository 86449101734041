import { z } from "zod";

import { OnnEventEvaluationMultipleSelectValueSchema } from "../../../../../_gen/zodSchema/index";

export const onnEventEvaluationMultipleSelectValueSchema =
  OnnEventEvaluationMultipleSelectValueSchema.merge(
    z.object({
      createdEmployeeId: z.string().optional(),
      updatedEmployeeId: z.string().optional(),
    })
  );
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationMultipleSelectValue
  extends z.infer<typeof onnEventEvaluationMultipleSelectValueSchema> {}
