import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";

import { InputState } from "../../../../../hooks/form/InputState";

import { AccessControlTypesSelectForm } from "./AccessControlTypesSelectForm";

import { IconButton, UncontrolledMenu } from "~/components/uiParts";

export const AccessControlSelectAndThreeDotMenuRow: React.FC<{
  fieldIndex: number;
  isFinalField: boolean;
  onRemoveField: (fieldIndex: number) => void;
  onDuplicateField: (fieldIndex: number) => void;
}> = ({ fieldIndex, isFinalField, onRemoveField, onDuplicateField }) => {
  // NOTE: ジェネリクス型を指定しないでいい方法があれば修正する
  const { field } = useController<InputState, `evaluationFields.${number}.accessControlType`>({
    name: `evaluationFields.${fieldIndex}.accessControlType`,
  });

  return (
    <Box
      display={"flex"}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
        }}
        style={{
          columnGap: "16px",
        }}
      >
        <Typography variant="body2" color="textPrimary">
          アクセス権限
        </Typography>
        <Box width={"300px"}>
          <AccessControlTypesSelectForm selected={field.value} onChange={field.onChange} />
        </Box>
      </Box>
      <UncontrolledMenu
        renderButton={(openMenu) => <IconButton icon="menuVert" size="md" onClick={openMenu} />}
        menuItemOptions={[
          isFinalField
            ? []
            : {
                text: "削除",
                onClick: () => {
                  onRemoveField(fieldIndex);
                },
              },
          {
            text: "複製",
            onClick: () => {
              onDuplicateField(fieldIndex);
            },
          },
        ].flat()}
      />
    </Box>
  );
};
