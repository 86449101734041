import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutate } from "swr";

import { mutateOnnEventAnswersForDisplay } from "../onnEventAnswer/useOnnEventAnswersForDisplay";
import { generateOnnEventSlotDatesForDisplayKey } from "../onnEventSlotDates/useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/onn_event_api/answer-new-interview-with-csv"]["POST"];

export const useAnswerNewInterviewOnBehalfWithCsv = () => {
  const answerNewInterviewOnBehalfWithCsv = useCallback(
    async (arg: {
      onnEventId: string;
      base64EncodedCsvFile: string;
      isDryRun: boolean;
      forceNotifyImmediately?: boolean;
    }) => {
      const requestBody: Endpoint["body"] = {
        onnEventId: arg.onnEventId,
        base64EncodedCsvFile: arg.base64EncodedCsvFile,
        isDryRun: arg.isDryRun,
        forceNotifyImmediately: arg.forceNotifyImmediately,
      };
      const response = await apiClient.post(
        "/onn_event_api/answer-new-interview-with-csv",
        requestBody
      );
      mutate(generateOnnEventSlotDatesForDisplayKey(arg.onnEventId));
      mutateOnnEventAnswersForDisplay(arg.onnEventId);
      return response;
    },
    []
  );

  return { answerNewInterviewOnBehalfWithCsv };
};
