import { Box, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Space } from "@onn/common";
import React, { MouseEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

export const SpaceSwitcher = ({
  currentSpace,
  spaces,
  isExpand,
  onClickMenuItem,
  ifFullWidthMenu,
  canSwitch = true,
}: {
  currentSpace: Space;
  spaces: Space[];
  isExpand: boolean;
  onClickMenuItem: (spaceId: string) => void;
  ifFullWidthMenu: boolean;
  canSwitch?: boolean;
}): JSX.Element => {
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorMenuEl(null);
  }, []);

  const handleClickMenuItem = useCallback(
    (spaceId: string) => {
      handleCloseMenu();
      onClickMenuItem(spaceId);
    },
    [handleCloseMenu, onClickMenuItem]
  );

  return (
    <Tooltip
      title={canSwitch ? "" : "権限がありません。アクセスするには管理者に連絡してください"}
      placement="left"
      arrow
    >
      <span>
        <Box
          borderRadius="4px"
          height="40px"
          width={isExpand ? "100%" : "40px"}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={isExpand ? "12px 8px" : "12px 6px"}
          onClick={canSwitch ? handleOpenMenu : undefined}
          style={{
            cursor: canSwitch ? "pointer" : "default",
            backgroundColor: canSwitch ? theme.palette.grey[50] : theme.palette.grey[25],
            outline: `2px solid ${theme.palette.grey[100]}`,
          }}
        >
          <Typography
            variant="caption"
            color="textSecondary"
            bold
            noWrap
            style={{
              lineHeight: "14.63px",
              color: canSwitch ? theme.palette.grey[400] : theme.palette.grey[200],
            }}
          >
            {currentSpace?.name}
          </Typography>
          {isExpand && canSwitch && <Icon icon="doubleArrowRight" size="sm" color="grey" />}
        </Box>
        <StyledMenu
          anchorEl={anchorMenuEl}
          keepMounted
          open={Boolean(anchorMenuEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          $listWidth={ifFullWidthMenu ? anchorMenuEl?.offsetWidth : undefined}
          anchorOrigin={
            ifFullWidthMenu
              ? { vertical: "bottom", horizontal: "left" }
              : { vertical: "top", horizontal: "right" }
          }
          transformOrigin={
            ifFullWidthMenu ? { vertical: -4, horizontal: 0 } : { vertical: "top", horizontal: -8 }
          }
        >
          {spaces.map((space) => (
            <MenuItem
              key={space.id}
              onClick={() => handleClickMenuItem(space.id)}
              style={{
                background: currentSpace.id === space.id ? theme.palette.primary.light : "unset",
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body2" color="textSecondary" bold style={{ lineHeight: 1.5 }}>
                {space.name}
              </Typography>
            </MenuItem>
          ))}
        </StyledMenu>
      </span>
    </Tooltip>
  );
};

const StyledMenu = styled(Menu)<{ $listWidth?: number }>`
  .MuiList-root {
    ${({ $listWidth }) => $listWidth && `width: ${$listWidth}px;`}
    padding: 8px;
  }
`;
