import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK V 2 SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankV2Schema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationRankV2 = z.infer<typeof OnnEventEvaluationRankV2Schema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK V 2 OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankV2OptionalDefaultsSchema = OnnEventEvaluationRankV2Schema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventEvaluationRankV2OptionalDefaults = z.infer<
  typeof OnnEventEvaluationRankV2OptionalDefaultsSchema
>;

export default OnnEventEvaluationRankV2Schema;
