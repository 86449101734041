import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Props = {
  row: { contents: ReactNode[] } & (
    | { onClick?: () => void; to?: undefined; isTargetBlank?: undefined }
    | { to?: string; onClick?: undefined; isTargetBlank: boolean }
  );
  widthOptions: string[];
  hover?: boolean;
};

export const TableRow: FC<Props> = ({ row, widthOptions, hover }) => {
  if (row.to) {
    return (
      <StyledLink to={row.to} target={`${row.isTargetBlank ? "_blank" : "_self"}`}>
        <StyledBox display="flex" alignItems="center" height="100%" $hover={Boolean(hover)}>
          {row.contents.map((content, index) => (
            <Box key={`TableCell-${index}`} p="16px" width={widthOptions[index]}>
              {content}
            </Box>
          ))}
        </StyledBox>
      </StyledLink>
    );
  } else {
    return (
      <StyledBox
        display="flex"
        alignItems="center"
        height="100%"
        onClick={row.onClick}
        $hover={Boolean(hover)}
      >
        {row.contents.map((content, index) => (
          <Box key={`TableCell-${index}`} p="16px" width={widthOptions[index]}>
            {content}
          </Box>
        ))}
      </StyledBox>
    );
  }
};

const StyledBox = styled(Box)<{ $hover: boolean }>`
  ${(props) =>
    props.$hover &&
    props.onClick &&
    `&:hover {
     background-color: rgba(0, 0, 0, 0.04);
    }`}

  > div {
    &:first-child {
      padding-left: 40px;
    }
    &:last-child {
      padding-right: 40px;
    }
  }
  text-decoration: none;
  position: relative;
  ${(props) => props.onClick && `cursor: pointer;`}
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: contents;
  text-decoration: none;
  color: inherit;
`;
