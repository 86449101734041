import { v4 } from "uuid";

import { OnnFormTaskAnswer } from "../../domain";

type Answer = OnnFormTaskAnswer["answers"][number];
const createIMockAnswer = ({ mock = {} }: { mock: Partial<Answer> }): Answer => {
  switch (mock.type) {
    case "CHECK_BOX":
      return {
        id: mock.id ?? v4(),
        questionId: mock.questionId ?? "question1",
        type: mock.type ?? "CHECK_BOX",
        selectedOptionIds: mock.selectedOptionIds ?? ["option1", "option2"],
        freeText: mock.freeText ?? "自由記述",
      };
    case "TEXT":
      return {
        id: mock.id ?? v4(),
        questionId: mock.questionId ?? "question1",
        type: mock.type ?? "TEXT",
        value: mock.value ?? "テキスト",
      };
    case "RADIO":
      return {
        id: mock.id ?? v4(),
        questionId: mock.questionId ?? "question1",
        type: mock.type ?? "RADIO",
        selectedOptionId: mock.selectedOptionId ?? "option1",
      };
    case "FILE":
      return {
        id: mock.id ?? v4(),
        questionId: mock.questionId ?? "question1",
        type: mock.type ?? "FILE",
        filePath: mock.filePath ?? "sample.pdf",
      };
    default: // type が指定されない場合は id とquestionId 以外無視する
      return {
        id: mock.id ?? v4(),
        questionId: mock.questionId ?? "question1",
        type: "CHECK_BOX",
        selectedOptionIds: ["option1", "option2"],
      };
  }
};

type PartialOnnFormTaskAnswer = Partial<Omit<OnnFormTaskAnswer, "answers">> & {
  answers?: Partial<Answer>[];
};
export const createIMockOnnFormTaskAnswer = (
  mock: PartialOnnFormTaskAnswer = {}
): OnnFormTaskAnswer => {
  return new OnnFormTaskAnswer({
    id: mock.id ?? "TaskMemo1",
    formTaskId: mock.formTaskId ?? "formTask1",
    employeeId: mock.employeeId ?? "employee1",
    tenantId: mock.tenantId ?? "tenant1",
    lastReadAt: mock.lastReadAt ?? null,
    firstReadAt: mock.firstReadAt ?? null,
    lastAnsweredAt: mock.lastAnsweredAt ?? null,
    answeredFormRevisionId: mock.answeredFormRevisionId ?? null,
    createdAt: mock.createdAt ?? new Date(),
    updatedAt: mock.updatedAt ?? new Date(),
    answers: mock.answers?.map((answer) => createIMockAnswer({ mock: answer })) ?? [],
  });
};

export const createIMockOnnFormTaskAnswers = ({
  commonValue,
  values,
}: {
  commonValue: PartialOnnFormTaskAnswer;
  values: Array<PartialOnnFormTaskAnswer>;
}): OnnFormTaskAnswer[] => {
  return values.map((value) =>
    createIMockOnnFormTaskAnswer({
      // ユニーク制約があるため、適当な値を入れる
      id: v4(),

      ...commonValue,
      ...value,
    })
  );
};
