import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint =
  APISchema["/api/onn-event-evaluations/check-if-settings-to-delete-already-used"]["POST"];

export const useCheckIfOnnEventEvaluationSettingsToDeleteAlreadyUsed = () => {
  const checkIfOnnEventEvaluationSettingsToDeleteAlreadyUsed = useCallback(
    async (body: Endpoint["body"]) => {
      return await apiClient.post(
        "/api/onn-event-evaluations/check-if-settings-to-delete-already-used",
        body
      );
    },
    []
  );

  return { checkIfOnnEventEvaluationSettingsToDeleteAlreadyUsed };
};
