import { Box, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import { SelectableFeatures } from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { InvitationMessageSettings } from "./InvitationMessageSettings";

import { BusinessHoursSettingsSection } from "./businessHoursSettingsSection";

import { AdminContentWrapper, Paper, Switch, TextContext, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { useTenantSettings, useUpdateTenantSettings } from "~/hooks/tenantSetting";
import customInvitation from "~/images/custom-invitation.png";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";
import { captureException } from "~/util";

const fileApiAdapter = new FileAPIAdapter({ bucketType: "public" });

const SectionIds = {
  INVITATION_MESSAGE: "invitationMessage",
} as const;
type SectionIds = (typeof SectionIds)[keyof typeof SectionIds];

export const BasicTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const { tenantSettings, mutateTenantSettings } = useTenantSettings();
  const { isLoading: isLoadingUpdateTenantSettings, updateTenantSettings } =
    useUpdateTenantSettings();

  const { features } = tenantSettings;

  const [editingSections, setEditingSections] = useState<Set<SectionIds>>(new Set());

  const handleSwitchCustomInvitationMessage = useCallback(async () => {
    const newFeatures = {
      ...tenantSettings.features,
      ...{
        [SelectableFeatures.CUSTOM_INVITATION_MESSAGE]: !features.customInvitationMessage,
      },
    };

    await TenantSettingsUseCase.updateFeature(currentUser.tenantId, newFeatures);
    mutateTenantSettings();
  }, [
    currentUser.tenantId,
    features.customInvitationMessage,
    mutateTenantSettings,
    tenantSettings.features,
  ]);

  const handleClickOptionSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => new Set(prevState.add(sectionId)));
  };

  const handleCancelSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => {
      prevState.delete(sectionId);
      return new Set(prevState);
    });
  };

  const handleSubmitInvitationMessageSettings = async (
    invitationMessage: { iconPath?: string; fullName: string; content: string },
    inputIcon?: File
  ) => {
    try {
      if (invitationMessage.iconPath && inputIcon) {
        await fileApiAdapter.upload({ path: invitationMessage.iconPath, file: inputIcon });
      }

      await updateTenantSettings(currentUser.tenantId, { invitationMessage });
      mutateTenantSettings();
      enqueueSnackbar("変更を保存しました", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "BasicTab:handleSubmitInvitationMessageSettings" },
      });
    } finally {
      handleCancelSettingButton(SectionIds.INVITATION_MESSAGE);
    }
  };

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={12}>
            <Stack rowGap="40px">
              <Stack>
                <Grid item xs={12}>
                  <StyledTextContext padding={2} isShowBg bold>
                    招待メッセージ設定
                  </StyledTextContext>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={8}>
                    <Box px={2} pt={4}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" bold>
                          入社者招待カスタムメッセージ
                        </Typography>
                        <Switch
                          checked={!!features.customInvitationMessage}
                          onChange={() => handleSwitchCustomInvitationMessage()}
                          color="primary"
                        />
                      </Box>
                      <StyledTypography variant="caption">
                        {`入社者に送られる招待メールに、案内を追加することができます。\nどんな目的で招待を送っているのかを簡潔に伝えて、入社者への説明を自動化しましょう。`}
                      </StyledTypography>
                    </Box>
                    {!!features.customInvitationMessage && (
                      <Box px="16px" pt="32px">
                        <InvitationMessageSettings
                          invitationMessage={tenantSettings.invitationMessage}
                          editing={editingSections.has(SectionIds.INVITATION_MESSAGE)}
                          updating={isLoadingUpdateTenantSettings}
                          onEdit={() =>
                            handleClickOptionSettingButton(SectionIds.INVITATION_MESSAGE)
                          }
                          onCancel={() => handleCancelSettingButton(SectionIds.INVITATION_MESSAGE)}
                          onSubmit={handleSubmitInvitationMessageSettings}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box pt={4}>
                      <img width="100%" src={customInvitation} alt="customInvitation" />
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Grid item xs={12}>
                  <StyledTextContext padding={2} isShowBg bold>
                    通知配信時間設定
                  </StyledTextContext>
                </Grid>
                <BusinessHoursSettingsSection />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
