import type {
  ICheckBoxQuestionSchema,
  IFileQuestionSchema,
  IQuestionSchema,
  IRadioQuestionSchema,
  ITextQuestionSchema,
} from "./schema";

export type CheckBoxQuestion = ICheckBoxQuestionSchema;

export type TextQuestion = ITextQuestionSchema;

export type RadioQuestion = IRadioQuestionSchema;

export type FileQuestion = IFileQuestionSchema;

export type Question = IQuestionSchema;

export const hasOptions = (
  question: IQuestionSchema
): question is IRadioQuestionSchema | ICheckBoxQuestionSchema =>
  question.type === "RADIO" || question.type === "CHECK_BOX";
