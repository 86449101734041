import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useUpdateEmployeeEmail = () => {
  const execUpdateEmployeeEmail = useCallback(
    async ({
      employeeId,
      value,
    }: {
      employeeId: string;
      value: { email: string };
    }): Promise<void> => {
      await apiClient.patch("/api/employee/update-employee", {
        id: employeeId,
        updates: { email: value.email },
      });
    },
    []
  );

  return { execUpdateEmployeeEmail };
};
