import { FormControlLabel, Stack, styled } from "@mui/material";
import { OnnEventEvaluationMultipleSelectField } from "@onn/common";
import { xor } from "lodash";
import React, { FC } from "react";
import { useController } from "react-hook-form";

import { FieldValues } from "../../../hooks/form/FieldValues";

import { FieldLabelArea } from "./_shared/FieldLabelArea";

import { Checkbox, Tooltip, Typography } from "~/components/uiParts";

export const OnnEventEvaluationMultipleSelectFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: OnnEventEvaluationMultipleSelectField;
  isDisabled: boolean;
}> = ({ onnEventEvaluationField, fieldIndex, isDisabled }) => {
  const { field: selectedOptionIdsField } = useController<
    FieldValues,
    `onnEventEvaluationValues.${number}.selectedOptionIds`
  >({
    name: `onnEventEvaluationValues.${fieldIndex}.selectedOptionIds`,
  });

  return (
    <Stack spacing={"8px"}>
      <FieldLabelArea onnEventEvaluationField={onnEventEvaluationField} />
      {onnEventEvaluationField.options.map((option) => {
        return (
          <Tooltip
            key={option.id}
            title={isDisabled ? "編集不可の項目です" : undefined}
            placement="top-start"
          >
            <StyledFormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOptionIdsField.value.includes(option.id)}
                  onChange={() => {
                    selectedOptionIdsField.onChange(xor(selectedOptionIdsField.value, [option.id]));
                  }}
                  value={option.id}
                  style={{
                    padding: 0,
                    marginRight: 8,
                  }}
                  disabled={isDisabled}
                />
              }
              label={
                <Typography variant="caption" color="textSecondary" noWrap>
                  {option.label}
                </Typography>
              }
            />
          </Tooltip>
        );
      })}
    </Stack>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
