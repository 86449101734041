import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { useMutateOnnEventEvaluation } from "./useOnnEventEvaluation";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-evaluations"]["POST"];

export const useEditOnnEventEvaluation = () => {
  const { mutateOnnEventEvaluation } = useMutateOnnEventEvaluation();

  const editOnnEventEvaluation = useCallback(
    async (body: Endpoint["body"]) => {
      await apiClient.post("/api/onn-event-evaluations", body);
      mutateOnnEventEvaluation({
        onnEventId: body.onnEventId,
        targetEmployeeId: body.targetEmployeeId,
      });
    },
    [mutateOnnEventEvaluation]
  );

  return { editOnnEventEvaluation };
};
