import { canEditOnnEventEvaluationValue } from "@onn/common";
import { useCallback } from "react";

import { FieldValues } from "./FieldValues";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnEventEvaluationDraft } from "~/hooks/onnEventEvaluation/useUpdateOnnEventEvaluationDraft";

/**
 * react-hook-formのFieldValuesを元に、評価データの下書きを更新する
 */
export const useUpdateDraftOnnEventEvaluationFromFieldValues = ({
  onnEventId,
  targetEmployeeId,
}: {
  onnEventId: string;
  targetEmployeeId: string;
}) => {
  const { currentUser } = useCurrentUser();
  const { updateOnnEventEvaluationDraft, isUpdatingDraft } = useUpdateOnnEventEvaluationDraft();

  const updateDraftOnnEventEvaluationFromFieldValues = useCallback(
    async (fieldValues: FieldValues) => {
      const onnEventEvaluationDraftValuesToUpdate = fieldValues.onnEventEvaluationValues.filter(
        (onnEventEvaluationValue) =>
          canEditOnnEventEvaluationValue({
            currentUser,
            fieldAccessControlType: onnEventEvaluationValue.fieldAccessControlType,
          })
      );

      await updateOnnEventEvaluationDraft({
        onnEventId,
        targetEmployeeId,
        onnEventEvaluationRankId: fieldValues.onnEventEvaluationRankId || undefined,
        onnEventEvaluationValues: onnEventEvaluationDraftValuesToUpdate.flatMap((inputValue) => {
          switch (inputValue.type) {
            case "TextField": {
              return {
                type: "TextField",
                fieldId: inputValue.fieldId,
                value: inputValue.value,
              };
            }
            case "FileField": {
              return {
                type: "FileField",
                fieldId: inputValue.fieldId,
                filePaths: inputValue.filePaths,
              };
            }
            case "SingleSelectField": {
              // NOTE: optionIdがnullのvalueは外部キー制約でエラーになるので、空の場合は送信しない
              return inputValue.selectedOptionId
                ? {
                    type: "SingleSelectField",
                    fieldId: inputValue.fieldId,
                    onnEventEvaluationSingleSelectOptionId: inputValue.selectedOptionId,
                  }
                : [];
            }
            case "MultipleSelectField": {
              return {
                type: "MultipleSelectField",
                fieldId: inputValue.fieldId,
                onnEventEvaluationMultipleSelectOptionIds: inputValue.selectedOptionIds,
              };
            }
            default: {
              const _exhaustiveCheck: never = inputValue;
              return _exhaustiveCheck;
            }
          }
        }),
      });
    },
    [currentUser, onnEventId, targetEmployeeId, updateOnnEventEvaluationDraft]
  );

  return { updateDraftOnnEventEvaluationFromFieldValues, isUpdatingDraft };
};
