import { Box } from "@material-ui/core";
import { AllContactRoom, EmployeeInformation, NewGraduateToDisplay } from "@onn/common";
import React, { FC, ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateMemo } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSidebar/NewGraduateMemo";

import { NewGraduateProfileSummary } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSidebar/NewGraduateProfileSummary";
import { NewGraduateRecruitmentStatuses } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSidebar/NewGraduateRecruitmentStatuses";
import { NewGraduateRichMenu } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSidebar/NewGraduateRichMenu";

import { NewGraduateSummary } from "../../contactRooms/ContactRoomItem/parts/NewGraduateSidebar/NewGraduateSummary";

import { useContactRoomPin } from "../../contactRooms/ContactRoomItem/parts/useContactRoomPin";
import { useHandleOnClickPin } from "../../contactRooms/ContactRoomItem/parts/useHandleOnClickPin";

import { IconList, Section } from "./components";

import { Button, Divider, Icon, Loading, Modal, Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useEmployee, useEmployees, useUpdateNewGraduateMemo } from "~/hooks/employee";
import { useNewGraduate } from "~/hooks/employee/useNewGraduate";
import { useRichMenuIdByLineUserId } from "~/hooks/richMenu/useRichMenuIdByLineUserId";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
  contactRoom: AllContactRoom;
  employeeInformation?: EmployeeInformation;
};

export const SPNewGraduateDetailModal: FC<Props> = ({
  open,
  onCancel,
  newGraduate: _newGraduate,
  contactRoom,
  employeeInformation,
}) => {
  // NOTE: モーダル内部でデータ更新を行うため再度取得
  const { data: newGraduate } = useNewGraduate({ newGraduateId: _newGraduate.id || "" });
  const { data: richMenuData, isLoading: isLoadingRichMenuUrl } = useRichMenuIdByLineUserId({
    lineUserId:
      newGraduate?.selectedAuthenticationFlowType === "line" ? newGraduate?.lineUserId : undefined,
  });
  const { contactRoomPin } = useContactRoomPin({
    selectedContactRoomId: contactRoom.id,
  });

  const { isPinned, handleOnClickPin, handleOnClickUnpin } = useHandleOnClickPin({
    selectedContactRoomId: contactRoom.id,
    contactRoomPin,
  });

  const handleClickPin = useCallback(
    () => (isPinned ? handleOnClickUnpin() : handleOnClickPin()),
    [isPinned, handleOnClickUnpin, handleOnClickPin]
  );

  // 担当者・サポートメンバー
  const { data: mentor, isLoading: isLoadingMentor } = useEmployee(newGraduate?.mentorUserId || "");
  const { data: supportMembers, isLoading: isLoadingSupportMembers } = useEmployees(
    newGraduate?.supportMemberEmployeeIds || []
  );

  // 社内メモ
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();
  const handleUpdateMemo = useCallback(
    async (newMemo: string) => {
      if (!newGraduate) return;
      await updateNewGraduateMemo(newGraduate, newMemo);
    },
    [newGraduate, updateNewGraduateMemo]
  );

  const displayRichMenuSection = useMemo(() => {
    if (newGraduate?.selectedAuthenticationFlowType !== "line") return false;
    // NOTE: ブロックされている場合などでrichMenuDataがnullで返ってくる場合はセクションごと表示しない
    if (!isLoadingRichMenuUrl && richMenuData === null) return false;

    return true;
  }, [isLoadingRichMenuUrl, newGraduate?.selectedAuthenticationFlowType, richMenuData]);

  const renderElementWithLoading = (isLoading: boolean, element: ReactElement) =>
    isLoading ? <Loading size="small" /> : element;

  return (
    <StyledModal
      fullWidth
      fullScreen
      open={open}
      title="詳細情報"
      titleSize="body1"
      content={
        <Box display="flex" flexDirection="column" gridGap="32px">
          {/* 選考ステータスが「辞退/不採用」の場合はメッセージを表示 */}
          {newGraduate?.isSomeScenarioRejectedOrWithdrew() && (
            <Box
              bgcolor={theme.palette.secondary.light}
              borderRadius={8}
              mb={2}
              px={2}
              py={1}
              gridGap={8}
              display="flex"
              alignItems="center"
            >
              <Icon icon="alert" color="secondary" size="md" />
              <Typography bold variant="caption" color="textPrimary">
                選考ステータスが「辞退/不採用」となっている候補者です。
              </Typography>
            </Box>
          )}
          {newGraduate && (
            <NewGraduateSummary
              newGraduate={newGraduate}
              isPinned={isPinned}
              handleClickPin={handleClickPin}
            />
          )}

          <Divider />

          <Section label={"担当者"}>
            {renderElementWithLoading(
              isLoadingMentor,
              mentor ? <IconList employees={[mentor]} /> : <Typography>未設定</Typography>
            )}
          </Section>

          <Section label={"サポートメンバー"}>
            {renderElementWithLoading(
              isLoadingSupportMembers,
              supportMembers && supportMembers.length > 0 ? (
                <IconList employees={supportMembers} />
              ) : (
                <Typography>未設定</Typography>
              )
            )}
          </Section>

          {newGraduate && (
            <NewGraduateMemo
              newGraduateId={newGraduate.id}
              memo={newGraduate.admin_memo ?? ""}
              onUpdateMemo={handleUpdateMemo}
            />
          )}

          {displayRichMenuSection && (
            <NewGraduateRichMenu
              richMenuUrl={richMenuData?.imageUrl}
              isLoading={isLoadingRichMenuUrl}
            />
          )}

          {newGraduate && <NewGraduateRecruitmentStatuses newGraduate={newGraduate} disabled />}

          <Divider />

          {newGraduate && (
            <NewGraduateProfileSummary
              kanaName={employeeInformation?.value.kanaName}
              phoneNumber={employeeInformation?.value.phoneNumber}
              homePhoneNumber={employeeInformation?.value.homePhoneNumber}
              affiliation={employeeInformation?.value.affiliation}
              graduationYearAndMonth={employeeInformation?.value.graduationYearAndMonth}
              address={employeeInformation?.value.address}
              email={newGraduate.email}
            />
          )}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              borderRadius="circle"
              variant="outlined"
              color="default"
              fullWidth
              onClick={() => {
                newGraduate &&
                  window.open(`/employee/${newGraduate.id}`, "_blank", "noopener noreferrer");
              }}
            >
              候補者詳細を見る
            </Button>
          </Box>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    margin: 0;
  }
`;
