import { v4 } from "uuid";

import { FileField } from "../../types";

import { IOnnEventEvaluationFileValue, onnEventEvaluationFileValueSchema } from "./schema";

export class OnnEventEvaluationFileValue implements IOnnEventEvaluationFileValue {
  static validator = onnEventEvaluationFileValueSchema;
  type = FileField;

  id: string;
  tenantId: string;
  onnEventEvaluationFileFieldId: string;
  filePaths: string[];
  employeeId: string;
  createdEmployeeId?: string;
  updatedEmployeeId?: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationFileValue>, "type">) {
    const parsedInit = OnnEventEvaluationFileValue.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationFileFieldId = parsedInit.onnEventEvaluationFileFieldId;
    this.filePaths = parsedInit.filePaths;
    this.employeeId = parsedInit.employeeId;
    this.createdEmployeeId = parsedInit.createdEmployeeId;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドバリューデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationFileValue>, "createdAt" | "updatedAt" | "type">
  ): OnnEventEvaluationFileValue {
    return new OnnEventEvaluationFileValue({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationFileValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationFileValue {
    return new OnnEventEvaluationFileValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<OnnEventEvaluationFileValue, "filePaths" | "updatedEmployeeId">
  ): OnnEventEvaluationFileValue {
    return new OnnEventEvaluationFileValue({
      ...this,
      filePaths: params.filePaths,
      updatedEmployeeId: params.updatedEmployeeId,
      updatedAt: new Date(),
    });
  }

  getFieldId() {
    return this.onnEventEvaluationFileFieldId;
  }
}
