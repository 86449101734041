import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION TEXT VALUE SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationTextValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationTextFieldId: z.string(),
  value: z.string(),
  employeeId: z.string(),
  createdEmployeeId: z.string().nullish(),
  updatedEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationTextValue = z.infer<typeof OnnEventEvaluationTextValueSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION TEXT VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationTextValueOptionalDefaultsSchema =
  OnnEventEvaluationTextValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationTextValueOptionalDefaults = z.infer<
  typeof OnnEventEvaluationTextValueOptionalDefaultsSchema
>;

export default OnnEventEvaluationTextValueSchema;
