import { z } from "zod";

import OnnEventEvaluationFieldAccessControlTypeSchema from "../../../_gen/zodSchema/inputTypeSchemas/OnnEventEvaluationFieldAccessControlTypeSchema";

export type OnnEventEvaluationFieldAccessControlType = z.infer<
  typeof OnnEventEvaluationFieldAccessControlTypeSchema
>;

export const onnEventEvaluationFieldAccessControlTypeSchema =
  OnnEventEvaluationFieldAccessControlTypeSchema;

export const onnEventEvaluationFieldAccessControlTypesTuple =
  OnnEventEvaluationFieldAccessControlTypeSchema._def.values;
