import { z } from "zod";

import { OnnEventSchema } from "../../../domain/_gen/zodSchema";
import { candidateDateSchema } from "../CandidateDate";

export const onnEventSchema = OnnEventSchema.merge(
  z.object({
    deadlineDate: z.date().optional(), // 回答締め切り日
    candidateDates: z.array(candidateDateSchema), // 候補日程リスト
    scheduledDate: z.date().optional(),
    firstDeliveredAt: z.date().optional(),
    canAnswerAfterDeadline: z.boolean().optional(),
    shouldRequestAttendance: z.boolean().optional(),
    registrationInvitationLinkId: z.string().optional(),
    isRestrictedAnswerFromNewGraduate: z.boolean().optional(),
    isRestrictedEditAnswerFromNewGraduate: z.boolean().optional(),
    isRestrictedDaysAgoWhenAnswer: z.boolean().optional(),
    daysAgoRestrictAnswer: z
      .number()
      .int()
      .min(1, { message: "1以上の整数を設定してください" })
      .optional(),
    slotDefaultValueSetting: z
      .object({
        slotType: z.enum(["online", "offline"]).optional(),
        online: z
          .object({
            description: z.string().optional(),
            url: z.string().optional(),
          })
          .optional(),
        offline: z
          .object({
            description: z.string().optional(),
          })
          .optional(),
      })
      .optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEvent extends z.infer<typeof onnEventSchema> {}
