import { NewGraduateToDisplay, OnnEventEvaluationRankV2 } from "@onn/common";

import { orderBy } from "lodash";
import { useCallback } from "react";

import { TableData } from "../components/RecruitmentProcessArea/RecruitmentProcessTable/RecruitmentProcessTable";

import { useConvertToOnnEventItemRowProps } from "./useConvertToOnnEventItemRowProps";
import { useConvertToOnnTaskItemRowProps } from "./useConvertToOnnTaskItemRowProps";

import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const useConvertToTableData = () => {
  const { convertToOnnEventItemRowProps } = useConvertToOnnEventItemRowProps();
  const { convertToOnnTaskItemRowProps } = useConvertToOnnTaskItemRowProps();
  const convertToTableData = useCallback(
    (
      dataFromApi: RecruitmentProcessRecordByEmployee[],
      newGraduate: NewGraduateToDisplay,
      onnEventEvaluationRanks: OnnEventEvaluationRankV2[]
    ): TableData => {
      const onnEventEvaluationRanksMap = new Map(
        onnEventEvaluationRanks.map((rank) => [rank.id, rank])
      );

      const tableData = dataFromApi.map((d) => {
        return {
          processRecord: {
            scenarioLabel: d.scenarioLabel,
            recruitmentStatusId: d.processRecord.recruitmentStatusId,
            recruitmentStatusLabel: d.recruitmentStatusLabel,
            progressStatus: d.progressStatusLabel || "",
            items: d.recruitmentProcessRecordItems.flatMap((item) => {
              switch (item.type) {
                case "Event": {
                  const target = d.relation.onnEventsWithRelation?.find((r) => {
                    return r.onnEvent.id === item.onnEventId;
                  });
                  if (!target) {
                    return [];
                  }
                  return convertToOnnEventItemRowProps({
                    onnEventWithRelation: target,
                    newGraduate,
                    onnEventEvaluationRanksMap,
                    processRecordItem: item,
                  });
                }
                case "Task": {
                  const target = d.relation.onnTasksWithRelation?.find((r) => {
                    return r.onnTask.id === item.onnTaskId;
                  });
                  if (!target) {
                    return [];
                  }
                  return convertToOnnTaskItemRowProps({
                    newGraduate,
                    onnTaskWithRelation: target,
                    processRecordItem: item,
                  });
                }
                default: {
                  const _exhaustiveCheck: never = item;
                  return _exhaustiveCheck;
                }
              }
            }),
            createdAt: d.processRecord.createdAt,
            updatedAt: d.processRecord.updatedAt,
          },
        };
      });

      return orderBy(tableData, ["processRecord.createdAt"], ["asc"]);
    },
    [convertToOnnEventItemRowProps, convertToOnnTaskItemRowProps]
  );
  return { convertToTableData };
};
