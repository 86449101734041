import {
  onnEventEvaluationFieldAccessControlTypeSchema,
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationRankV2,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import { z } from "zod";

export const generateFormSchema = ({
  selectableOnnEventEvaluationRanks,
}: {
  selectableOnnEventEvaluationRanks: OnnEventEvaluationRankV2[];
}) => {
  const evaluationTextValueSchema = z.object({
    fieldId: z.string(),
    type: z.literal(OnnEventEvaluationTextField.type),
    value: z.string(),
    fieldAccessControlType: onnEventEvaluationFieldAccessControlTypeSchema,
  });
  const evaluationFileValueSchema = z.object({
    fieldId: z.string(),
    type: z.literal(OnnEventEvaluationFileField.type),
    filePaths: z.array(z.string()),
    fieldAccessControlType: onnEventEvaluationFieldAccessControlTypeSchema,
  });
  const evaluationSingleSelectValueSchema = z.object({
    fieldId: z.string(),
    type: z.literal(OnnEventEvaluationSingleSelectField.type),
    selectedOptionId: z.string().nullable(),
    fieldAccessControlType: onnEventEvaluationFieldAccessControlTypeSchema,
  });
  const evaluationMultipleSelectValueSchema = z.object({
    fieldId: z.string(),
    type: z.literal(OnnEventEvaluationMultipleSelectField.type),
    selectedOptionIds: z.array(z.string()),
    fieldAccessControlType: onnEventEvaluationFieldAccessControlTypeSchema,
  });

  const evaluationField = z.union([
    evaluationTextValueSchema,
    evaluationFileValueSchema,
    evaluationSingleSelectValueSchema,
    evaluationMultipleSelectValueSchema,
  ]);

  return z.object({
    onnEventEvaluationRankId: z.string().superRefine((value, ctx) => {
      if (!selectableOnnEventEvaluationRanks.some((v) => v.id === value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "選択した評価ランクが存在しません",
        });
      }
    }),
    onnEventEvaluationValues: z.array(evaluationField),
  });
};
