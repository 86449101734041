import { v4 } from "uuid";

import { IOnnEventEvaluationRankRelation, onnEventEvaluationRankRelationSchema } from "./schema";

/**
 * 候補者へのイベント評価ランク
 */
export class OnnEventEvaluationRankRelation implements IOnnEventEvaluationRankRelation {
  static readonly validator = onnEventEvaluationRankRelationSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly onnEventId: string;
  readonly employeeId: string;

  readonly onnEventEvaluationRankId?: string;
  readonly updatedEmployeeId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationRankRelation>) {
    const parsedInit = OnnEventEvaluationRankRelation.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;

    this.onnEventEvaluationRankId = parsedInit.onnEventEvaluationRankId;

    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Omit<ExcludeMethods<OnnEventEvaluationRankRelation>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluationRankRelation {
    return new OnnEventEvaluationRankRelation({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update({
    onnEventEvaluationRankId,
    updatedEmployeeId,
  }: {
    onnEventEvaluationRankId?: string;
    updatedEmployeeId: string;
  }): OnnEventEvaluationRankRelation {
    return new OnnEventEvaluationRankRelation({
      ...this,
      updatedAt: new Date(),
      onnEventEvaluationRankId,
      updatedEmployeeId,
    });
  }
}
