import { AnyOnnEventEvaluationField, OnnEventEvaluationRankV2 } from "@onn/common";
import { useCallback } from "react";

import { InputState } from "./InputState";

export const useGenerateDefaultValue = () => {
  const generateDefaultValues = useCallback(
    (
      onnEventEvaluationFields: AnyOnnEventEvaluationField[],
      onnEventEvaluationRanks: OnnEventEvaluationRankV2[]
    ) => {
      const defaultValues: InputState = {
        evaluationRanks: onnEventEvaluationRanks.map((rank) => ({
          id: rank.id,
          label: rank.label,
        })),
        evaluationFields: onnEventEvaluationFields.flatMap((field) => {
          switch (field.type) {
            case "TextField":
              return {
                id: field.id,
                label: field.label,
                type: field.type,
                accessControlType: field.accessControlType,
                hasInputTemplate: field.hasInputTemplate,
                inputTemplate: field.inputTemplate,
                isSavedField: true,
              };
            case "SingleSelectField":
              return {
                id: field.id,
                label: field.label,
                type: field.type,
                options: field.options.map((o) => {
                  return {
                    optionId: o.id,
                    label: o.label,
                  };
                }),
                accessControlType: field.accessControlType,
                isSavedField: true,
              };
            case "MultipleSelectField":
              return {
                id: field.id,
                label: field.label,
                type: field.type,
                options: field.options.map((o) => {
                  return {
                    optionId: o.id,
                    label: o.label,
                  };
                }),
                accessControlType: field.accessControlType,
                isSavedField: true,
              };
            // NOTE: ファイルタイプは固定値なのでRHFでは管理しない
            case "FileField": {
              return [];
            }
            default: {
              const _exhaustiveCheck: never = field;
              return _exhaustiveCheck;
            }
          }
        }),
      };

      return defaultValues;
    },
    []
  );

  return { generateDefaultValues };
};
