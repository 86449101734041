import {
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import { useCallback } from "react";

import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";

import { v4 } from "uuid";

import { EvaluationTextField, InputState } from "../../hooks/form/InputState";

/**
 * 評価フィールドの操作に関するカスタムフック
 * - フィールドの追加
 * - フィールドの削除
 * - フィールドのドラッグアンドドロップ
 * - フィールドの複製
 */
export const useOnnEventFieldArray = () => {
  const { getValues } = useFormContext<InputState>();
  const {
    move: moveFields,
    append: appendField,
    remove: removeField,
    insert: insertField,
    fields,
  } = useFieldArray<InputState>({
    name: "evaluationFields",
  });

  const onAddField = useCallback(() => {
    const textTypeField: EvaluationTextField = {
      id: v4(),
      label: "",
      type: OnnEventEvaluationTextField.type,
      accessControlType: "ALL",
      hasInputTemplate: false,
      inputTemplate: "",
      isSavedField: false,
    };
    appendField(textTypeField);
  }, [appendField]);

  const onRemoveField = useCallback(
    (fieldIndex: number) => {
      removeField(fieldIndex);
    },
    [removeField]
  );

  const onDragFieldEnd = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      moveFields(sourceIndex, destinationIndex);
    },
    [moveFields]
  );

  const onDuplicateField = useCallback(
    (fieldIndex: number) => {
      const field = getValues(`evaluationFields.${fieldIndex}`);

      const common = {
        id: v4(),
        isSavedField: false,
        label: `${field.label} のコピー`,
      };
      switch (field.type) {
        case OnnEventEvaluationTextField.type:
        case OnnEventEvaluationFileField.type: {
          const duplicatedField = {
            ...field,
            ...common,
          };
          insertField(fieldIndex + 1, duplicatedField);
          break;
        }
        case OnnEventEvaluationMultipleSelectField.type:
        case OnnEventEvaluationSingleSelectField.type: {
          const duplicatedField = {
            ...field,
            ...common,
            // NOTE: 選択肢のidも新規に発行する
            options: field.options?.map((option) => ({ ...option, optionId: v4() })),
          };
          insertField(fieldIndex + 1, duplicatedField);
        }
      }
    },
    [getValues, insertField]
  );

  return {
    onAddField,
    onRemoveField,
    onDragFieldEnd,
    onDuplicateField,
    fields,
  };
};
