import React, { createContext, FC, ReactNode, useCallback, useState } from "react";

export const EditOnnEventEvaluationDrawerContext = createContext<{
  newGraduateId: string | undefined;
  onnEventId: string | undefined;
  isOpen: boolean;
  open: (args: { newGraduateId: string; onnEventId: string }) => void;
  cancel: () => void;
}>({
  newGraduateId: undefined,
  onnEventId: undefined,
  isOpen: false,
  open: () => {},
  cancel: () => {},
});

export const EditOnnEventEvaluationDrawerProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [newGraduateId, setNewGraduateId] = useState<string | undefined>();
  const [onnEventId, setOnnEventId] = useState<string | undefined>();

  const open = useCallback(
    ({ newGraduateId, onnEventId }: { newGraduateId: string; onnEventId: string }) => {
      setNewGraduateId(newGraduateId);
      setOnnEventId(onnEventId);
    },
    []
  );

  const cancel = useCallback(() => {
    setNewGraduateId(undefined);
    setOnnEventId(undefined);
  }, []);

  const isOpen = newGraduateId !== undefined && onnEventId !== undefined;
  return (
    <EditOnnEventEvaluationDrawerContext.Provider
      value={{ newGraduateId, onnEventId, isOpen, open, cancel }}
    >
      {children}
    </EditOnnEventEvaluationDrawerContext.Provider>
  );
};
