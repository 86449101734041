import {
  AnyOnnEventEvaluationField,
  AnyOnnEventEvaluationValue,
  OnnEventEvaluationDraft,
  partitionAnyOnnEventEvaluationValues,
} from "@onn/common";
import { useCallback } from "react";

import { FieldValues } from "./FieldValues";

export const useGenerateDefaultValues = () => {
  const generateDefaultValuesWhenAlreadyEvaluated = useCallback(
    ({
      onnEventEvaluationFields,
      registeredOnnEventEvaluationValues,
      onnEventEvaluationRankId,
    }: {
      onnEventEvaluationFields: AnyOnnEventEvaluationField[];
      registeredOnnEventEvaluationValues: AnyOnnEventEvaluationValue[];
      onnEventEvaluationRankId?: string;
    }) => {
      const { textValues, fileValues, singleSelectValues, multipleSelectValues } =
        partitionAnyOnnEventEvaluationValues(registeredOnnEventEvaluationValues);

      const defaultValues: FieldValues = {
        onnEventEvaluationRankId: onnEventEvaluationRankId ?? "",
        onnEventEvaluationValues: onnEventEvaluationFields.map((field) => {
          switch (field.type) {
            case "TextField": {
              const registeredValue = textValues.find(
                (v) => v.onnEventEvaluationTextFieldId === field.id
              )?.value;
              // NOTE: 評価値がundefinedの場合は、inputTemplateがあればそれを初期値とする
              const value = registeredValue ?? field.inputTemplate ?? "";
              return {
                fieldId: field.id,
                type: field.type,
                value: value,
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "FileField": {
              const registeredFilePaths = fileValues.find(
                (v) => v.onnEventEvaluationFileFieldId === field.id
              )?.filePaths;

              return {
                fieldId: field.id,
                type: field.type,
                filePaths: registeredFilePaths ?? [],
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "SingleSelectField": {
              const registeredOptionId = singleSelectValues.find(
                (v) => v.onnEventEvaluationSingleSelectFieldId === field.id
              )?.onnEventEvaluationSingleSelectOptionId;
              return {
                fieldId: field.id,
                type: field.type,
                selectedOptionId: registeredOptionId || null,
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "MultipleSelectField": {
              const registeredOptionIds = multipleSelectValues
                .filter((v) => v.onnEventEvaluationMultipleSelectFieldId === field.id)
                .map((v) => v.onnEventEvaluationMultipleSelectOptionId);
              return {
                fieldId: field.id,
                type: field.type,
                selectedOptionIds: registeredOptionIds,
                fieldAccessControlType: field.accessControlType,
              };
            }
            default: {
              const _exhaustiveCheck: never = field;
              return _exhaustiveCheck;
            }
          }
        }),
      };

      return defaultValues;
    },
    []
  );

  const generateDefaultValuesWhenNotEvaluated = useCallback(
    ({
      onnEventEvaluationFields,
      onnEventEvaluationDraft,
    }: {
      onnEventEvaluationFields: AnyOnnEventEvaluationField[];
      onnEventEvaluationDraft?: OnnEventEvaluationDraft;
    }) => {
      const defaultValues: FieldValues = {
        onnEventEvaluationRankId: onnEventEvaluationDraft?.onnEventEvaluationRankId ?? "",
        onnEventEvaluationValues: onnEventEvaluationFields.map((field) => {
          switch (field.type) {
            case "TextField": {
              const draftValue = onnEventEvaluationDraft?.findDraftValueByFieldId<"TextField">({
                fieldId: field.id,
              });
              return {
                fieldId: field.id,
                type: field.type,
                value: draftValue?.value ?? "",
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "FileField": {
              const draftValue = onnEventEvaluationDraft?.findDraftValueByFieldId<"FileField">({
                fieldId: field.id,
              });
              return {
                fieldId: field.id,
                type: field.type,
                filePaths: draftValue?.filePaths ?? [],
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "SingleSelectField": {
              const draftValue =
                onnEventEvaluationDraft?.findDraftValueByFieldId<"SingleSelectField">({
                  fieldId: field.id,
                });
              return {
                fieldId: field.id,
                type: field.type,
                selectedOptionId: draftValue?.onnEventEvaluationSingleSelectOptionId || null,
                fieldAccessControlType: field.accessControlType,
              };
            }
            case "MultipleSelectField": {
              const draftValue =
                onnEventEvaluationDraft?.findDraftValueByFieldId<"MultipleSelectField">({
                  fieldId: field.id,
                });
              const draftOptionIds =
                draftValue?.map((df) => df.onnEventEvaluationMultipleSelectOptionId) || [];
              return {
                fieldId: field.id,
                type: field.type,
                selectedOptionIds: draftOptionIds,
                fieldAccessControlType: field.accessControlType,
              };
            }
            default: {
              const _exhaustiveCheck: never = field;
              return _exhaustiveCheck;
            }
          }
        }),
      };

      return defaultValues;
    },
    []
  );

  return { generateDefaultValuesWhenAlreadyEvaluated, generateDefaultValuesWhenNotEvaluated };
};
