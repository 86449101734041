import { canEditOnnEventEvaluationValue } from "@onn/common";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { FormState } from "./FormState";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnEventEvaluationDraft } from "~/hooks/onnEventEvaluation/useUpdateOnnEventEvaluationDraft";

/**
 * 下書きを自動保存するhooks
 */
export const useAutoSaveDraft = ({
  form,
  mode,
  onnEventId,
  targetEmployeeId,
}: {
  form: UseFormReturn<FormState>;
  mode: "new" | "edit";
  onnEventId: string;
  targetEmployeeId: string;
}) => {
  const { watch } = form;
  const { currentUser } = useCurrentUser();
  const { updateOnnEventEvaluationDraft, isUpdatingDraft } = useUpdateOnnEventEvaluationDraft();
  const updateDraftDebounce = useMemo(() => {
    return debounce((inputValue: FormState) => {
      const onnEventEvaluationDraftValuesToUpdate = inputValue.onnEventEvaluationValues.filter(
        (inputValue) =>
          canEditOnnEventEvaluationValue({
            currentUser,
            // NOTE: fieldAccessControlTypeは、RHFで持たせるべきではないため、改修時に直す
            fieldAccessControlType: inputValue.fieldAccessControlType,
          })
      );

      updateOnnEventEvaluationDraft({
        onnEventId,
        targetEmployeeId,
        onnEventEvaluationRankId: inputValue.onnEventEvaluationRankId || undefined,
        onnEventEvaluationValues: onnEventEvaluationDraftValuesToUpdate.flatMap((inputValue) => {
          switch (inputValue.type) {
            case "TextField": {
              return {
                type: "TextField",
                fieldId: inputValue.fieldId,
                value: inputValue.value,
              };
            }
            case "FileField": {
              return {
                type: "FileField",
                fieldId: inputValue.fieldId,
                filePaths: inputValue.filePaths,
              };
            }
            case "SingleSelectField": {
              // NOTE: optionIdがnullのvalueは外部キー制約でエラーになるので、空の場合は送信しない
              return inputValue.selectedOptionId
                ? {
                    type: "SingleSelectField",
                    fieldId: inputValue.fieldId,
                    onnEventEvaluationSingleSelectOptionId: inputValue.selectedOptionId,
                  }
                : [];
            }
            case "MultipleSelectField": {
              return {
                type: "MultipleSelectField",
                fieldId: inputValue.fieldId,
                onnEventEvaluationMultipleSelectOptionIds: inputValue.selectedOptionIds,
              };
            }
            default: {
              const _exhaustiveCheck: never = inputValue;
              return _exhaustiveCheck;
            }
          }
        }),
      });
      // NOTE: 2sが好ましいが、既存モーダルコンポーネントの実装の都合上、モーダルを閉じるときに下書き上書きが難しいので、一旦1sに設定
    }, 1000);
  }, [currentUser, onnEventId, targetEmployeeId, updateOnnEventEvaluationDraft]);

  const [isFirstChanged, setIsFirstChanged] = useState(false);
  useEffect(() => {
    // NOTE: 評価編集の場合はすでにデータが存在するため、下書きの自動保存は行わない
    if (mode === "edit") return;
    const { unsubscribe } = watch((inputValue) => {
      // NOTE: なぜか初回レンダリング時にwatchが発火するので初回の変更は無視する
      if (!isFirstChanged) {
        setIsFirstChanged(true);
        return;
      }
      updateDraftDebounce(inputValue as FormState);
    });
    return () => unsubscribe();
  }, [isFirstChanged, mode, updateDraftDebounce, watch]);

  return { updateDraftDebounce, isUpdatingDraft };
};
