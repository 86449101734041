import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Stack } from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import styled from "styled-components";

import { isCustomEmployeeInformationGroupLabel, useFieldSelection } from "../useFieldSelection";

import { SelectConditionMenuItemGroup } from "./parts/SelectConditionMenuItemGroup";

import { Typography } from "~/components/uiParts";

type TabValue = "new_graduates" | "recruitment";
const tabs: Array<{ label: string; value: TabValue }> = [
  {
    label: "候補者情報",
    value: "new_graduates",
  },
  {
    label: "選考情報",
    value: "recruitment",
  },
];

export const FieldSelectStepContent = ({
  selectableFieldsMap,
  selectedFieldKeys,
  selectAll,
  unselectAll,
  ...props
}: ReturnType<typeof useFieldSelection>): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabValue>("new_graduates");

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    setActiveTab(newValue);
  };

  return (
    // Modalのスクロールバーに重なるため、右側にpaddingを追加して調整している
    <Stack rowGap="24px" pr="8px">
      <Typography align="center">CSVファイルに書き出す項目を選択してください</Typography>
      <TabContext value={activeTab}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <Box display="flex" alignItems="center" columnGap="8px">
            <Typography variant="caption">選択中：</Typography>
            <Box
              sx={{
                borderRadius: "5px",
                backgroundColor: ({ palette }) => palette.primary.main,
                color: "white",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="overline" bold>
                {selectedFieldKeys.length}
              </Typography>
            </Box>
          </Box>
        </Box>
        <StyledTabPanel value="new_graduates">
          <Box display={"flex"} columnGap={"16px"}>
            <Box minWidth="312px">
              <SelectConditionMenuItemGroup
                title="基本情報"
                selectAll={() => selectAll("NEW_GRADUATE")}
                unselectAll={() => unselectAll("NEW_GRADUATE")}
                menuItems={selectableFieldsMap["NEW_GRADUATE"] || []}
                {...props}
              />
            </Box>
            <Box minWidth="312px">
              <SelectConditionMenuItemGroup
                title="採用管理"
                selectAll={() => selectAll("RECRUITMENT_MANAGEMENT")}
                unselectAll={() => unselectAll("RECRUITMENT_MANAGEMENT")}
                menuItems={selectableFieldsMap["RECRUITMENT_MANAGEMENT"] || []}
                {...props}
              />
            </Box>
            {Object.entries(selectableFieldsMap).map(([key, value]) => {
              if (isCustomEmployeeInformationGroupLabel(key)) {
                return (
                  <Box key={key} minWidth="312px">
                    <SelectConditionMenuItemGroup
                      title={key}
                      selectAll={() => selectAll(key)}
                      unselectAll={() => unselectAll(key)}
                      menuItems={value}
                      {...props}
                    />
                  </Box>
                );
              }
            })}
          </Box>
        </StyledTabPanel>
        <StyledTabPanel value="recruitment">
          <Box width="492px">
            <SelectConditionMenuItemGroup
              title="選考情報"
              selectAll={() => selectAll("RECRUITMENT_INFORMATION")}
              unselectAll={() => unselectAll("RECRUITMENT_INFORMATION")}
              menuItems={selectableFieldsMap["RECRUITMENT_INFORMATION"] || []}
              {...props}
            />
          </Box>
        </StyledTabPanel>
      </TabContext>
    </Stack>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    padding: 0 16px;
    height: 54px;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 16px 24px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  overflow-x: scroll;
  &.MuiTabPanel-root {
    padding: 8px 0;
  }
`;
