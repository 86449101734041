import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEvent } from "@onn/common";
import { useCallback } from "react";
import { FieldErrors, UseFormHandleSubmit, useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import {
  InputState,
  createOnnEventOrNull,
  getOnnEventsFormSchema,
} from "../../../_shared/createAndEdit";

import { useGenerateRHFDefaultValue } from "./useGenerateRHFDefaultValue";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateOnnEvent, useSelectableTimeItems } from "~/hooks/onnEvent";
import { useOpenPortalOnnEventPreview } from "~/hooks/openPortalPreview/onnEvent";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { captureException } from "~/util";

export const usePage = ({
  eventType,
}: {
  eventType: Extract<OnnEvent["type"], "normal" | "new_interview">;
}) => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const { defaultValues } = useGenerateRHFDefaultValue({ eventType });

  const form = useForm<InputState>({
    defaultValues,
    mode: "onChange",
    resolver: zodResolver(getOnnEventsFormSchema({ isEdit: false })),
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = form;

  const { fromTimeItems, untilTimeItems } = useSelectableTimeItems();

  const RHFInput = watch();

  /**
   * NOTE:
   * 「保存して次へ」ボタンのdisabled判定に使用。
   * また、createOnnEventリクエストにも使用する
   */
  const onnEventOrNull = createOnnEventOrNull({
    tenantId: tenant.tenantId,
    spaceId: currentSpace.id,
    currentUserId: currentUser.id,
    RHFInput,
    isEdit: false,
  });

  const {
    isDisableSaveButton,
    isReadyToOpenPortalOnnEventPreview,
    onClickCancel,
    onClickPreview,
    onClickSaveAndToNext,
    isCreatingOnnEvent,
  } = useActionFooter({ onnEventOrNull, handleSubmit, fieldErrors: errors });

  return {
    isDisableSaveButton: isDisableSaveButton || isCreatingOnnEvent,
    footerAction: {
      onClickCancel,
      isReadyToOpenPortalOnnEventPreview,
      onClickSaveAndToNext,
      onClickPreview,
    },
    candidateForm: {
      fromTimeItems,
      untilTimeItems,
    },
    form,
  };
};

const useActionFooter = ({
  onnEventOrNull,
  handleSubmit,
  fieldErrors,
}: {
  onnEventOrNull: OnnEvent | null;
  handleSubmit: UseFormHandleSubmit<InputState>;
  fieldErrors: FieldErrors<InputState>;
}) => {
  const navigation = useNavigate();
  const { execCreateOnnEvent, isLoading: isCreatingOnnEvent } = useCreateOnnEvent();
  const { openPortalOnnEventPreview, isReady: isReadyToOpenPortalOnnEventPreview } =
    useOpenPortalOnnEventPreview();
  const { enqueueSnackbar } = useSnackbar();

  // onnEventドメインオブジェクトの生成に失敗（zodバリデーション失敗）or formがエラー状態の時は次へ進めない
  const isDisableSaveButton = onnEventOrNull === null || Object.keys(fieldErrors).length !== 0;

  // 保存して次へボタンクリック時のハンドラー
  const onClickSaveAndToNext = useCallback(async () => {
    if (!isDisableSaveButton) {
      try {
        // 新面談タイプの場合は、予約枠に担当者が紐づくので、イベント自体のassigneeIdsは空にする
        if (onnEventOrNull.type === "new_interview") {
          onnEventOrNull.updateNewInterview({ assigneeIds: [] });
        }
        await execCreateOnnEvent({ onnEvent: onnEventOrNull });
        onnEventOrNull.isNewInterviewEvent()
          ? navigation(`/events/${onnEventOrNull.id}/slot_default_value_setting?from_page=create`)
          : navigation(`/events/${onnEventOrNull.id}/evaluation_setting?from_page=create`);
      } catch (e) {
        enqueueSnackbar("イベントの作成に失敗しました。", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "usePage:onClickSaveAndToNext" },
        });
      }
    }
  }, [enqueueSnackbar, execCreateOnnEvent, isDisableSaveButton, navigation, onnEventOrNull]);

  // プレビューボタンクリック時のハンドラー
  const onClickPreview = useCallback(() => {
    onnEventOrNull && openPortalOnnEventPreview(onnEventOrNull);
  }, [onnEventOrNull, openPortalOnnEventPreview]);

  // キャンセルボタンクリック時のハンドラー
  const onClickCancel = () => {
    navigation(`/events/`);
  };

  return {
    isDisableSaveButton,
    onClickCancel,
    isReadyToOpenPortalOnnEventPreview,
    onClickSaveAndToNext: handleSubmit(onClickSaveAndToNext),
    onClickPreview,
    isCreatingOnnEvent,
  };
};
