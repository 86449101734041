import { FormControlLabel } from "@material-ui/core";
import { Box, Stack } from "@mui/material";
import React from "react";

import styled from "styled-components";

import { Checkbox, Chip, Typography } from "~/components/uiParts";

export const ScenarioConditionTab = ({
  options,
  selectedScenarioIds,
  selectedCount,
  isSelectedSome,
  isSelectedAll,
  toggleIsSelected,
  toggleIsSelectedAll,
}: {
  options: {
    value: string;
    label: string;
  }[];
  selectedScenarioIds: string[];
  selectedCount: number;
  isSelectedSome: boolean;
  isSelectedAll: boolean;
  toggleIsSelected: (scenarioId: string) => void;
  toggleIsSelectedAll: () => void;
}): JSX.Element => {
  return (
    <Box>
      <Box my="24px">
        <StyledFormControlLabel
          control={
            <Checkbox
              onChange={toggleIsSelectedAll}
              checked={isSelectedAll}
              indeterminate={isSelectedSome}
            />
          }
          label={
            <StyledSelectAllBox display="flex">
              <Typography variant="body2" color="textPrimary">
                全て選択
              </Typography>
              <StyledChip color="grey" label={selectedCount} bold />
            </StyledSelectAllBox>
          }
        />
      </Box>
      <Stack gap="16px">
        {options.map((option, i) => (
          <StyledFormControlLabel
            key={i}
            control={
              <Checkbox
                onChange={() => toggleIsSelected(option.value)}
                checked={selectedScenarioIds.includes(option.value)}
              />
            }
            label={
              <Box width="100%" display="flex" alignItems={"center"}>
                <StyledTypography variant="body2" noWrap>
                  {option.label}
                </StyledTypography>
              </Box>
            }
          />
        ))}
      </Stack>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
    gap: 8px;
    height: 24px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 0px 0px;
    margin: 11.5px 0px;
    color: #000000;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
