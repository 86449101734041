import { v4 } from "uuid";

import { IOnnEventEvaluationRankV2, onnEventEvaluationRankV2Schema } from "./schema";

export class OnnEventEvaluationRankV2 implements IOnnEventEvaluationRankV2 {
  static validator = onnEventEvaluationRankV2Schema;

  id: string;
  tenantId: string;
  onnEventId: string;
  label: string;
  order: number;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationRankV2>) {
    const parsedInit = OnnEventEvaluationRankV2.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventId = parsedInit.onnEventId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<OnnEventEvaluationRankV2>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluationRankV2 {
    return new OnnEventEvaluationRankV2({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createDefaultRanks(params: {
    onnEventId: string;
    tenantId: string;
    spaceId: string;
  }): OnnEventEvaluationRankV2[] {
    const defaultRankLabels = ["A", "B", "C"];
    return defaultRankLabels.map((label, index) =>
      OnnEventEvaluationRankV2.createNew({
        label,
        order: index + 1,
        ...params,
      })
    );
  }
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationRankV2>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationRankV2 {
    return new OnnEventEvaluationRankV2({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(params: Pick<OnnEventEvaluationRankV2, "label" | "order">): OnnEventEvaluationRankV2 {
    return new OnnEventEvaluationRankV2({
      ...this,
      label: params.label,
      order: params.order,
      updatedAt: new Date(),
    });
  }
}
