import {
  EmployeeInformationGroup,
  instantiateFromAnyEmployeeInformationFieldWithOptions,
} from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const getKey = () => {
  return { endpoint: "/api/employee-information-groups" } as const;
};

export const useEmployeeInformationGroups = () => {
  return useSWR(getKey(), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);

    return response.data.map((v) => {
      const { employeeInformationGroup, employeeInformationFieldWithOptions } = v;

      return {
        employeeInformationGroup: new EmployeeInformationGroup(employeeInformationGroup),
        employeeInformationFieldWithOptions: employeeInformationFieldWithOptions.map((v) => {
          return instantiateFromAnyEmployeeInformationFieldWithOptions(v);
        }),
      };
    });
  });
};

export const mutateEmployeeInformationGroups = () => {
  return mutate(getKey());
};

export type EmployeeInformationGroupData = Array<{
  employeeInformationGroup: EmployeeInformationGroup;
  employeeInformationFieldWithOptions: Array<{ id: string; label: string }>;
}>;
