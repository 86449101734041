import { Button, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import {
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  generateOnnEventEvaluationFilePath,
  OnnEventEvaluationFileField,
} from "@onn/common";
import React, { FC, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { v4 } from "uuid";

import { FieldValues } from "../../../hooks/form/FieldValues";

import { FieldLabelArea } from "./_shared/FieldLabelArea";

import { FilePicker, Icon, IconButton, Loading } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { colors } from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { useFileViewer, useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

type File = { path: string; name: string };

export const OnnEventEvaluationFileFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: OnnEventEvaluationFileField;
  setIsUploadingFile: (isUploadingFile: boolean) => void;
  isUploadingFile: boolean;
}> = ({ onnEventEvaluationField, fieldIndex, setIsUploadingFile, isUploadingFile }) => {
  const { field: valueField } = useController<
    FieldValues,
    `onnEventEvaluationValues.${number}.filePaths`
  >({
    name: `onnEventEvaluationValues.${fieldIndex}.filePaths`,
  });
  const { onAddFile, onRemoveFile } = useFileFields({
    fieldIndex,
    setIsUploadingFile,
  });

  return (
    <>
      <FieldLabelArea onnEventEvaluationField={onnEventEvaluationField} />
      <FileAttachButton onChange={onAddFile} />
      <AttachedFileList filePaths={valueField.value} onRemoveFile={onRemoveFile} />
      {isUploadingFile && <Loading size="small" />}
    </>
  );
};

const useFileFields = ({
  fieldIndex,
  setIsUploadingFile,
}: {
  fieldIndex: number;
  setIsUploadingFile: (isUploadingFile: boolean) => void;
}) => {
  const { setValue, getValues } = useFormContext<FieldValues>();

  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const onAddFile = useCallback(
    async (payload: Payload) => {
      if (payload.status === "error") {
        return enqueueSnackbar(payload.message, { variant: "error" });
      }

      const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });
      setIsUploadingFile(true);
      for (const file of payload.files) {
        const filePath = generateOnnEventEvaluationFilePath({
          tenantId: currentUser.tenantId,
          fileName: file.name,
          pathUid: v4(),
        });
        try {
          await fileAPIAdapter.upload({
            file,
            path: filePath,
          });
        } catch (error) {
          enqueueSnackbar((error as Error).message, { variant: "error" });
        }
        const currentFilePaths = getValues(`onnEventEvaluationValues.${fieldIndex}.filePaths`);

        setValue<`onnEventEvaluationValues.${number}.filePaths`>(
          `onnEventEvaluationValues.${fieldIndex}.filePaths`,
          [...currentFilePaths, filePath],
          {
            shouldDirty: true,
          }
        );
      }
      setIsUploadingFile(false);
    },
    [currentUser.tenantId, enqueueSnackbar, fieldIndex, getValues, setIsUploadingFile, setValue]
  );

  const onRemoveFile = useCallback(
    (index: number) => {
      const currentFilePaths = getValues(`onnEventEvaluationValues.${fieldIndex}.filePaths`);
      const newFilePaths = currentFilePaths.filter((_, i) => i !== index);
      setValue(`onnEventEvaluationValues.${fieldIndex}.filePaths`, newFilePaths, {
        shouldDirty: true,
      });
    },
    [fieldIndex, getValues, setValue]
  );

  return { onAddFile, onRemoveFile };
};

const AttachedFileList: FC<{
  filePaths: string[];
  onRemoveFile: (index: number) => void;
}> = ({ filePaths, onRemoveFile }) => {
  const { setFiles, setPreviewFileIndex } = useFileViewer();
  const files = filePaths?.map((path) => ({
    path,
    name: path.split("/").pop() || "",
  }));

  const onClickFile = useCallback(
    async (file: File) => {
      const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });
      const fileUrl = await fileAPIAdapter.fetchUrl({ path: file.path });
      if (fileUrl) {
        setFiles([{ name: file.name, url: fileUrl, size: 100 }]);
        setPreviewFileIndex(0);
      } else {
        captureException({
          error: new Error("ファイルが取得できませんでした"),
          tags: { type: "RelatedFilesCell" },
          extras: { filePath: file.path },
        });
      }
    },
    [setFiles, setPreviewFileIndex]
  );

  return files.map((file, index) => {
    return (
      <Stack
        key={file.path}
        width="100%"
        onClick={() => onClickFile(file)}
        style={{ cursor: "pointer" }}
        paddingRight={"16px"}
        paddingLeft={"16px"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          border: "1px solid",
          borderColor: colors.grey[100],
          borderRadius: "8px",
        }}
        height={"60px"}
      >
        <Typography
          variant="body1"
          color="primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => onClickFile(file)}
        >
          {file.name}
        </Typography>
        <IconButton
          icon="trash"
          color="grey"
          size="md"
          onClick={(e) => {
            onRemoveFile(index);
            e.stopPropagation();
          }}
        />
      </Stack>
    );
  });
};

const FileAttachButton: FC<{
  onChange: (payload: Payload) => void;
}> = ({ onChange }) => {
  return (
    <FilePicker
      accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
      multiple
      onChange={onChange}
      inputAccept={
        "application/gzip, .pdf, .doc, .docx, .xls, .xlsx, .pptx, .ppt, .gif, .jpg, .jpeg, .png, .zip"
      }
    >
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Icon size="sm" icon="clip" color="primary" />}
        onClick={() => void 0}
      >
        ファイルを追加
      </Button>
    </FilePicker>
  );
};
