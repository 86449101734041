import { Stack } from "@mui/material";
import { OnnEventEvaluationTextField } from "@onn/common";
import React, { FC } from "react";
import { useController } from "react-hook-form";

import { FormState } from "../../../hooks/form/FormState";

import { FieldLabelArea } from "./_shared/FieldLabelArea";

import { TextareaAutosize } from "~/components/uiParts";

export const OnnEventEvaluationTextFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: OnnEventEvaluationTextField;
  isDisabled: boolean;
}> = ({ onnEventEvaluationField, fieldIndex, isDisabled }) => {
  const { field: valueField } = useController<
    FormState,
    `onnEventEvaluationValues.${number}.value`
  >({
    name: `onnEventEvaluationValues.${fieldIndex}.value`,
  });

  return (
    <Stack spacing={"8px"}>
      <FieldLabelArea onnEventEvaluationField={onnEventEvaluationField} />
      <TextareaAutosize
        fullWidth
        value={valueField.value}
        placeholder={`入力してください`}
        onChange={(e) => valueField.onChange(e.target.value)}
        minRows={4}
        disabled={isDisabled}
      />
    </Stack>
  );
};
