import { Box } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

import { EmployeeInformationMultipleSelectTypeFieldCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

import { Choice } from "~/components/types/choice";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

const TARGET = "employeeInformationMultipleSelectTypeField";

type Props = CommonProps<EmployeeInformationMultipleSelectTypeFieldCondition>;
export const EmployeeInformationMultipleSelectFieldConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: employeeInformationGroups } = useEmployeeInformationGroups();
  const choices = useMemo(() => {
    const employeeInformation = employeeInformationGroups
      ?.flatMap((group) => group.employeeInformationFieldWithOptions)
      .find((info) => info.id === condition.employeeInformationId);

    if (employeeInformation?.type !== "MULTIPLE_SELECT") return [];

    return employeeInformation.options.map((option) => ({ label: option.label, value: option.id }));
  }, [employeeInformationGroups, condition.employeeInformationId]);

  const onChange = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        employeeInformationOptionIds: newChoices.map((c) => c.value),
      });
    },
    [index, condition, onChangeCondition]
  );

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
          employeeInformationId={condition.employeeInformationId}
        />
      </Box>
      <Box flex={9}>
        <SelectMultipleConditionDropdown
          key={`multipleSelect-${condition.employeeInformationId}`}
          selectedChoices={choices.filter((c) =>
            condition.employeeInformationOptionIds?.includes(c.value)
          )}
          choices={choices}
          withSearch
          searchPlaceholder="項目を検索"
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};
