import { Box, Stack } from "@mui/material";
import React from "react";
import { useController, useFormState } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../../hooks/form/InputState";

import { useOnnEventRankArray } from "./useOnnEventRankArray";

import {
  Button,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "~/components/uiParts";

export const OnnEventEvaluationRankForm: React.FC<{
  undeletableOnnEventEvaluationRankIds: string[];
}> = ({ undeletableOnnEventEvaluationRankIds }) => {
  const { fields, onAddRank, onRemoveRank, onDragRankEnd } = useOnnEventRankArray();

  return (
    <Stack spacing={"24px"}>
      <Box mb={4}>
        <Typography variant="body1" color="textPrimary" bold>
          評価項目
        </Typography>
      </Box>
      <Paper>
        <DnDProvider onDragEnd={onDragRankEnd}>
          <DnDDroppable droppableId={`onn-event-evaluation-rank-form`} isDropDisabled={false}>
            {fields.map((field, fieldIndex) => {
              return (
                <DnDDraggable
                  key={field._id}
                  isDragDisabled={false}
                  draggableId={field._id}
                  index={fieldIndex}
                >
                  <RankInputRow
                    onRemoveRank={onRemoveRank}
                    fieldIndex={fieldIndex}
                    isShowRemoveButton={fields.length > 1}
                    undeletableOnnEventEvaluationRankIds={undeletableOnnEventEvaluationRankIds}
                  />
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
        </DnDProvider>
        <Button
          variant="text"
          color="primary"
          borderRadius="regular"
          onClick={onAddRank}
          startIcon={<Icon icon="add" color="primary" size="md" />}
        >
          評価項目を追加
        </Button>
      </Paper>
    </Stack>
  );
};

const RankInputRow: React.FC<{
  onRemoveRank: (fieldIndex: number) => void;
  fieldIndex: number;
  isShowRemoveButton?: boolean;
  undeletableOnnEventEvaluationRankIds: string[];
}> = ({ onRemoveRank, fieldIndex, isShowRemoveButton, undeletableOnnEventEvaluationRankIds }) => {
  const { defaultValues } = useFormState<InputState>();
  const { field, fieldState } = useController<InputState, `evaluationRanks.${number}.label`>({
    name: `evaluationRanks.${fieldIndex}.label`,
  });
  const { field: rankIdField } = useController({
    name: `evaluationRanks.${fieldIndex}.id`,
  });

  // NOTE: fieldIndex で label の defaultValues から探すと、評価項目が削除された場合に index がズレるため id で探す
  const defaultRankFieldId = rankIdField.value;
  const defaultRank = defaultValues?.evaluationRanks?.find(
    (rank) => rank && rank.id === defaultRankFieldId
  );
  const defaultRankLabel = defaultRank?.label;

  const isUnremovable = undeletableOnnEventEvaluationRankIds.includes(rankIdField.value);

  return (
    <StyledWrapper
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <StyledGrip id="gripVerticalWrapper" display="flex" height="16px" minWidth="24px">
        <Icon icon="gripVertical" size="sm" color="lightGrey" />
      </StyledGrip>
      <StyledTextField
        value={field.value}
        onChange={field.onChange}
        fullWidth
        error={!!fieldState.error}
        helperText={fieldState.error?.message || defaultRankLabel}
        placeholder="評価項目を入力してください"
      />
      {isShowRemoveButton && (
        <Box ml="16px">
          <Tooltip
            title={
              isUnremovable
                ? "この評価ランクは、評価で使用中、もしくはシナリオのアクション条件で使用中のため削除できません。"
                : ""
            }
            placement="top"
          >
            <Box style={{ cursor: isUnremovable ? "not-allowed" : "pointer" }}>
              <IconButton
                icon="close"
                onClick={() => onRemoveRank(fieldIndex)}
                disabled={isUnremovable}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-bottom: 20px;

  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
  }
`;

const StyledGrip = styled(Box)`
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translate(0, -50%);
`;
