import {
  NewGraduateToDisplay,
  OnnEvent,
  OnnEventAnswer,
  OnnEventDeliveryCancellationLog,
  OnnEventDeterminedDate,
  OnnEventEvaluationRankRelation,
  OnnEventEvaluationRankV2,
  OnnEventSlotDate,
  RecruitmentProcessRecordOnnEventItem,
} from "@onn/common";
import { isPast } from "date-fns";
import { compact, orderBy } from "lodash";
import { useCallback } from "react";

import { RecruitmentProcessRecordOnnEventItemRowProps } from "../components/RecruitmentProcessArea/RecruitmentProcessTable/body/rows/RecruitmentProcessRecordOnnEventItemRow/RecruitmentProcessRecordOnnEventItemRow";

import { OnnEventStatusCellProps } from "../components/RecruitmentProcessArea/RecruitmentProcessTable/body/rows/RecruitmentProcessRecordOnnEventItemRow/cells/OnnEventStatusCell";

import { AnswerStatus } from "~/components/domains/onnEvents/AnswerStatusChip/AnswerStatusChip";
import { useConvertFromDeterminedDateAttendanceStatus } from "~/components/domains/onnEvents/AttendanceStatusMenu";
import { useCurrentUser } from "~/hooks/employee";
import { OnnEventWithRelation } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const useConvertToOnnEventItemRowProps = () => {
  const { calculateImplementationDate } = useCalculateImplementationDate();
  const { calculateAnswerStatus } = useCalculateAnswerStatus();
  const { calculateRowUpdatedAt } = useCalculateRowUpdatedAt();

  const { generateOnnEventStatusCellProps } = useGenerateOnnEventStatusCellProps();

  const { currentUser } = useCurrentUser();

  const convertToOnnEventItemRowProps = useCallback(
    ({
      onnEventWithRelation,
      newGraduate,
      onnEventEvaluationRanksMap,
      processRecordItem,
    }: {
      onnEventWithRelation: OnnEventWithRelation;
      newGraduate: NewGraduateToDisplay;
      onnEventEvaluationRanksMap: Map<string, OnnEventEvaluationRankV2>;
      processRecordItem: RecruitmentProcessRecordOnnEventItem;
    }): RecruitmentProcessRecordOnnEventItemRowProps => {
      const answerStatus = calculateAnswerStatus({
        onnEvent: onnEventWithRelation.onnEvent,
        onnEventAnswer: onnEventWithRelation.answer,
        onnEventDeterminedDate: onnEventWithRelation.determinedDate,
        slotDate: onnEventWithRelation.slotDate,
      });

      return {
        type: "onnEvent",
        onnEvent: onnEventWithRelation.onnEvent,
        selectedOnnEventSlotDate: onnEventWithRelation.slotDate,
        determinedDate: onnEventWithRelation.determinedDate,
        ...calculateImplementationDate({
          onnEvent: onnEventWithRelation.onnEvent,
          onnEventDeterminedDate: onnEventWithRelation.determinedDate,
          slotDate: onnEventWithRelation.slotDate,
        }),
        evaluationCell: {
          onnEventId: onnEventWithRelation.onnEvent.id,
          evaluation: onnEventWithRelation.evaluation,
          onnEventEvaluationRankLabel: onnEventWithRelation.evaluation?.onnEventEvaluationRankId
            ? onnEventEvaluationRanksMap.get(
                onnEventWithRelation.evaluation.onnEventEvaluationRankId
              )?.label
            : undefined,
          newGraduateName: newGraduate.getName(),
          newGraduateId: newGraduate.id,
          isExistOnnEventDeterminingDate: !!onnEventWithRelation.determinedDate,
          isExistDraft: !!onnEventWithRelation.isExistEvaluationDraft,
        },
        relatedFilesCell: {
          filePaths: onnEventWithRelation.onnEventEvaluationFileValue?.filePaths || [],
        },
        onnEventStatusCellProps: generateOnnEventStatusCellProps({
          newGraduate,
          onnEventWithRelation,
        }),
        answerStatus,
        newGraduate: {
          name: newGraduate.getName(),
          id: newGraduate.id,
        },
        assigneeCell: {
          name: onnEventWithRelation.assignee?.getName() || "",
          iconUrl: onnEventWithRelation.assignee?.profileIconImageUrl || "",
          isShowNoneCell: !onnEventWithRelation.answer?.isAnswered(), // NOTE: 未回答の場合はNoneCellを表示する
        },
        rowUpdatedAt: calculateRowUpdatedAt({
          onnEventEvaluation: onnEventWithRelation.evaluation,
          onnEventAnswer: onnEventWithRelation.answer,
          onnEventDeterminedDate: onnEventWithRelation.determinedDate,
          latestDeliveryCancellationLog: onnEventWithRelation.latestDeliveryCancellationLog,
          processRecordItem,
        }),
        disableTitleLink: currentUser.isOnlyInterviewer(),
      };
    },
    [
      calculateAnswerStatus,
      calculateImplementationDate,
      calculateRowUpdatedAt,
      currentUser,
      generateOnnEventStatusCellProps,
    ]
  );

  return { convertToOnnEventItemRowProps };
};

const useGenerateOnnEventStatusCellProps = () => {
  const { calculateAnswerStatus } = useCalculateAnswerStatus();

  const generateOnnEventStatusCellProps = useCallback(
    ({
      newGraduate,
      onnEventWithRelation,
    }: {
      newGraduate: NewGraduateToDisplay;
      onnEventWithRelation: OnnEventWithRelation;
    }): OnnEventStatusCellProps => {
      const status = calculateAnswerStatus({
        onnEvent: onnEventWithRelation.onnEvent,
        onnEventAnswer: onnEventWithRelation.answer,
        onnEventDeterminedDate: onnEventWithRelation.determinedDate,
        slotDate: onnEventWithRelation.slotDate,
      });
      const { onnEvent, determinedDate } = onnEventWithRelation;
      const candidateId = determinedDate?.candidateDateId;

      return {
        employeeId: newGraduate.id,
        onnEvent,
        onnEventSlotDate: onnEventWithRelation.slotDate,
        ...(onnEventWithRelation.onnEvent.type === "normal"
          ? {
              ...(status === "answer_to_unable_to_participate_all_candidates"
                ? {
                    candidateId: undefined,
                    status,
                  }
                : {
                    ...(status === null
                      ? {
                          candidateId: undefined,
                          status,
                        }
                      : {
                          candidateId: candidateId as string, // NOTE: この分岐に入ってくる時点でcandidateIdは存在する
                          status,
                        }),
                  }),
              onnEventType: onnEventWithRelation.onnEvent.type,
            }
          : {
              onnEventType: onnEventWithRelation.onnEvent.type,
              candidateId: null,
              status,
            }),
      };
    },
    [calculateAnswerStatus]
  );

  return { generateOnnEventStatusCellProps };
};

const useCalculateAnswerStatus = () => {
  const { convertStatus } = useConvertFromDeterminedDateAttendanceStatus();

  const calculateAnswerStatus = useCallback(
    ({
      onnEvent,
      onnEventAnswer,
      onnEventDeterminedDate,
      slotDate,
    }: {
      onnEvent: OnnEvent;
      onnEventAnswer?: OnnEventAnswer;
      onnEventDeterminedDate?: OnnEventDeterminedDate;
      slotDate?: OnnEventSlotDate;
    }): AnswerStatus | null => {
      switch (onnEvent.type) {
        case "normal": {
          if (onnEventAnswer && onnEventAnswer.isUnavailableCandidates()) {
            return "answer_to_unable_to_participate_all_candidates";
          }

          if (!onnEventDeterminedDate) {
            return null;
          }

          const { candidateDateId } = onnEventDeterminedDate;
          const candidateDate = onnEvent.candidateDates.find((d) => d.id === candidateDateId);
          if (!candidateDate) {
            return null;
          }
          if (isPast(candidateDate.from)) {
            return convertStatus(onnEventDeterminedDate.attendanceStatus);
          } else {
            return "unregistered_attendance_and_not_past";
          }
        }
        case "briefing_session":
        case "new_interview": {
          if (!onnEventDeterminedDate) {
            return null;
          }

          if (!slotDate) {
            return null;
          }
          if (isPast(slotDate.from)) {
            return convertStatus(onnEventDeterminedDate.attendanceStatus);
          } else {
            return "unregistered_attendance_and_not_past";
          }
        }
        default: {
          const _exhaustiveCheck: never = onnEvent.type;
          return _exhaustiveCheck;
        }
      }
    },
    [convertStatus]
  );

  return { calculateAnswerStatus };
};

/**
 * 「日程列」に表示する日時を計算する
 */
const useCalculateImplementationDate = () => {
  const calculateImplementationDate = useCallback(
    ({
      onnEvent,
      onnEventDeterminedDate,
      slotDate,
    }: {
      onnEvent: OnnEvent;
      onnEventDeterminedDate?: OnnEventDeterminedDate;
      slotDate?: OnnEventSlotDate;
    }) => {
      if (!onnEventDeterminedDate) {
        return {
          fromDate: undefined,
          untilDate: undefined,
        };
      }

      switch (onnEvent.type) {
        case "normal": {
          const { candidateDateId } = onnEventDeterminedDate;
          const candidateDate = onnEvent.candidateDates.find((d) => d.id === candidateDateId);
          if (!candidateDate) {
            return null;
          }
          return {
            fromDate: candidateDate.from,
            untilDate: candidateDate.until,
          };
        }
        case "briefing_session":
        case "new_interview": {
          if (!slotDate) {
            return null;
          }
          return {
            fromDate: slotDate.from,
            untilDate: slotDate.until,
          };
        }
        default: {
          const _exhaustiveCheck: never = onnEvent.type;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );
  return { calculateImplementationDate };
};

/**
 * 「更新日時」列に表示する日時を算出する
 */
const useCalculateRowUpdatedAt = () => {
  const calculateRowUpdatedAt = useCallback(
    ({
      onnEventEvaluation,
      onnEventAnswer,
      onnEventDeterminedDate,
      latestDeliveryCancellationLog,
      processRecordItem,
    }: {
      onnEventEvaluation?: OnnEventEvaluationRankRelation;
      onnEventAnswer?: OnnEventAnswer;
      onnEventDeterminedDate?: OnnEventDeterminedDate;
      latestDeliveryCancellationLog?: OnnEventDeliveryCancellationLog;
      processRecordItem: RecruitmentProcessRecordOnnEventItem;
    }) => {
      // NOTE: 回答を削除された場合イベントの評価は残るため、回答が存在する時のみ評価の更新日時を表示する
      const onnEventEvaluationUpdatedAt =
        onnEventDeterminedDate && onnEventAnswer && onnEventEvaluation
          ? onnEventEvaluation?.updatedAt
          : undefined;

      const targets = compact([
        onnEventEvaluationUpdatedAt,
        onnEventAnswer?.updatedAt,
        onnEventDeterminedDate?.updatedAt,
        latestDeliveryCancellationLog?.createdAt,
        processRecordItem.createdAt,
      ]);

      return orderBy(targets, (d) => d.getTime(), "desc")[0];
    },
    []
  );

  return { calculateRowUpdatedAt };
};
