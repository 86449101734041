import { RecruitmentStatusWithRelatedInfo } from "@onn/common";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";
import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const generateKey = (tenantId: string, spaceId: string, withDeleted: boolean) => {
  return {
    name: `/get_recruitment_statuses_with_relations`,
    tenantId,
    spaceId,
    withDeleted: withDeleted ?? false,
  } as const;
};

// ポータルで使用するとクラッシュするため気をつける
export const useRecruitmentStatusesWithRelations = ({ withDeleted }: { withDeleted: boolean }) => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  return useSWR(
    generateKey(currentUser.tenantId, currentSpace.id, withDeleted),
    async ({ name, withDeleted }) => {
      const response = await apiClient.get(name, { withDeleted });
      return response.data.map((d) => new RecruitmentStatusWithRelatedInfo(d));
    },
    { revalidateOnFocus: true }
  );
};

export const useMutateRecruitmentStatusesWithRelations = ({
  withDeleted,
}: {
  withDeleted: boolean;
}) => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const mutateRecruitmentStatusesWithRelations = () => {
    mutate(generateKey(currentUser.tenantId, currentSpace.id, withDeleted));
  };

  return { mutateRecruitmentStatusesWithRelations };
};
