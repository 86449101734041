import { z } from "zod";

import { OnnEventEvaluationSingleSelectValueSchema } from "../../../../../_gen/zodSchema/index";

export const onnEventEvaluationSingleSelectValueSchema =
  OnnEventEvaluationSingleSelectValueSchema.merge(
    z.object({
      createdEmployeeId: z.string().optional(),
      updatedEmployeeId: z.string().optional(),
      onnEventEvaluationSingleSelectOptionId: z.string().nullable(),
    })
  );
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationSingleSelectValue
  extends z.infer<typeof onnEventEvaluationSingleSelectValueSchema> {}
