import { Box } from "@material-ui/core";
import React from "react";

import { Button } from "~/components/uiParts";

export const FieldSelectStepFooter = ({
  isLoading,
  isDisabled,
  onCancel,
  onSubmit,
}: {
  isLoading: boolean;
  isDisabled: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}): JSX.Element => {
  return (
    <Box display={"flex"} gridGap={"16px"}>
      <Box>
        <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
          キャンセル
        </Button>
      </Box>
      <Box>
        <Button
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          出力設定へ
        </Button>
      </Box>
    </Box>
  );
};
