import { Box, Stack } from "@mui/material";

import {
  RecruitmentProcessConditionFieldKey,
  RecruitmentStatusConditionFieldKey,
} from "@onn/common";
import React, { FC } from "react";

import { MenuItem, SelectedFieldKey } from "../../useFieldSelection";

import { SelectConditionMenuItem } from "./SelectConditionMenuItem";
import { SelectConditionMenuItemWithDetailItems } from "./SelectConditionMenuItemWithDetailItems";

import { Checkbox, IconButton, Typography } from "~/components/uiParts";

type Props = {
  title: string;
  selectAll: () => void;
  unselectAll: () => void;
  selectAllDetailItems: (
    detailItemKeys: Array<RecruitmentStatusConditionFieldKey | RecruitmentProcessConditionFieldKey>
  ) => void;
  unselectAllDetailItems: (
    detailItemKeys: Array<RecruitmentStatusConditionFieldKey | RecruitmentProcessConditionFieldKey>
  ) => void;
  toggleIsSelected: (key: SelectedFieldKey) => void;
  selectedFieldsMap: Map<SelectedFieldKey, boolean>;
  menuItems: MenuItem[];
};

export const SelectConditionMenuItemGroup: FC<Props> = ({
  title,
  selectAll,
  unselectAll,
  selectAllDetailItems,
  unselectAllDetailItems,
  toggleIsSelected,
  menuItems,
  selectedFieldsMap,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const selectableKeys = menuItems.flatMap((menuItem) =>
    "detailItems" in menuItem ? menuItem.detailItems.map((v) => v.key) : menuItem.key
  );
  const selectedCount = selectableKeys.filter((key) => selectedFieldsMap.get(key)).length;

  const isSelectedAll = selectedCount === selectableKeys.length;
  const isDisplaySelectedCount = selectedCount > 0;

  return (
    <Stack borderRadius="8px" sx={{ border: ({ palette }) => `1px solid ${palette.grey[100]}` }}>
      <Box
        display="flex"
        columnGap="8px"
        p="16px"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={isSelectedAll ? unselectAll : selectAll}
      >
        <Checkbox
          indeterminate={selectedCount > 0 && !isSelectedAll}
          checked={isSelectedAll}
          style={{ padding: 0 }}
        />
        <Typography bold variant="body2" style={{ flex: 1 }}>
          {`${title}${isDisplaySelectedCount ? ` (${selectedCount})` : ""}`}
        </Typography>
        <IconButton
          size="md"
          icon={isExpanded ? "chevronUp" : "chevronDown"}
          color="grey"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded((prev) => !prev);
          }}
        />
      </Box>
      {isExpanded && (
        <Stack>
          {menuItems.map((menuItem) => {
            if ("detailItems" in menuItem) {
              return (
                <SelectConditionMenuItemWithDetailItems
                  key={menuItem.key}
                  selectAllDetailItems={selectAllDetailItems}
                  unselectAllDetailItems={unselectAllDetailItems}
                  toggleIsSelectedDetailsItem={toggleIsSelected}
                  menuItem={{
                    ...menuItem,
                    detailItems: menuItem.detailItems.map((detailItem) => ({
                      ...detailItem,
                      isSelected: !!selectedFieldsMap.get(detailItem.key),
                    })),
                  }}
                />
              );
            }

            return (
              <SelectConditionMenuItem
                key={menuItem.key}
                toggleIsSelected={toggleIsSelected}
                menuItem={{
                  ...menuItem,
                  indeterminate: false,
                  isSelected: !!selectedFieldsMap.get(menuItem.key),
                }}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
