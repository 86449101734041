import { BusinessHoursSetting } from "@onn/common";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  businessHoursSetting?: BusinessHoursSetting;
};

export const BusinessHoursSettingsViewer: FC<Props> = ({ businessHoursSetting }) => {
  if (!businessHoursSetting) return <Typography variant="body2">設定なし</Typography>;

  return <Typography variant="body2">{businessHoursSetting.getBusinessHoursText()}</Typography>;
};
