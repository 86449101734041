import { Stack } from "@mui/material";
import { AnyOnnEventEvaluationField } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

export const FieldLabelArea: FC<{
  onnEventEvaluationField: AnyOnnEventEvaluationField;
}> = ({ onnEventEvaluationField }) => {
  const { currentUser } = useCurrentUser();

  return (
    <Stack spacing={"8px"} direction={"row"} justifyContent={"space-between"}>
      <StyledTypographyForLabel variant="body2" color="textPrimary" bold component={"div"}>
        {onnEventEvaluationField.label}
      </StyledTypographyForLabel>
      {onnEventEvaluationField.accessControlType === "ADMIN_ONLY" && (
        <Typography variant="body2" color="textSecondary" component={"div"}>
          {"この項目は管理者のみ表示されます"}
        </Typography>
      )}
      {!onnEventEvaluationField.isEditable(currentUser) && (
        <Typography variant="body2" color="textSecondary" component={"div"}>
          {"編集不可"}
        </Typography>
      )}
    </Stack>
  );
};

const StyledTypographyForLabel = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
  }
`;
