import { z } from "zod";

import {
  DeprecatedNewGraduate,
  Employee,
  NewGraduateToDisplay,
  Role,
} from "../../../../domain/Employee";

import { RegistrationInvitationLink } from "../../../../domain/RegistrationInvitationLink";
import { uploadCsvRequestType } from "../_shared";

import { getNewGraduatesCSVBody } from "./getNewGraduatesCSVSchema";

export * from "./getNewGraduatesCSVSchema";

export interface APISchemaEmployees {
  "/api/employee/acceptance-employees": {
    GET: {
      response: {
        data: ExcludeMethods<Employee>[];
      };
    };
  };
  "/api/employee/new-graduates/bulk-update-information-with-csv": {
    POST: {
      body: z.infer<typeof bulkUpdateNewGraduatesInformationWithCsvSchema>["body"];
      response:
        | {
            isOk: true;
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };
  "/api/employee/link-line-user-and-employee": {
    POST: {
      body: {
        liffAccessToken: string;
        invitationToken: string;
      };
      response: {
        data: undefined;
      };
    };
  };
  "/api/employee/update-recruitment-status": {
    PATCH: {
      body: z.infer<typeof updateRecruitmentStatusBodySchema>["body"];
      response: {
        data: {
          newGraduate: Employee;
          updatedOfferAcceptanceDeadline?: Date;
        };
      };
    };
  };
  "/api/employee/get-registration-info": {
    POST: {
      body: z.infer<typeof getRegistrationInfoRequestSchema>["body"];
      response: {
        data: {
          // TODO: ExcludeMethodsを噛ませたいが、get_registration_infoのレスポンス型を直接使っている箇所があるので、そこを一緒に修正する
          employee: Employee;
          tenantName: string;
          enTenantName?: string;
          tenantLogoUrl: string | null;
          emailAuthenticationStatus?: {
            isAuthenticated: boolean;
            types: ("email" | "google")[];
          };
          lineAuthenticationStatus?: {
            isAuthenticated: boolean;
          };
        };
      };
    };
  };
  "/api/employee/send-invitation-notifications": {
    POST: {
      body: z.infer<typeof sendInvitationNotificationsBodySchema>["body"];
    };
  };
  "/get_employee": {
    GET: {
      query: z.infer<typeof getEmployeeRequestSchema>["query"];
      response: {
        data: Employee | null;
      };
    };
  };
  "/api/employee/get-new-graduate": {
    GET: {
      query: z.infer<typeof getNewGraduateRequestSchema>["query"];
      response: {
        data: ExcludeMethods<NewGraduateToDisplay> | null;
      };
    };
  };
  "/get_employees": {
    POST: {
      body: z.infer<typeof getEmployeesRequestSchema>["body"];
      response: {
        data: Employee[];
      };
    };
  };
  "/api/employee/get-new-graduates": {
    POST: {
      body: z.infer<typeof getNewGraduatesRequestSchema>["body"];
      response: {
        data: ExcludeMethods<NewGraduateToDisplay>[];
      };
    };
  };
  "/api/employee/get-employees-by-uid": {
    GET: {
      query: z.infer<typeof getEmployeesByUidRequestSchema>["query"];
      response: {
        data: ExcludeMethods<Employee>[];
      };
    };
  };
  "/api/employee/retrieve-registration-info-by-liff": {
    POST: {
      body: {
        liffAccessToken: string;
        liffId: string;
      };
      response: {
        data: { tenantName: string; userAccount: Employee };
      };
    };
  };
  "/api/employee/signup": {
    POST: {
      body: z.infer<typeof signupSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  "/api/employee/signup-by-line": {
    POST: {
      body: z.infer<typeof signupByLineSchema>["body"];
      response: {
        data: { userAccount: Employee; customToken: string };
      };
    };
  };
  "/api/employee/signup-by-registration-invitation-link": {
    POST: {
      body: z.infer<typeof signupByRegistrationInvitationLinkSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink;
          newGraduate: Employee;
        };
      };
    };
  };
  "/api/employee/sign-up-by-line-registration-invitation-link": {
    POST: {
      body: z.infer<typeof signupByLineRegistrationInvitationLinkSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink;
          newGraduate: Employee;
          customToken: string;
        };
      };
    };
  };
  "/api/employee/get-line-access-token-by-authorize-code": {
    POST: {
      body: z.infer<typeof getLineAccessTokenByAuthorizeCodeSchema>["body"];
      response: {
        data: { accessToken: string };
      };
    };
  };
  "/api/employee/issue-custom-token-for-line": {
    POST: {
      body: z.infer<typeof issueCustomTokenForLineSchema>["body"];
      response: {
        data: { customToken: string };
      };
    };
  };
  // NOTE: このエンドポイントはLINE認証専用のエンドポイントです。現時点で複数テナントに1つの認証でログインすることができるのはLINE認証のみです。
  "/api/employee/authorize-for-individual": {
    POST: {
      body: z.infer<typeof authorizeForIndividualSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  // NOTE: このエンドポイントはLINE認証専用のエンドポイントです。現時点で複数テナントに1つの認証でログインすることができるのはLINE認証のみです。
  "/api/employee/authorize-for-registration-invitation-link": {
    POST: {
      body: z.infer<typeof authorizeForRegistrationInvitationLinkSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  "/api/employee/update-current-employee": {
    PATCH: {
      body: z.infer<typeof updateCurrentEmployeeRequestSchema>["body"];
      response: {
        data: {
          employee: ExcludeMethods<Employee>;
        };
      };
    };
  };
  "/api/employee/update-unregistered-employee-invited-at": {
    PATCH: {
      body: z.infer<typeof updateInvitedAtUnregisteredEmployeeRequestSchema>["body"];
      response: {
        data: {
          employee: ExcludeMethods<Employee>;
        };
      };
    };
  };
  "/api/employee/update-last-refresh-time": {
    PATCH: {
      body: z.infer<typeof updateLastRefreshTimeRequestSchema>["body"];
      response: {
        data: undefined;
      };
    };
  };
  "/api/employee/update-employee": {
    PATCH: {
      body: { id: string; updates: Partial<ExcludeMethods<Employee>> };
      response: {
        data: undefined;
      };
    };
  };
  "/api/employee/get-new-graduates-csv": {
    POST: {
      body: z.infer<typeof getNewGraduatesCSVBody>;
      // response: CSVファイル
    };
  };
  "/api/employee/create-new-graduates-with-csv": {
    POST: {
      body: z.infer<typeof createNewGraduatesWithCsvSchema>["body"];
      response:
        | {
            isOk: true;
            createdNewGraduates: DeprecatedNewGraduate[];
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };

  "/api/employee/change-authentication-by-link": {
    POST: {
      body: z.infer<typeof changeAuthenticationByChangeAuthenticationTypeLinkSchema>["body"];
      response: {
        data:
          | {
              isSuccess: true;
              customToken: string;
              isFollowedLineOfficialAccount: boolean;
            }
          | {
              isSuccess: false;
              type:
                | "NotFoundChangeAuthenticationTypeLink"
                | "ChangeAuthenticationTypeLinkWasUsed"
                | "ChangeAuthenticationTypeLinkIsExpired"
                | "NotFoundLineUser"
                | "AlreadyExistAuthInSameTenant";
            };
      };
    };
  };
  "/api/employee/list-tagged-employees": {
    GET: {
      query: z.infer<typeof listTaggedEmployeesQuerySchema>["query"];
      response: {
        data: {
          employees: ExcludeMethods<Employee>[];
        };
        meta: {
          employeeIdToTaggedAtMap: {
            [employeeId: string]: string;
          };
        };
      };
    };
  };
  "/api/employee/assignee-and-followers": {
    PATCH: {
      body: z.infer<typeof patchAssigneeAndFollowersSchema>["body"];
    };
  };
  "/api/employee/id/employee-prediction-relation": {
    POST: {
      body: z.infer<typeof postEmployeePredictionRelationSchema>["body"];
    };
  };
}

export const updateRecruitmentStatusBodySchema = z.object({
  body: z.object({
    employeeId: z.string(),
    recruitmentStatusId: z.string(),
    offerAcceptanceDeadline: z.date().optional(),
  }),
});

export const getRegistrationInfoRequestSchema = z.object({
  body: z.object({
    "invitation-token": z.string(),
    "line-access-token": z.string().optional(),
  }),
});

export const sendInvitationNotificationsBodySchema = z.object({
  body: z.object({
    ["employee-ids"]: z.array(z.string()),
  }),
});

export const getEmployeeRequestSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});
export const getNewGraduateRequestSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});
export const getEmployeesRequestSchema = z.object({
  body: z.object({
    ids: z.array(z.string()).optional(),
    includeDeleted: z.boolean(),
  }),
});

export const getEmployeesByUidRequestSchema = z.object({
  query: z.object({
    includeDeleted: z.boolean().optional(),
  }),
});

export const getNewGraduatesRequestSchema = z.object({
  body: z.object({
    ids: z.array(z.string()).optional(),
    includeDeleted: z.boolean(),
  }),
});

export const authorizeForIndividualSchema = z.object({
  body: z.object({
    invitationToken: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    lineAccessToken: z.string(),
  }),
});

export const authorizeForRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    lineAccessToken: z.string().optional(),
    registrationInvitationLinkId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
  }),
});

export const getLineAccessTokenByAuthorizeCodeSchema = z.object({
  body: z.object({
    authorizeCode: z.string(),
    tenantId: z.string().optional(),
  }),
});

export const issueCustomTokenForLineSchema = z.object({
  body: z.object({
    accessToken: z.string(),
  }),
});

export const signupSchema = z.object({
  body: z.object({
    firstName: z.string(),
    lastName: z.string(),
    password: z.string(),
    invitationToken: z.string(),
  }),
});

export const signupByLineSchema = z.object({
  body: z.object({
    firstName: z.string(),
    lastName: z.string(),
    lineAccessToken: z.string(),
    invitationToken: z.string(),
  }),
});

export const signupByRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    password: z.string(),
    liffAccessToken: z.string().optional(),
  }),
});

export const signupByLineRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    lineAccessToken: z.string(),
  }),
});

export const updateRoleRequestSchema = z.object({
  body: z.object({
    employeeId: z.string(),
    role: z.nativeEnum(Role),
    assignedAsNewcomer: z.boolean().optional(),
  }),
});

export const updateCurrentEmployeeRequestSchema = z.object({
  body: z.object({
    lastActiveTime: z.date().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    profileIconImageUrl: z.string().optional(),
  }),
});

export const updateInvitedAtUnregisteredEmployeeRequestSchema = z.object({
  body: z.object({
    invitationToken: z.string(),
    lineAccessToken: z.string().optional(),
  }),
});

export const updateLastRefreshTimeRequestSchema = z.object({
  body: z.object({
    lastRefreshTime: z.date(),
  }),
});
export const createNewGraduatesWithCsvSchema = z.object({
  body: uploadCsvRequestType,
});

export const bulkUpdateNewGraduatesInformationWithCsvSchema = z.object({
  body: uploadCsvRequestType,
});

export const changeAuthenticationByChangeAuthenticationTypeLinkSchema = z.object({
  body: z.object({
    changeAuthenticationTypeLinkId: z.string(),
    employeeId: z.string(),
    lineAccessToken: z.string(),
  }),
});
export const listTaggedEmployeesQuerySchema = z.object({
  query: z.object({
    employeeTagId: z.string(),
  }),
});

export const patchAssigneeAndFollowersSchema = z.object({
  body: z.object({
    newGraduateId: z.string(),
    assigneeId: z.string().optional(),
    followerIds: z.array(z.string()),
  }),
});

export const postEmployeePredictionRelationSchema = z.object({
  body: z.object({
    employeeId: z.string(),
    employeePredictionId: z.string().nullable(),
    scenarioId: z.string(),
  }),
});
