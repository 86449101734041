import { isSPView } from "./isSPView";

const activePathNames = ["/", "/employee/", "/tasks", "/contact_rooms", "/tools"];

// NOTE: "/employee/" を含めた方がより良いが、イベント評価 Drawer 表示時には非表示にしたくその機構が今ないため、一旦除外
const activePathPrefixNames = [];

function chanelTalk(currentUserName, tenantName, currentPathname) {
  try {
    // SPではチャネルトークを表示しない
    if (isSPView()) {
      window.ChannelIO("shutdown");
      return;
    }

    const isActivePath =
      activePathNames.some((path) => currentPathname === path) ||
      activePathPrefixNames.some((path) => currentPathname.startsWith(path));

    // 該当のページでなければチャネルトークを閉じる
    if (!isActivePath) {
      window.ChannelIO("shutdown");
      return;
    }

    (function () {
      const w = window;
      if (w.ChannelIO) {
        return w.console.error("ChannelIO script included twice.");
      }
      const ch = function () {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function (args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        const x = document.getElementsByTagName("script")[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }
      if (document.readyState === "complete") {
        l();
      } else {
        w.addEventListener("DOMContentLoaded", l);
        w.addEventListener("load", l);
      }
    })();
    // eslint-disable-next-line no-undef
    ChannelIO("boot", {
      pluginKey: "6b60ae0d-6e08-44fd-9688-85bbbeefce78",
      profile: {
        name: `${currentUserName} (${tenantName})`,
      },
    });
  } catch (error) {
    void 0;
  }
}

export { chanelTalk };
