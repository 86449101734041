import {
  Announcement,
  AnnouncementDistribution,
  Employee,
  NewGraduateToDisplay,
} from "@onn/common";
import { isPast, isValid } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";

import { useUpdateAnnouncementSettings } from "~/hooks/announcement";

import { useModal } from "~/hooks/modal";

import { usePrompt } from "~/hooks/shared/usePrompt";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { captureException } from "~/util";

export const usePublicationStartDateInput = ({
  publicationEndDate,
  publicationStartDate,
}: {
  publicationEndDate: Date | null;
  publicationStartDate: Date | null;
}) => {
  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && publicationEndDate) {
        return "配信タイミングを設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (isPast(date)) {
        return "過去の日付は入力できません";
      }

      if (publicationEndDate && date.getTime() >= publicationEndDate.getTime()) {
        return "公開終了日よりも前の日付を入力してください";
      }

      return "";
    },
    [publicationEndDate]
  );

  const isError = useCallback(
    (date: Date | null) => {
      return !!getHelperText(date);
    },
    [getHelperText]
  );

  return {
    helperText: getHelperText(publicationStartDate),
    error: isError(publicationStartDate),
  };
};

export const usePublicationEndDateInput = ({
  publicationEndDate,
  publicationStartDate,
}: {
  publicationEndDate: Date | null;
  publicationStartDate: Date | null;
}) => {
  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && publicationStartDate) {
        return "配信タイミングを設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (isPast(date)) {
        return "過去の日付は入力できません";
      }

      if (publicationStartDate && date.getTime() <= publicationStartDate.getTime()) {
        return "公開開始日よりも後の日付を入力してください";
      }

      return "";
    },
    [publicationStartDate]
  );

  const isError = useCallback(
    (date: Date | null) => {
      return !!getHelperText(date);
    },
    [getHelperText]
  );

  return {
    helperText: getHelperText(publicationEndDate),
    error: isError(publicationEndDate),
  };
};

export const useHooks = ({
  announcement,
  announcementDistributions,
  mutateAnnouncement,
  mutateAnnouncementDistributions,
}: {
  announcement: Announcement;
  announcementDistributions: AnnouncementDistribution[];
  mutateAnnouncement: (announcement: Announcement) => Promise<Announcement | null | undefined>;
  mutateAnnouncementDistributions: (
    announcementDistributions: AnnouncementDistribution[]
  ) => Promise<AnnouncementDistribution[] | undefined>;
}) => {
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from_page");
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const isAlreadyPublished = announcement.isAlreadyPublished();

  const [publicationStartDate, setPublicationStartDate] = useState<Date | null>(
    announcement.publicationStartDate ?? null
  );
  const [publicationEndDate, setPublicationEndDate] = useState<Date | null>(
    announcement.publicationEndDate ?? null
  );
  const [isDirtyPublicationDate, setIsDirtyPublicationDate] = useState(false);

  const updatePublicationStartDate = useCallback((date: Date | null) => {
    setIsDirtyPublicationDate(true);

    setPublicationStartDate(date);
  }, []);
  const updatePublicationEndDate = useCallback((date: Date | null) => {
    setIsDirtyPublicationDate(true);

    setPublicationEndDate(date);
  }, []);

  usePrompt("変更内容を破棄しますか？", isChanged);

  const { execUpdateAnnouncementSettings } = useUpdateAnnouncementSettings();

  const [selectedNewGraduates, setSelectedNewGraduates] = useState<Employee[]>([]);
  const publicationStartDateInput = usePublicationStartDateInput({
    publicationStartDate,
    publicationEndDate,
  });
  const publicationEndDateInput = usePublicationEndDateInput({
    publicationStartDate,
    publicationEndDate,
  });

  // 配信対象追加モーダルで追加された候補者を削除する
  const onClickDeleteTargetNewGraduate = useCallback(
    (target: Employee) => {
      const newSelectedNewGraduates = selectedNewGraduates.filter((v) => v.id !== target.id);
      setSelectedNewGraduates(newSelectedNewGraduates);
      if (newSelectedNewGraduates.length === 0) setIsChanged(false);
    },
    [selectedNewGraduates]
  );

  const onClickUploadCSVButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: selectedNewGraduates,
        registeredEmployeeIds: announcementDistributions.map((v) => v.employeeId),
        // TODO[登録済み削除機能実装時に対応]: registeredEmployeeIds は、登録済みリストに保持されているものに変更する
        onSubmit(newTargets) {
          setSelectedNewGraduates((prev) => {
            const prevIds = prev.map((v) => v.id);
            const newSelectedNewGraduates = [
              ...prev,
              ...newTargets.filter((v) => !prevIds.includes(v.id)),
            ];
            return newSelectedNewGraduates;
          });
        },
      },
    });
  }, [handleModal, selectedNewGraduates, announcementDistributions]);

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToAnnouncementButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        filter: (employee: NewGraduateToDisplay) => {
          if (!employee.canUseNotificationFeatures()) return false;

          const isAlreadySelected = selectedNewGraduates.map((e) => e.id).includes(employee.id);
          const isAlreadyDelivered = announcementDistributions.some(
            (v) => v.employeeId === employee.id
          );

          return !isAlreadySelected && !isAlreadyDelivered;
        },
        onSubmit: async (modalSelectedNewGraduates: Employee[]) => {
          setSelectedNewGraduates([...modalSelectedNewGraduates, ...selectedNewGraduates]);
          if (!isChanged) setIsChanged(true);
        },
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [
    announcementDistributions,
    handleModal,
    isChanged,
    onClickUploadCSVButton,
    selectedNewGraduates,
  ]);

  const enqueueSuccessSnackbar = useCallback(
    (newAnnouncement: Announcement) => {
      const isCreate = fromPage === "create";
      const isNotUnderPublication = !newAnnouncement.isUnderPublication();
      const isAdded = !isEmpty(selectedNewGraduates);

      let text;
      if (isCreate) {
        text = "配信予約が設定されました";
      } else if (isNotUnderPublication) {
        text = "配信内容が更新されました";
      } else if (isAdded) {
        text = `${selectedNewGraduates.length}名にお知らせを配信しました。対象者には通知が送信されます。`;
      } else {
        text = "配信お知らせの内容が更新されました";
      }

      enqueueSnackbar(text, {
        variant: "success",
      });
    },
    [enqueueSnackbar, fromPage, selectedNewGraduates]
  );

  const _submit = useCallback(
    async (isForce?: boolean) => {
      const isChangedPublicationStartDate =
        publicationStartDate?.getTime() !== announcement.publicationStartDate?.getTime();
      const isChangedPublicationEndDate =
        publicationEndDate?.getTime() !== announcement.publicationEndDate?.getTime();
      if (isChangedPublicationStartDate && publicationStartDateInput.error) {
        enqueueSnackbar("公開開始日に不適切な値が入力されている、または未入力です。", {
          variant: "error",
        });

        return;
      }
      if (isChangedPublicationEndDate && publicationEndDateInput.error) {
        enqueueSnackbar("公開終了日に不適切な値が入力されている、または未入力です。", {
          variant: "error",
        });

        return;
      }

      try {
        setIsChanged(false);
        const { announcement: newAnnouncement, createdAnnouncementDistributions } =
          await execUpdateAnnouncementSettings({
            announcementId: announcement.id,
            targetNewGraduateIds: selectedNewGraduates.map((v) => v.id),
            publicationStartDate,
            publicationEndDate,
            forceNotifyImmediately: isForce,
          });
        enqueueSuccessSnackbar(newAnnouncement);
        setSelectedNewGraduates([]);
        await mutateAnnouncement(newAnnouncement);
        await mutateAnnouncementDistributions([
          ...(announcementDistributions || []),
          ...(createdAnnouncementDistributions ?? []),
        ]);
        navigate(`/announcements/${announcement.id}`);
      } catch (e) {
        setSelectedNewGraduates([]);
        enqueueSnackbar("配信対象の追加に失敗しました。管理者より連絡がくるまで、お待ちください", {
          variant: "error",
        });
        navigate(`/announcements/${announcement.id}`);
        captureException({
          error: e as Error,
          tags: { type: "useHooks:onClickConfirmSave" },
        });
      }
    },
    [
      publicationStartDate,
      announcement.publicationStartDate,
      announcement.publicationEndDate,
      announcement.id,
      publicationEndDate,
      publicationStartDateInput.error,
      publicationEndDateInput.error,
      enqueueSnackbar,
      execUpdateAnnouncementSettings,
      selectedNewGraduates,
      enqueueSuccessSnackbar,
      mutateAnnouncement,
      mutateAnnouncementDistributions,
      announcementDistributions,
      navigate,
    ]
  );

  const submit = useCallback(async () => {
    if (!isAlreadyPublished) {
      await _submit();
    } else {
      withBusinessHours(_submit);
    }
  }, [_submit, isAlreadyPublished, withBusinessHours]);

  const onClickConfirmSave = useCallback(() => {
    setIsSubmitting(true);
    submit().finally(() => {
      setIsSubmitting(false);
    });
  }, [submit]);

  const onClickGoBack = useCallback(() => {
    navigate(`/announcements/${announcement.id}/edit?from_page=delivery_setting`);
  }, [announcement.id, navigate]);

  const onClickSetLater = useCallback(() => {
    navigate(`/announcements/${announcement.id}`);
  }, [announcement.id, navigate]);

  const onClickCancel = useCallback(() => {
    if (fromPage === "detail") {
      navigate(`/announcements/${announcement.id}?from_page=delivery_setting`);
      return;
    }
    navigate("/tools#announcements");
  }, [fromPage, navigate, announcement.id]);

  // いずれかの値が正しく変更されていた場合は保存ボタンを有効化する
  const isChangedPublicationStartDate =
    publicationStartDate?.getTime() !== announcement.publicationStartDate?.getTime();
  const isChangedPublicationEndDate =
    publicationEndDate?.getTime() !== announcement.publicationEndDate?.getTime();
  const isDeliverySettingsChanged = isChangedPublicationStartDate || isChangedPublicationEndDate;
  const isErrorPublicationStartDate =
    isChangedPublicationStartDate && publicationStartDateInput.error;
  const isErrorPublicationEndDate = isChangedPublicationEndDate && publicationEndDateInput.error;
  const isDeliverySettingsError = isErrorPublicationStartDate || isErrorPublicationEndDate;
  const isChangedSelectedNewGraduates = selectedNewGraduates.length > 0;

  const isCorrectlyChangedDeliverySettings = isDeliverySettingsChanged && !isDeliverySettingsError;
  const isCorrectlyChangedSelectedNewGraduates = isChangedSelectedNewGraduates;

  const isDisabledSaveButton =
    isSubmitting ||
    (!isCorrectlyChangedDeliverySettings && !isCorrectlyChangedSelectedNewGraduates);

  return {
    onClickAddNewGraduateToAnnouncementButton,
    onClickDeleteTargetNewGraduate,
    onClickGoBack,
    onClickSetLater,
    onClickCancel,
    onClickConfirmSave,
    onClickUploadCSVButton,
    updatePublicationEndDate,
    updatePublicationStartDate,
    publicationStartDateInput,
    publicationEndDateInput,
    publicationEndDate,
    publicationStartDate,
    isDirtyPublicationDate,
    selectedNewGraduates,
    isDisabledSaveButton,
    isSubmitting,
    announcementDistributions,
    searchParams,
    fromPage,
    isAlreadyPublished,
  };
};
