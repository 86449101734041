import {
  instantiateFromAnyOnnEventEvaluationFieldExcludeMethods,
  OnnEventEvaluationRankV2,
} from "@onn/common";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string; onnEventId: string }) => {
  return {
    endpoint: "/api/onn-event-evaluations/settings",
    ...arg,
  } as const;
};
export const useOnnEventEvaluationSetting = ({ onnEventId }: { onnEventId: string }) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
    }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint, { onnEventId });
      return {
        onnEventEvaluationRanks: response.data.onnEventEvaluationRanks.map(
          (d) => new OnnEventEvaluationRankV2(d)
        ),
        onnEventEvaluationFields: response.data.onnEventEvaluationFields.map((d) =>
          instantiateFromAnyOnnEventEvaluationFieldExcludeMethods(d)
        ),
      };
    }
  );
};

export const useMutateOnnEventEvaluationSetting = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventEvaluationSetting = async ({ onnEventId }: { onnEventId: string }) => {
    await mutate(
      generateKey({
        tenantId: currentUser.tenantId,
        onnEventId,
      })
    );
  };

  return { mutateOnnEventEvaluationSetting };
};
