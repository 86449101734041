import { FC, useState } from "react";

import { useCurrentUser } from "~/hooks/employee";

import { useClipboard } from "~/hooks/shared";

type Props = {
  renderViewer: ({
    onChangeModeToEdit,
    onClickCopy,
  }: {
    onChangeModeToEdit: () => void;
    onClickCopy: (value: string, label: string) => void;
  }) => JSX.Element;
  renderEditor: ({
    onChangeModeToView,
    onCancel,
  }: {
    onChangeModeToView: () => void;
    onCancel: () => void;
  }) => JSX.Element;
};

export const ViewerAndEditorManager: FC<Props> = ({ renderViewer, renderEditor }) => {
  const [mode, setMode] = useState<"view" | "edit">("view");
  const { handleCopy } = useClipboard();
  const { currentUser } = useCurrentUser();

  return mode === "view"
    ? renderViewer({
        onChangeModeToEdit: () => {
          if (currentUser.isRegularAcceptanceEmployee()) {
            setMode("edit");
          }
        },
        onClickCopy: (value: string, label: string) => handleCopy(value, label),
      })
    : renderEditor({
        onChangeModeToView: () => setMode("view"),
        onCancel: () => setMode("view"),
      });
};
