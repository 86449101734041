import { isEmpty } from "lodash";
import React, { useState } from "react";

import { FieldSelectStepContent, FieldSelectStepFooter } from "./FieldSelectStep";
import { SettingStepContent, SettingStepFooter } from "./SettingStep";
import { Step } from "./types";
import { useFieldSelection } from "./useFieldSelection";

import { useScenarioSelection } from "./useScenarioSelection";

import { Loading, ScrollableBodyModal, Typography } from "~/components/uiParts";
import { useDownloadNewGraduatesCSV } from "~/hooks/employee/useDownloadNewGraduatesCSV";
import {
  EmployeeInformationGroupData,
  useEmployeeInformationGroups,
} from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";
type Props = {
  open: boolean;
  onCancel(): void;
  employeeIds: string[];
};

export const DownloadEmployeesWithCSVActionModal = (props: Props): JSX.Element => {
  const { data: employeeInformationGroupData, isLoading } = useEmployeeInformationGroups();

  if (isLoading) {
    return (
      <ScrollableBodyModal
        {...props}
        fullHeight
        title={"CSVダウンロード｜候補者データ"}
        content={<Loading size="large" />}
        fullWidth
        disableBackdropModal
      />
    );
  }

  if (!employeeInformationGroupData) {
    return (
      <ScrollableBodyModal
        {...props}
        fullHeight
        title={"CSVダウンロード｜候補者データ"}
        content={
          <Typography variant="body1" color="error" align="center">
            エラーが発生しました
          </Typography>
        }
        fullWidth
        disableBackdropModal
      />
    );
  }

  return (
    <DownloadEmployeesWithCSVActionModalCore
      {...props}
      employeeInformationGroupData={employeeInformationGroupData}
    />
  );
};

export const DownloadEmployeesWithCSVActionModalCore = ({
  open,
  onCancel,
  employeeIds,
  employeeInformationGroupData,
}: Props & { employeeInformationGroupData: EmployeeInformationGroupData }): JSX.Element => {
  const fieldSelectionManager = useFieldSelection(employeeInformationGroupData);
  const scenarioSelectionManger = useScenarioSelection();
  const { downloadNewGraduatesCSV, isLoading } = useDownloadNewGraduatesCSV();
  const [step, setStep] = useState<Step>("FieldSelectStep");

  const stepComponents: Record<
    Step,
    {
      content: React.JSX.Element;
      footer: React.JSX.Element;
    }
  > = {
    FieldSelectStep: {
      content: <FieldSelectStepContent {...fieldSelectionManager} />,
      footer: (
        <FieldSelectStepFooter
          isLoading={isLoading}
          isDisabled={isEmpty(fieldSelectionManager.selectedFieldsMap)}
          onCancel={onCancel}
          onSubmit={() => setStep("SettingStep")}
        />
      ),
    },
    SettingStep: {
      content: <SettingStepContent {...scenarioSelectionManger} />,
      footer: (
        <SettingStepFooter
          isLoading={isLoading}
          isDisabled={false}
          onBack={() => setStep("FieldSelectStep")}
          onCancel={onCancel}
          onSubmit={() => {
            downloadNewGraduatesCSV({
              employeeIds,
              conditionFields: fieldSelectionManager.convertedFieldToRequest,
              scenarioIdSelections: scenarioSelectionManger.selectedScenarioIds,
            });
          }}
        />
      ),
    },
  };

  return (
    <ScrollableBodyModal
      open={open}
      fullHeight
      title={"CSVダウンロード｜候補者データ"}
      footer={stepComponents[step].footer}
      content={stepComponents[step].content}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};
