import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon, IconButton, Tooltip, Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  label?: JSX.Element;
  onChangeModeToEdit?: () => void;
  onClickCopy?: () => void;
};

export const InformationValueViewer: FC<Props> = ({ label, onChangeModeToEdit, onClickCopy }) => {
  const { currentUser } = useCurrentUser();
  if (!label) {
    return (
      <StyledNoLabelBox
        onClick={onChangeModeToEdit}
        $isEditable={currentUser.isRegularAcceptanceEmployee()}
      >
        <Typography
          variant="body2"
          style={{ color: theme.palette.grey[200] }}
          className="hover-hidden"
        >
          未登録
        </Typography>
        {currentUser.isRegularAcceptanceEmployee() && (
          <StyledButtonWrapper
            display="flex"
            alignItems="center"
            gridColumnGap="4px"
            className="hover-display"
          >
            <Button borderRadius="regular" variant="text" color="primary">
              <Icon icon="pencil" color="primary" size="sm" />
              <Typography variant="body2" color="primary" bold>
                情報を登録
              </Typography>
            </Button>
          </StyledButtonWrapper>
        )}
      </StyledNoLabelBox>
    );
  }

  return (
    <StyledBox display="flex" alignItems="center">
      {label}
      <Box display="flex" ml="auto" className="button-wrapper">
        {onChangeModeToEdit && currentUser.isRegularAcceptanceEmployee() && (
          <Tooltip title="編集" arrow>
            <IconButton icon="pencil" size="md" onClick={onChangeModeToEdit} />
          </Tooltip>
        )}
        {onClickCopy && (
          <Tooltip title="コピー" arrow>
            <IconButton icon="copy" size="md" onClick={onClickCopy} />
          </Tooltip>
        )}
      </Box>
    </StyledBox>
  );
};

const StyledNoLabelBox = styled(Box)<{ $isEditable: boolean }>`
  cursor: ${({ $isEditable }) => ($isEditable ? "pointer" : "default")};
  border-radius: 8px;
  .hover-hidden {
    display: inline-block;
  }
  .hover-display {
    display: none;
    cursor: pointer;
  }
  :hover {
    .hover-hidden {
      display: ${({ $isEditable }) => ($isEditable ? "none" : "inline-block")};
    }
    .hover-display {
      display: inline-block;
      background-color: rgba(${mixin.generateRgbaFromColorCode(theme.palette.grey[400])}, 0.1);
      width: 100%;
      border-radius: 8px;
    }
  }
`;

const StyledButtonWrapper = styled(Box)`
  .MuiButtonBase-root > div {
    padding: 4px 12px;
    :hover {
      background-color: transparent;
    }
  }
`;

const StyledBox = styled(Box)`
  :hover {
    .button-wrapper {
      visibility: visible;
    }
  }
  .button-wrapper {
    visibility: hidden;
  }
  .MuiButtonBase-root {
    border-radius: 5px;
    :hover {
      svg {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
