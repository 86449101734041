import { Box } from "@mui/material";
import { RichMenuImageFileConstraints } from "@onn/common";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../RichMenuTabContext";
import { InputState } from "../useForm";

import { DisplaySelector } from "./components/DisplaySelector";
import { ImageFileForm } from "./components/ImageFileForm";
import { PreviewRichMenuImageFile } from "./components/PreviewRichMenuImageFile";

import { Icon, Loading as LoadingUIPart, Tooltip, Typography } from "~/components/uiParts";
import { useMetaDataByUrls } from "~/hooks/file";

export const ImageSetting: FC = () => {
  const { selectedRecruitmentStatus } = useRichMenuTabContext();

  const { control } = useFormContext<InputState>();
  const richMenuImageController = useController({ control, name: "richMenuImage" });
  const {
    field: { value: isSetRichMenu },
  } = useController({ control, name: "isSetRichMenu" });

  const filePath = richMenuImageController.field.value.filePath;

  const { data: filesMetaData = [], isLoading: isLoadingFilesMetaData } = useMetaDataByUrls(
    filePath ? [filePath] : []
  );
  const imageFileMetaData = filesMetaData[0];
  const { isUploadingRichMenuImage: isUploading } = useRichMenuTabContext();

  const isShowImageFilePicker = isSetRichMenu === "true";
  return (
    <Box display="flex" flexDirection={"column"} rowGap={"16px"}>
      <Box display="flex" alignItems="center" columnGap={"8px"}>
        <Typography bold variant="body2">
          表示画像
        </Typography>
        <Tooltip
          title={`設定する画像は以下の制約を満たしてください。
・ファイルサイズが${RichMenuImageFileConstraints.maxImageFileSizeMb}MB以下
・pngまたはjpeg形式
・横幅は${RichMenuImageFileConstraints.minWidth}px〜${RichMenuImageFileConstraints.maxWidth}px
・縦幅は${RichMenuImageFileConstraints.minHeight}px以上
・アスペクト比（幅÷高さ）は${RichMenuImageFileConstraints.minAspectRatio}〜${RichMenuImageFileConstraints.maxAspectRatio}
            `}
          placement="top-start"
        >
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </Box>
      <Typography variant="body2">
        {selectedRecruitmentStatus
          ? `「${selectedRecruitmentStatus.label}」の選考ステータスに該当する候補者に表示されるリッチメニューです。`
          : `未登録の候補者に表示されるリッチメニューです。`}
      </Typography>
      <Box>
        <DisplaySelector />
      </Box>
      {isShowImageFilePicker &&
        (imageFileMetaData ? (
          <PreviewRichMenuImageFile src={imageFileMetaData.url} />
        ) : isLoadingFilesMetaData || isUploading ? (
          <Loading />
        ) : (
          <ImageFileForm />
        ))}
    </Box>
  );
};

const Loading: FC = () => {
  return (
    <Box height={"290px"} width={"100%"}>
      <LoadingUIPart size="small" />
    </Box>
  );
};
