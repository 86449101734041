import { OnnEventEvaluationRankV2 } from "@onn/common";
import React, { FC, useMemo } from "react";
import { useController } from "react-hook-form";

import { FieldValues } from "../../hooks/form/FieldValues";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const OnnEventEvaluationRankSelector: FC<{
  selectableOnnEventEvaluationRanks: OnnEventEvaluationRankV2[];
}> = ({ selectableOnnEventEvaluationRanks }) => {
  const { field: onnEventEvaluationRankIdField, fieldState } = useController<
    FieldValues,
    `onnEventEvaluationRankId`
  >({
    name: `onnEventEvaluationRankId`,
  });
  const menuItems = useMemo(
    () =>
      selectableOnnEventEvaluationRanks
        .map((onnEventEvaluationRank) => ({
          value: onnEventEvaluationRank.id,
          name: onnEventEvaluationRank.label,
        }))
        .concat({ value: "", name: "選択してください" }),
    [selectableOnnEventEvaluationRanks]
  );

  return (
    <SelectFormV2
      menuItems={menuItems}
      selected={onnEventEvaluationRankIdField.value}
      errorText={fieldState.error?.message}
      onChange={(e) => {
        onnEventEvaluationRankIdField.onChange(e.target.value);
      }}
      fullWidth
    />
  );
};
