import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import { BusinessHoursSetting } from "@onn/common";
import { BusinessHoursSettingSchema } from "@onn/common/domain/_gen/zodSchema";
import { parse } from "date-fns";
import React, { FC, useCallback, useState } from "react";

import { useForm } from "react-hook-form";
import styled from "styled-components";

import { z } from "zod";

import { ViewerAndEditorManager } from "../ViewerAndEditorManager";

import {
  BusinessHoursSettingsEditor,
  BusinessHoursSettingsEditorStatInputState,
} from "~/components/domains/businessHours/BusinessHoursSettingsEditor/BusinessHoursSettingsEditor";
import { BusinessHoursSettingsViewer } from "~/components/domains/businessHours/BusinessHoursSettingsViewer";
import { Loading, Switch, Typography } from "~/components/uiParts";
import { useBusinessHoursSettings, useSaveBusinessHoursSetting } from "~/hooks/businessHours";
import { useSnackbar } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";
import { captureException } from "~/util";

export const BusinessHoursSettingsSection: FC = () => {
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();

  const { data: businessHoursSettings, isLoading: isLoadingBusinessHoursSettings } =
    useBusinessHoursSettings();

  const handleSwitchBussinessHoursIsEnable = useCallback(async () => {
    await TenantSettingsUseCase.newUpdate(tenantSettings.id, {
      businessHours: {
        isEnable: !tenantSettings.businessHours?.isEnable,
      },
    });
    mutateTenantSettings();
  }, [mutateTenantSettings, tenantSettings]);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        <Stack px={2} pt={4} rowGap="32px">
          <Stack rowGap="16px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" bold>
                営業時間設定
              </Typography>
              <Switch
                checked={!!tenantSettings.businessHours?.isEnable}
                onChange={handleSwitchBussinessHoursIsEnable}
                color="primary"
              />
            </Box>
            <StyledTypography variant="caption">
              {`この設定を有効にすると、企業から候補者への通知は営業時間外には送信されません。営業時間外の通知は、自動的に次の営業時間に送信されます。\n※直接的なコンタクト（個別メッセージなど）は、この設定の対象外です。`}
            </StyledTypography>
          </Stack>
          {!!tenantSettings.businessHours?.isEnable && (
            <>
              {isLoadingBusinessHoursSettings ? (
                <Box mt={2}>
                  <Loading size="small" />
                </Box>
              ) : (
                <BusinessHoursSettingsEditorWrapper
                  businessHoursSetting={businessHoursSettings?.[0]} // NOTE: 初期は1つのみの設定を想定
                />
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const BusinessHoursSettingsEditorWrapper = ({
  businessHoursSetting,
}: {
  businessHoursSetting?: BusinessHoursSetting;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { saveBusinessHoursSetting } = useSaveBusinessHoursSetting();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickStartEditSettingButton = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleClickCancelEditSettingButton = useCallback(() => {
    setIsEditing(false);
  }, []);

  const form = useForm<BusinessHoursSettingsEditorStatInputState>({
    defaultValues: {
      daysOfWeeks: businessHoursSetting?.daysOfWeeks || BusinessHoursSetting.getAllWeekDays(),
      fromDate: businessHoursSetting ? `${businessHoursSetting.getFromTimeText()}` : "8:00",
      untilDate: businessHoursSetting ? `${businessHoursSetting?.getUntilTimeText()}` : "18:00",
    },
    mode: "all",
    resolver: zodResolver(
      z
        .object({
          daysOfWeeks: BusinessHoursSettingSchema.shape.daysOfWeeks.min(
            1,
            "1つ以上選択してください"
          ),
          fromDate: z.string(),
          untilDate: z.string(),
        })
        .superRefine((value, ctx) => {
          const now = new Date();
          if (parse(value.fromDate, "HH:mm", now) >= parse(value.untilDate, "HH:mm", now)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "開始時間は終了時間よりも前に設定してください",
              path: ["untilDate"],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "開始時間は終了時間よりも前に設定してください",
              path: ["fromDate"],
            });
          }
        })
    ),
  });

  const { formState } = form;

  const submit = form.handleSubmit(async (data) => {
    try {
      const now = new Date();
      const fromDate = BusinessHoursSetting.parseTextToDate(data.fromDate, now);
      const untilDate = BusinessHoursSetting.parseTextToDate(data.untilDate, now);

      await saveBusinessHoursSetting({
        id: businessHoursSetting?.id,
        inputFields: {
          daysOfWeeks: data.daysOfWeeks,
          fromHours: fromDate.getHours(),
          fromMinutes: fromDate.getMinutes(),
          untilHours: untilDate.getHours(),
          untilMinutes: untilDate.getMinutes(),
        },
      });
      enqueueSnackbar("営業時間を更新しました", { variant: "success" });
    } catch (error) {
      captureException({
        error: error as Error,
        tags: { type: "AssignTagsToEmployeeModal:handleAdd" },
      });
      enqueueSnackbar("予期せぬエラーが発生しました。再度お試しください", { variant: "error" });
    }

    setIsEditing(false);
  });

  return (
    <ViewerAndEditorManager
      editing={isEditing}
      onClickEditButton={handleClickStartEditSettingButton}
      onCancel={handleClickCancelEditSettingButton}
      onSubmit={submit}
      submitting={formState.isSubmitting}
      isSubmitDisabled={formState.isSubmitting || !formState.isValid}
      editButtonText="設定を編集"
      renderEditor={() => <BusinessHoursSettingsEditor form={form} />}
      renderViewer={() => (
        <BusinessHoursSettingsViewer businessHoursSetting={businessHoursSetting} />
      )}
    />
  );
};

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
