import { isEmpty } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

export const useScenarioSelection = () => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);
  const options = useMemo(() => {
    return scenariosWithRecruitmentStatuses.map(({ scenario }) => ({
      value: scenario.id,
      label: scenario.name,
    }));
  }, [scenariosWithRecruitmentStatuses]);

  const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>(
    options.map((option) => option.value)
    // NOTE: 全選択を初期値とする
  );

  const toggleIsSelected = useCallback((scenarioId: string) => {
    setSelectedScenarioIds((prev) => {
      if (prev.includes(scenarioId)) {
        return prev.filter((id) => id !== scenarioId);
      }
      return [...prev, scenarioId];
    });
  }, []);

  const selectedCount = useMemo(() => selectedScenarioIds.length, [selectedScenarioIds]);

  const isSelectedAll = useMemo(
    () => selectedCount === options.length,
    [options.length, selectedCount]
  );

  const isSelectedSome = useMemo(
    () => !isEmpty(selectedScenarioIds) && !isSelectedAll,
    [isSelectedAll, selectedScenarioIds]
  );

  const toggleIsSelectedAll = useCallback(() => {
    if (isSelectedAll) {
      setSelectedScenarioIds([]);
    } else {
      setSelectedScenarioIds(options.map((option) => option.value));
    }
  }, [isSelectedAll, options]);

  return {
    options,
    selectedScenarioIds,
    selectedCount,
    isSelectedSome,
    isSelectedAll,
    toggleIsSelected,
    toggleIsSelectedAll,
  };
};
