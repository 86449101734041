import { v4 } from "uuid";

import { SingleSelectField } from "../../types";

import {
  IOnnEventEvaluationSingleSelectValue,
  onnEventEvaluationSingleSelectValueSchema,
} from "./schema";

export class OnnEventEvaluationSingleSelectValue implements IOnnEventEvaluationSingleSelectValue {
  static validator = onnEventEvaluationSingleSelectValueSchema;
  type = SingleSelectField;

  id: string;
  tenantId: string;
  onnEventEvaluationSingleSelectFieldId: string;
  onnEventEvaluationSingleSelectOptionId: string | null;
  employeeId: string;
  createdEmployeeId?: string;
  updatedEmployeeId?: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationSingleSelectValue>, "type">) {
    const parsedInit = OnnEventEvaluationSingleSelectValue.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationSingleSelectFieldId = parsedInit.onnEventEvaluationSingleSelectFieldId;
    this.onnEventEvaluationSingleSelectOptionId = parsedInit.onnEventEvaluationSingleSelectOptionId;
    this.employeeId = parsedInit.employeeId;
    this.createdEmployeeId = parsedInit.createdEmployeeId;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドバリューデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationSingleSelectValue>,
      "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationSingleSelectValue {
    return new OnnEventEvaluationSingleSelectValue({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationSingleSelectValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationSingleSelectValue {
    return new OnnEventEvaluationSingleSelectValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<
      OnnEventEvaluationSingleSelectValue,
      "onnEventEvaluationSingleSelectOptionId" | "updatedEmployeeId"
    >
  ): OnnEventEvaluationSingleSelectValue {
    return new OnnEventEvaluationSingleSelectValue({
      ...this,
      onnEventEvaluationSingleSelectOptionId: params.onnEventEvaluationSingleSelectOptionId,
      updatedEmployeeId: params.updatedEmployeeId,
      updatedAt: new Date(),
    });
  }

  getFieldId() {
    return this.onnEventEvaluationSingleSelectFieldId;
  }
}
