import { useCallback, useState } from "react";

import { useWithBusinessHoursConfirmationModal } from "../../businessHours/BusinessHoursConfirmationModal";

import { useConvertFileToBase64 } from "~/hooks/file";
import { useAnswerOnBehalfToExistedSlotDatesWithCsv } from "~/hooks/onnEventSlotDates/useAnswerOnBehalfToExistedSlotDatesWithCsv";
import { captureException } from "~/util";

export const useHandleUploadFile = ({
  onnEventId,
  inputFile,
  onCancel,
}: {
  onnEventId: string;
  inputFile: File | undefined;
  onCancel: () => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const { answerOnBehalfToExistedSlotDatesWithCsv } = useAnswerOnBehalfToExistedSlotDatesWithCsv();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const { convertFileToBase64 } = useConvertFileToBase64();
  const handleUpload = useCallback(async () => {
    withBusinessHours(async (forceNotifyImmediately) => {
      if (!inputFile) return;

      try {
        setIsUploading(true);

        const base64EncodedCsvFile = await convertFileToBase64(inputFile);
        await answerOnBehalfToExistedSlotDatesWithCsv({
          onnEventId,
          base64EncodedCsvFile,
          isDryRun: false,
          forceNotifyImmediately,
        });
        onCancel();
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "AnswerNewInterviewEventOnBehalfWithCSVModal:handleUpload",
          },
          extras: {
            onnEventId,
          },
        });
      } finally {
        setIsUploading(false);
      }
    });
  }, [
    answerOnBehalfToExistedSlotDatesWithCsv,
    convertFileToBase64,
    inputFile,
    onCancel,
    onnEventId,
    withBusinessHours,
  ]);

  return { handleUpload, isUploading };
};
