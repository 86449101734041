import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// ONN EVENT EVALUATION DRAFT SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationDraftSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  onnEventEvaluationRankId: z.string().nullish(),
  onnEventEvaluationValues: JsonValueSchema,
  updatedEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationDraft = z.infer<typeof OnnEventEvaluationDraftSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION DRAFT OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationDraftOptionalDefaultsSchema = OnnEventEvaluationDraftSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventEvaluationDraftOptionalDefaults = z.infer<
  typeof OnnEventEvaluationDraftOptionalDefaultsSchema
>;

export default OnnEventEvaluationDraftSchema;
