export const RichMenuImageFileConstraints = {
  maxImageFileSizeMb: 1,
  maxFileSizeByes: 1024 * 1024,
  accept: ["image/jpeg", "image/png"],
  minWidth: 800,
  maxWidth: 2500,
  minHeight: 250,
  fixedWidth: 2500, // NOTE: アプリケーション仕様として2500pxに設定する
  fixedHeiht: 1686, // NOTE: アプリケーション仕様として1686pxに設定する
  minAspectRatio: 1.45,
  maxAspectRatio: 1.51,
};

export const validateRichMenuImageSize = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): { isOk: true } | { isOk: false; message: string } => {
  if (width < RichMenuImageFileConstraints.minWidth) {
    return {
      isOk: false,
      message: `画像の幅は${RichMenuImageFileConstraints.minWidth}px以上にしてください`,
    };
  }
  if (width > RichMenuImageFileConstraints.maxWidth) {
    return {
      isOk: false,
      message: `画像の幅は${RichMenuImageFileConstraints.maxWidth}px以下にしてください`,
    };
  }
  if (height < RichMenuImageFileConstraints.minHeight) {
    return {
      isOk: false,
      message: `画像の高さは${RichMenuImageFileConstraints.minHeight}px以上にしてください`,
    };
  }
  const aspectRatio = width / height;
  if (
    aspectRatio < RichMenuImageFileConstraints.minAspectRatio ||
    aspectRatio > RichMenuImageFileConstraints.maxAspectRatio
  ) {
    return {
      isOk: false,
      message: `アスペクト比（幅÷高さ）が${RichMenuImageFileConstraints.minAspectRatio}〜${RichMenuImageFileConstraints.maxAspectRatio}の画像を設定してください`,
    };
  }
  return { isOk: true };
};
