import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const updateDeliverySettingOfOnnEventRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
  newGraduatesIds: z.array(z.string()).optional(),
  deadlineDate: z.date().optional(),
  scheduledDate: z.date().optional(),
  canAnswerAfterDeadline: z.boolean().optional(),
  forceNotifyImmediately: z.boolean().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateDeliverySettingOfOnnEventRequest
  extends z.infer<typeof updateDeliverySettingOfOnnEventRequestSchema> {}
