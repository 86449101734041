import { Box, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { RecruitmentStatus, Scenario } from "@onn/common";
import React, { FC, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../../RichMenuTabContext";
import { InputState } from "../../useForm";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { Button, Checkbox, FormControlLabel, Icon } from "~/components/uiParts";
import { useDisplayScenariosWithRecruitmentStatuses } from "~/hooks/recruitmentStatus";

export const TargetRecruitmentStatusesSelector: FC = () => {
  const { control } = useFormContext<InputState>();
  const {
    field: { value: asyncTargetRecruitmentStatusIds, onChange: setAsyncTargetSelectedStatusIds },
  } = useController({ control, name: "asyncTargetRecruitmentStatusIds" });
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const { displayScenariosWithRecruitmentStatuses } =
    useDisplayScenariosWithRecruitmentStatuses(true);

  const [scenarioWithStatusesListAnchorEl, setScenarioWithStatusesListAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleClickScenarioWithStatusesListAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    setScenarioWithStatusesListAnchorEl(event.currentTarget);
  };

  const [selectedScenarioAndStatusList, setSelectedScenarioAndStatusList] = React.useState<
    {
      scenarioId: string;
      recruitmentStatusId: string;
    }[]
  >([]);
  const updateSelectedValues = ({
    scenarioId,
    recruitmentStatusId,
  }: {
    scenarioId: string;
    recruitmentStatusId: string;
  }) => {
    const newSelectedValue = selectedScenarioAndStatusList.some(
      (value) =>
        value.scenarioId === scenarioId && value.recruitmentStatusId === recruitmentStatusId
    )
      ? selectedScenarioAndStatusList.filter(
          (value) =>
            value.scenarioId !== scenarioId || value.recruitmentStatusId !== recruitmentStatusId
        )
      : [...selectedScenarioAndStatusList, { scenarioId, recruitmentStatusId }];
    setSelectedScenarioAndStatusList(newSelectedValue);
    setAsyncTargetSelectedStatusIds(newSelectedValue.map((value) => value.recruitmentStatusId));
  };

  const selectedRecruitmentStatusLabel = selectedScenarioAndStatusList
    .map(
      (selectedScenarioAndStatus) =>
        `${
          displayScenariosWithRecruitmentStatuses.find(
            (scenarioWithRecruitmentStatuses) =>
              scenarioWithRecruitmentStatuses.scenario.id === selectedScenarioAndStatus.scenarioId
          )?.scenario.name || ""
        }／${
          recruitmentStatuses.find(
            (status) => status.id === selectedScenarioAndStatus.recruitmentStatusId
          )?.label || ""
        }`
    )
    .join(",");

  return (
    <Box width={"100%"}>
      <Tooltip title={selectedRecruitmentStatusLabel}>
        <Box>
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={handleClickScenarioWithStatusesListAnchorEl}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {selectedScenarioAndStatusList.length === 0
                  ? "選考ステータスを選択"
                  : selectedRecruitmentStatusLabel}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Tooltip>

      <Menu
        anchorEl={scenarioWithStatusesListAnchorEl}
        open={Boolean(scenarioWithStatusesListAnchorEl)}
        onClose={() => setScenarioWithStatusesListAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          {displayScenariosWithRecruitmentStatuses.map((scenarioWithRecruitmentStatuses) => {
            return (
              <ScenarioWithRecruitmentStatusesMenuItem
                key={scenarioWithRecruitmentStatuses.scenario.id}
                scenarioWithRecruitmentStatuses={scenarioWithRecruitmentStatuses}
                selectedRecruitmentStatusIds={asyncTargetRecruitmentStatusIds}
                setSelectedValues={updateSelectedValues}
              />
            );
          })}
        </Box>
      </Menu>
    </Box>
  );
};

type ScenarioWithRecruitmentStatuses = {
  scenario: Scenario;
  recruitmentStatuses: RecruitmentStatus[];
};

type ScenarioWithRecruitmentStatusesMenuItemProps = {
  scenarioWithRecruitmentStatuses: ScenarioWithRecruitmentStatuses;
  selectedRecruitmentStatusIds: string[];
  setSelectedValues: (value: { scenarioId: string; recruitmentStatusId: string }) => void;
} & React.ComponentProps<typeof MenuItem>;

const ScenarioWithRecruitmentStatusesMenuItem: FC<ScenarioWithRecruitmentStatusesMenuItemProps> = ({
  scenarioWithRecruitmentStatuses,
  selectedRecruitmentStatusIds,
  setSelectedValues,
  ...props
}) => {
  const [recruitmentStatusMenuAnchorEl, setRecruitmentStatusMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleClickRecruitmentStatusAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    setRecruitmentStatusMenuAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment key={scenarioWithRecruitmentStatuses.scenario.id}>
      <MenuItem onClick={handleClickRecruitmentStatusAnchorEl} {...props}>
        <Typography variant="body2" color="textSecondary">
          {scenarioWithRecruitmentStatuses.scenario.name}
        </Typography>
      </MenuItem>
      <RecruitmentStatusesMenu
        scenarioWithRecruitmentStatuses={scenarioWithRecruitmentStatuses}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        open={Boolean(recruitmentStatusMenuAnchorEl)}
        anchorEl={recruitmentStatusMenuAnchorEl}
        onClose={() => setRecruitmentStatusMenuAnchorEl(null)}
        setSelectedValues={setSelectedValues}
      />
    </React.Fragment>
  );
};

type RecruitmentStatusesMenuProps = {
  scenarioWithRecruitmentStatuses: ScenarioWithRecruitmentStatuses;
  selectedRecruitmentStatusIds: string[];
  setSelectedValues: (value: { scenarioId: string; recruitmentStatusId: string }) => void;
} & React.ComponentProps<typeof Menu>;

const RecruitmentStatusesMenu: FC<RecruitmentStatusesMenuProps> = ({
  scenarioWithRecruitmentStatuses,
  selectedRecruitmentStatusIds,
  setSelectedValues,
  ...props
}) => {
  const { selectedRecruitmentStatus: currentRecruitmentStatus } = useRichMenuTabContext();

  const handleChangeCheckbox = useCallback(
    (statusId: string) => {
      setSelectedValues({
        scenarioId: scenarioWithRecruitmentStatuses.scenario.id,
        recruitmentStatusId: statusId,
      });
    },
    [setSelectedValues, scenarioWithRecruitmentStatuses]
  );
  return (
    <Menu
      {...props}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box>
        {scenarioWithRecruitmentStatuses.recruitmentStatuses.map((status) => {
          const isDisabled = currentRecruitmentStatus?.id === status.id;
          return (
            <MenuItem
              key={status.id}
              style={{
                padding: 0,
              }}
              disabled={isDisabled}
            >
              <FormControlLabel
                onChange={() => handleChangeCheckbox(status.id)}
                control={
                  <Checkbox
                    checked={selectedRecruitmentStatusIds.includes(status.id)}
                    name={status.label}
                    value={status.id}
                    disabled={isDisabled}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    {status.label}
                  </Typography>
                }
                style={{
                  padding: "8px 16px",
                  width: "100%",
                }}
                disabled={isDisabled}
              />
            </MenuItem>
          );
        })}
      </Box>
    </Menu>
  );
};
