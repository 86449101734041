import { createTheme } from "@material-ui/core/styles";

// TODO: atomic design移行完了後にThemeProviderに移行する

export const colors = {
  primary: {
    light: "#D4F7F0",
    main: "#27E0B7",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#FFE5E4",
    main: "#FC7878",
    contrastText: "#ffffff",
  },
  grey: {
    25: "#FCFCFC",
    50: "#F7F7F7",
    100: "#E6E6E6",
    200: "#CCCCCC",
    300: "#959595",
    400: "#757575",
    500: "#404040",
  },
  blue: {
    light: "#C8EFFE",
    main: "#27C1FC",
    contrastText: "#ffffff",
  },
  error: {
    main: "#E00B75",
    dark: "#E0340B",
    light: "#fd7878",
  },
  text: {
    primary: "#404040",
    secondary: "#757575",
    muted: "#959595",
  },

  background: {
    default: "#fff",
  },
  divider: "#E8E8E8",
  action: {
    active: "#000000",
  },
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// style関連の定数
const SIDEBAR_OPEN_WIDTH = 270;
const CENTRAL_BOX_WRAPPER_PADDING_X = 40;
export const constants = {
  HEADER_HEIGHT: 64,
  SIDEBAR_OPEN_WIDTH,
  SIDEBAR_CLOSED_WIDTH: 104,
  STANDARD_IMAGE_WIDTH_RATIO: 1.91, // FBやTwitter、NoteのOGPの縦幅に対する横幅の比率
  CENTRAL_BOX_WRAPPER_PADDING_X,
  ADMIN_CONTENT_MIN_WIDTH: breakpoints.md - SIDEBAR_OPEN_WIDTH - CENTRAL_BOX_WRAPPER_PADDING_X * 2,
};

const shadows = ["0px 0px 10px #0000001A", "0px 0px 10px #0000000D", "0px 0px 10px #00000026"];

const theme = createTheme({
  palette: colors,
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    shadows[0] as (typeof shadows)[number],
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    shadows[1] as (typeof shadows)[number],
    shadows[2] as (typeof shadows)[number],
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  breakpoints: {
    values: breakpoints,
  },
  typography: {
    h1: {
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 32,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 1.7,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 1.7,
    },
    button: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 10,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: shadows[1],
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.grey[200],
        fontSize: 12,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.5rem",
      },
    },
    MuiTypography: {
      button: {
        textTransform: "none",
      },
    },
    MuiTabs: {
      scroller: {
        overflowX: "auto !important" as "auto",
        // 行儀は良くないが、overflow: hidden; が直接 div の style に設定されてしまい変更できないため
        overflowY: "hidden",
      },
      root: {
        paddingTop: 16,
      },
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      root: {
        color: colors.text.primary,
        fontWeight: "bold",
        padding: 0,
        "@media (min-width: 600px)": {
          minWidth: "auto",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 16,
      },
      elevation1: {
        boxShadow: shadows[1],
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiListItemText: {
      primary: {
        color: colors.text.primary,
        fontWeight: "bold",
        fontSize: 16,
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: shadows[1],
        minWidth: constants.ADMIN_CONTENT_MIN_WIDTH,
      },
    },
    MuiTable: {
      root: {},
    },
    MuiMenu: {
      paper: {
        boxShadow: shadows[1],
        borderRadius: 8,
        // width: "20ch"
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: 24,
        paddingTop: 16,
        paddingBottom: 16,
        color: colors.text.secondary,
        fontSize: 12,
      },
    },
    MuiFormControlLabel: {
      labelPlacementTop: {
        marginLeft: 0,
      },
    },
    MuiRadio: {
      root: {
        color: colors.primary.main,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.primary.main,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: "normal",
        fontFamily: "Montserrat",
        fontSize: 16,
      },
    },
    MuiSelect: {
      root: {
        color: colors.text.secondary,
        font: "Bold 12px/24px Noto Sans JP",
        padding: 10,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
        padding: 40,
      },
    },
  },
});

export default theme;
