import React, { FC } from "react";

import { useOnChangeFile } from "../hooks/useOnChangeFile";

import { FilePicker } from "~/components/uiParts";

export const RichMenuImageFilePicker: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { onChangeFile } = useOnChangeFile();

  return (
    <FilePicker
      inputAccept="image/jpeg, image/png"
      maxImageFileSizeMb={1}
      multiple={false}
      onChange={onChangeFile}
    >
      {children}
    </FilePicker>
  );
};
