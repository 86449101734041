import { z } from "zod";

import { OnnEventEvaluationTextValueSchema } from "../../../../../_gen/zodSchema/index";

export const onnEventEvaluationTextValueSchema = OnnEventEvaluationTextValueSchema.merge(
  z.object({
    createdEmployeeId: z.string().optional(),
    updatedEmployeeId: z.string().optional(),
  })
);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationTextValue
  extends z.infer<typeof onnEventEvaluationTextValueSchema> {}
