import { Employee } from "../../../../../Employee";
import { OnnEventEvaluationFieldAccessControlType } from "../../OnnEventEvaluationFieldAccessControlType";

export class BaseOnnEventEvaluationField {
  id: string;
  tenantId: string;
  label: string;
  order: number;
  onnEventId: string;
  accessControlType: OnnEventEvaluationFieldAccessControlType;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<BaseOnnEventEvaluationField>) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.label = init.label;
    this.order = init.order;
    this.onnEventId = init.onnEventId;
    this.accessControlType = init.accessControlType;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  isEditable(currentUser: Employee): boolean {
    if (currentUser.isNewGraduateEmployee()) return false;
    if (currentUser.isAdmin()) return true;

    return this.accessControlType === "ALL";
  }
}
