import { Box } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

import { EmployeeInformationSingleSelectTypeFieldCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Choice } from "~/components/types/choice";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

const TARGET = "employeeInformationSingleSelectTypeField";

type Props = CommonProps<EmployeeInformationSingleSelectTypeFieldCondition>;
export const EmployeeInformationSingleSelectFieldConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: employeeInformationGroups } = useEmployeeInformationGroups();
  const choices = useMemo(() => {
    const employeeInformation = employeeInformationGroups
      ?.flatMap((group) => group.employeeInformationFieldWithOptions)
      .find((info) => info.id === condition.employeeInformationId);

    if (employeeInformation?.type !== "SINGLE_SELECT") return [];

    return employeeInformation.options.map((option) => ({ label: option.label, value: option.id }));
  }, [employeeInformationGroups, condition.employeeInformationId]);

  const onChange = useCallback(
    (newChoice: Choice<string>) => {
      onChangeCondition(index, { ...condition, employeeInformationOptionId: newChoice.value });
    },
    [index, condition, onChangeCondition]
  );

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
          employeeInformationId={condition.employeeInformationId}
        />
      </Box>
      <Box flex={9}>
        <SelectSingleConditionDropdown
          key={`singleSelect-${condition.employeeInformationId}`}
          selectedChoice={choices.find(
            (choice) => choice.value === condition.employeeInformationOptionId
          )}
          choices={choices}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};
