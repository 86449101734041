import { BusinessHoursSetting } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const getKey = () =>
  ({
    endpoint: "/api/business-hours-settings",
  } as const);

export const useBusinessHoursSettings = () => {
  return useSWR(getKey(), async ({ endpoint }) => {
    return apiClient.get(endpoint).then((res) => {
      return res.data.map((businessHoursSetting) => new BusinessHoursSetting(businessHoursSetting));
    });
  });
};

export const mutateBusinessHoursSettings = () => {
  return mutate(getKey());
};
