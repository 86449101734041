import { z } from "zod";

import { OnnEventEvaluationRankV2Schema } from "../../../../_gen/zodSchema/index";

export const onnEventEvaluationRankV2Schema = OnnEventEvaluationRankV2Schema;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationRankV2 extends z.infer<typeof onnEventEvaluationRankV2Schema> {}

export const onnEventEvaluationRanksV2InputSchema = z
  .array(
    z.object({
      id: z.string(),
      label: z.string().min(1, { message: "入力してください" }),
    })
  )
  .superRefine((v, ctx) => {
    if (v.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "評価ランクは1つ以上必要です",
      });
    }

    // ラベルの重複チェック
    const duplicatedLabels = v
      .filter(({ label }, index, ranks) => {
        const isDuplicated = ranks.findIndex((r) => r.label === label) !== index;
        return isDuplicated;
      })
      .map(({ label }) => label);
    const duplicatedIndexes = v.reduce((acc, { label }, index) => {
      if (duplicatedLabels.includes(label)) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);
    duplicatedIndexes.forEach((duplicatedIndex) => {
      ctx.addIssue({
        path: [`${duplicatedIndex}.label`],
        code: z.ZodIssueCode.custom,
        message: "評価ランクのラベルが重複しています",
      });
    });
  });

export type OnnEventEvaluationRanksV2InputType = z.infer<
  typeof onnEventEvaluationRanksV2InputSchema
>;
