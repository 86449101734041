import { Box } from "@material-ui/core";
import React from "react";

import { IdCondition } from "../../../types/condition";

import { FreeTextInput } from "../../parts/FreeTextInput";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { CommonProps } from "./type";

const TARGET = "id";

type Props = CommonProps<IdCondition>;
export const IdConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
        />
      </Box>
      <Box flex={9}>
        <FreeTextInput
          value={condition.searchString ?? ""}
          onChange={(s) => onChangeCondition(index, { target: TARGET, searchString: s })}
        />
      </Box>
    </Box>
  );
};
