import { Box, FormControlLabel, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { useSelectNotificationOption } from "./hooks/useSelectNotificationOption";

import { Button, Loading, Modal, RadioButton } from "~/components/uiParts";
import { useBusinessHoursSettings } from "~/hooks/businessHours/useBusinessHoursSettings";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (isForce: boolean) => void;
};
export const BusinessHoursConfirmationModal: FC<Props> = ({ open, onCancel, onConfirm }) => {
  const { data: businessHoursSettings, isLoading: isLoadingBusinessHoursSettings } =
    useBusinessHoursSettings();

  const businessHoursText = useMemo(() => {
    // NOTE: 初期は1つのみの設定を想定
    const businessHoursSetting = businessHoursSettings?.[0];
    if (!businessHoursSetting) return "";

    return businessHoursSetting.getBusinessHoursText();
  }, [businessHoursSettings]);

  const { isForce, onSelectForceOption, onSelectNotifyDuringBusinessHoursOption } =
    useSelectNotificationOption();

  const content = useMemo(() => {
    return isLoadingBusinessHoursSettings ? (
      <Loading size="large" />
    ) : (
      <Grid container flexDirection={"column"} alignItems={"center"} gap={"32px"}>
        <Grid item flexDirection={"column"} alignItems={"center"}>
          <Typography variant="body1" align="center">
            設定された営業時間外です。
          </Typography>
          <Typography variant="body1" align="center">
            営業時間：
          </Typography>
          <Typography variant="body1" align="center">
            {businessHoursText}
          </Typography>
        </Grid>

        <Grid item flexDirection={"column"} alignItems={"center"} gap={"16px"}>
          <Box>
            <StyledFormControlLabel
              value={!isForce}
              control={
                <StyledRadioButton
                  color="primary"
                  size="medium"
                  defaultColor={!isForce ? "primary" : "default"}
                  checked={!isForce}
                  onChange={onSelectNotifyDuringBusinessHoursOption}
                />
              }
              label={<Typography variant="body2">次の配信可能時間に自動送信する</Typography>}
            />
          </Box>
          <Box>
            <StyledFormControlLabel
              value={isForce}
              control={
                <StyledRadioButton
                  color="primary"
                  size="medium"
                  defaultColor={isForce ? "primary" : "default"}
                  checked={isForce}
                  onChange={onSelectForceOption}
                />
              }
              label={<Typography variant="body2">今すぐに送信する</Typography>}
            />
          </Box>
        </Grid>

        <Grid item justifyContent={"center"} alignItems={"center"}>
          <Typography variant="caption">{"この操作は取り消すことができません。"}</Typography>
        </Grid>
      </Grid>
    );
  }, [
    businessHoursText,
    isForce,
    isLoadingBusinessHoursSettings,
    onSelectForceOption,
    onSelectNotifyDuringBusinessHoursOption,
  ]);

  const footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={() => {
          onConfirm(isForce);
          onCancel();
        }}
      >
        確定
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="営業時間外の送信確認"
      content={content}
      footer={footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledRadioButton = styled(RadioButton)`
  padding-left: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
