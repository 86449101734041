import {
  UpdateDeliverySettingOfOnnEventRequest,
  UpdateDeliverySettingOfOnnEventResponse,
} from "@onn/common";
import { useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useUpdateDeliverySettingOfOnnEvent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const execUpdateDeliverySettingOfOnnEvent = async ({
    onnEventId,
    newGraduatesIds,
    deadlineDate,
    scheduledDate,
    canAnswerAfterDeadline,
    forceNotifyImmediately,
  }: {
    onnEventId: string;
    newGraduatesIds: string[];
    deadlineDate: Date | null;
    scheduledDate: Date | null;
    canAnswerAfterDeadline: boolean;
    forceNotifyImmediately?: boolean;
  }) => {
    try {
      const data = UpdateDeliverySettingOfOnnEventRequest.createRequestBody({
        onnEventId,
        newGraduatesIds,
        deadlineDate,
        scheduledDate,
        canAnswerAfterDeadline,
        forceNotifyImmediately,
      });
      setIsLoading(true);
      const response = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof UpdateDeliverySettingOfOnnEventRequest.createRequestBody>,
        typeof UpdateDeliverySettingOfOnnEventResponse.ResponseBody
      >("onnevent", data);
      return UpdateDeliverySettingOfOnnEventResponse.convertUnixTimeToDate(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { execUpdateDeliverySettingOfOnnEvent, isLoading };
};
