import { AllContactRoom, NewGraduateToDisplay, EmployeeInformation } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useSPNewGraduateDetailModal = () => {
  const { handleModal } = useModal();

  const handleOpenSPNewGraduateDetailModal = useCallback(
    (
      newGraduate: NewGraduateToDisplay,
      contactRoom: AllContactRoom,
      employeeInformation?: EmployeeInformation
    ) =>
      handleModal({
        name: "spNewGraduateDetailModal",
        args: {
          newGraduate,
          contactRoom,
          employeeInformation,
        },
      }),
    [handleModal]
  );

  return {
    handleOpenSPNewGraduateDetailModal,
  };
};
