import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { Button, Typography } from "~/components/uiParts";

type Props = {
  children: ReactNode;
  editing: boolean;
  updating: boolean;
  isSubmitDisabled?: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  mode?: "option" | "content";
};

/**
 * @deprecated より直接的な名称である ViewerAndEditorManagerに置き換える
 */
export const SettingsWrapper: FC<Props> = ({
  children,
  editing,
  updating,
  isSubmitDisabled,
  onEdit,
  onCancel,
  onSubmit,
  mode = "option",
}) => {
  return (
    <>
      {children}
      {editing ? (
        <Box display="flex" mt={3} gridColumnGap="16px">
          <Button
            variant="outlined"
            borderRadius="circle"
            color="default"
            onClick={onCancel}
            disabled={updating}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            borderRadius="circle"
            color="primary"
            onClick={onSubmit}
            disabled={updating || isSubmitDisabled}
          >
            設定保存
          </Button>
        </Box>
      ) : (
        <Box mt={2}>
          {mode === "option" ? (
            <StyledTypographyButton variant="caption" color="primary" onClick={onEdit}>
              オプション設定
            </StyledTypographyButton>
          ) : (
            <Button color="primary" variant="outlined" onClick={onEdit} borderRadius="circle">
              コンテンツを編集
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

const StyledTypographyButton = styled(Typography)`
  cursor: pointer;
`;
