import React from "react";

import { SpaceSwitcher } from "./SpaceSwitcher";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const SpaceSwitcherController = ({
  isExpand,
  ifFullWidthMenu,
  isAccessible = true,
}: {
  isExpand: boolean;
  ifFullWidthMenu: boolean;
  isAccessible?: boolean;
}) => {
  const { currentSpace, spaces, switchSpace, isShowSpaceOnScreen } = useCurrentSpace();

  if (!isShowSpaceOnScreen(spaces)) return null;

  return (
    <SpaceSwitcher
      currentSpace={currentSpace}
      spaces={spaces}
      isExpand={isExpand}
      onClickMenuItem={switchSpace}
      ifFullWidthMenu={ifFullWidthMenu}
      canSwitch={isAccessible}
    />
  );
};
