import { Box } from "@material-ui/core";
import React from "react";

import { Button, Icon } from "~/components/uiParts";

export const SettingStepFooter = ({
  isLoading,
  isDisabled,
  onBack,
  onCancel,
  onSubmit,
}: {
  isLoading: boolean;
  isDisabled: boolean;
  onBack: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent="space-between" width="100%">
      <Box>
        <Button borderRadius="circle" variant="text" color="default" onClick={onBack}>
          <Icon icon="arrowLeft" color="grey" size="ssm" />
          項目選択に戻る
        </Button>
      </Box>
      <Box display={"flex"} gridGap={"16px"}>
        <Box>
          <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
            キャンセル
          </Button>
        </Box>
        <Box>
          <Button
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            isLoading={isLoading}
            disabled={isDisabled}
          >
            ダウンロード
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
