import liff from "@line/liff";
import { Box, List } from "@material-ui/core";

import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { SpaceSwitcherController } from "../Sidebar/parts/SpaceSwitcherController";

import { Sidebar } from "~/components/layouts/Sidebar";

import { Divider, GlobalNaviMenu, Header } from "~/components/uiParts";
import { AccountSettingButton } from "~/components/uiParts/GlobalNaviMenu/parts/AccountSettingButton";
import { ListItem } from "~/components/uiParts/GlobalNaviMenu/parts/ListItem";
import { LogoutButton } from "~/components/uiParts/GlobalNaviMenu/parts/LogoutButton";

import { constants } from "~/config/theme";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";

import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useLocalStorage, useSwitchViewport } from "~/hooks/shared";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";
import { useTenant } from "~/hooks/tenant";

type Props = {
  children: ReactNode;
  isCentralChildren?: boolean;
};

export const AdminLayout: FC<Props> = ({ children, isCentralChildren }) => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const { storeValue } = useLocalStorage();
  const { isOpenSidebar: storedIsOpenSidebar } = useTheme();
  const { switchViewport } = useSwitchViewport();
  const shouldSPView = useShouldSPView();
  const currentPathname = window.location.pathname;
  const [isOpenSidebarByCurrentPath, setIsOpenSidebarByCurrentPath] = useState(
    currentPathname !== "/contact_rooms"
  );
  const { totalNotificationCount } = useContactContext();

  const isDisplayOnboardingMenu = !currentUser.isNewcomer();

  const isDisplayContactBadge = useMemo(() => {
    return totalNotificationCount > 0;
  }, [totalNotificationCount]);

  const onClickToggleIcon = React.useCallback(() => {
    if (!isOpenSidebarByCurrentPath) {
      // NOTE: コンタクトページでサイドバーを初回クリックした際は、LocalStorageの値に関わらず、”開く"挙動を実行するため。
      storeValue("isOpenSidebar", true);
      setIsOpenSidebarByCurrentPath(true);
      return;
    }

    storeValue("isOpenSidebar", !storedIsOpenSidebar);
  }, [storedIsOpenSidebar, storeValue, isOpenSidebarByCurrentPath, setIsOpenSidebarByCurrentPath]);

  const isOpenSideBar = useMemo(
    () => storedIsOpenSidebar && isOpenSidebarByCurrentPath,
    [storedIsOpenSidebar, isOpenSidebarByCurrentPath]
  );

  useEffect(() => {
    // コンタクトルームへ遷移した場合、サイドバーは閉じて表示する
    setIsOpenSidebarByCurrentPath(currentPathname !== "/contact_rooms");
  }, [currentPathname]);

  useEffect(() => {
    if (shouldSPView) switchViewport(currentPathname);
  }, [currentPathname, shouldSPView, switchViewport]);
  const navigate = useNavigate();
  const handleClickLogo = (): void => {
    navigate("/");
  };

  return (
    <>
      {shouldSPView && (
        <Header
          onClickLogo={currentUser.isRegularAcceptanceEmployee() ? handleClickLogo : undefined}
          isTenantLogoUsed={false}
          drawerMenu={
            <GlobalNaviMenu
              isBadge={isDisplayContactBadge}
              logoInfo={{
                isDisplay: true,
                handleClick: currentUser.isRegularAcceptanceEmployee()
                  ? handleClickLogo
                  : undefined,
              }}
              renderDrawerContent={() => (
                <Box display="flex" flexDirection="column" gridRowGap="16px">
                  {/* Spaceの個数によっては何もレンダリングされないケースがあるが、その判定はSpaceProviderに依存しているためこのコンポーネントでは判別できない */}
                  {tenant.isActiveNewGraduate && (
                    <SpaceSwitcherController
                      isExpand
                      ifFullWidthMenu
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                  )}
                  <List
                    style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
                    disablePadding
                  >
                    <ListItem
                      label={tenant.isActiveNewGraduate ? "候補者" : "オンボーディング"}
                      to="/"
                      icon="onboarding"
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                    {tenant.isActiveNewGraduate && (
                      <ListItem
                        label="イベント"
                        to="/events"
                        icon="calendar"
                        isAccessible={currentUser.isRegularAcceptanceEmployee()}
                      />
                    )}
                    <ListItem
                      label="タスク"
                      to={tenant.isActiveNewGraduate ? "/onn_tasks" : "/tasks"}
                      icon="checkOutlineCircle"
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                    <ListItem
                      label="コンタクト"
                      to="/contact_rooms"
                      icon="paperAirplane"
                      badgeLabel={
                        0 < totalNotificationCount ? totalNotificationCount.toString() : undefined
                      }
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                    <ListItem
                      label="ツール"
                      to="/tools"
                      icon="suitcase"
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                    <Divider margin={0} orientation="horizontal" />
                    <ListItem
                      label="設定"
                      to={currentUser.isAdmin() ? "/settings/admin" : "/settings/account"}
                      icon="setting"
                      isAccessible={currentUser.isRegularAcceptanceEmployee()}
                    />
                    <Divider margin={0} orientation="horizontal" />
                    <AccountSettingButton currentUser={currentUser} />
                    <Divider margin={0} orientation="horizontal" />
                    {/* TODO: コンポーネント分割するなどして、liff を排除する */}
                    {!liff.isInClient() && (
                      <Box pb="16px">
                        <LogoutButton />
                      </Box>
                    )}
                  </List>
                </Box>
              )}
            />
          }
        />
      )}
      {!shouldSPView && (
        <Sidebar
          isOpenSidebar={isOpenSideBar}
          isDisplayContactBadge={isDisplayContactBadge}
          isDisplayOnboardingMenu={isDisplayOnboardingMenu}
          toSettingsUrl={currentUser.isAdmin() ? "/settings/admin" : "/settings/account"}
          onClickToggleIcon={onClickToggleIcon}
        />
      )}
      <StyledBox $isOpenSidebar={isOpenSideBar} $isSP={!!shouldSPView}>
        {isCentralChildren ? (
          <StyledCentralBoxWrapper
            py={6}
            px={5}
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
          >
            <Box width="100%" maxWidth="1090px">
              {children}
            </Box>
          </StyledCentralBoxWrapper>
        ) : (
          children
        )}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)<{ $isOpenSidebar: boolean; $isSP: boolean }>`
  width: calc(
    100% -
      ${(props) => {
        if (props.$isSP) return 0;
        return props.$isOpenSidebar
          ? props.theme.constants.SIDEBAR_OPEN_WIDTH
          : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
      }}px
  );
  margin-left: ${(props) => {
    if (props.$isSP) return 0;
    return props.$isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;
`;
const StyledCentralBoxWrapper = styled(Box)`
  min-height: 100vh;
  background: ${(props) => props.theme.palette.grey[50]};

  &.MuiBox-root {
    padding: 48px ${constants.CENTRAL_BOX_WRAPPER_PADDING_X}px;
  }
`;
