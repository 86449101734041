import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT VALUE SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationMultipleSelectFieldId: z.string(),
  onnEventEvaluationMultipleSelectOptionId: z.string(),
  employeeId: z.string(),
  createdEmployeeId: z.string().nullish(),
  updatedEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationMultipleSelectValue = z.infer<
  typeof OnnEventEvaluationMultipleSelectValueSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectValueOptionalDefaultsSchema =
  OnnEventEvaluationMultipleSelectValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationMultipleSelectValueOptionalDefaults = z.infer<
  typeof OnnEventEvaluationMultipleSelectValueOptionalDefaultsSchema
>;

export default OnnEventEvaluationMultipleSelectValueSchema;
