import { Box } from "@mui/material";
import React, { FC } from "react";

import { DayOfWeek } from "../../../../../function/src/.prisma/client";

import { Typography } from "~/components/uiParts";

const daysOfWeeksItems = [
  { label: "日", value: "Sunday" },
  { label: "月", value: "Monday" },
  { label: "火", value: "Tuesday" },
  { label: "水", value: "Wednesday" },
  { label: "木", value: "Thursday" },
  { label: "金", value: "Friday" },
  { label: "土", value: "Saturday" },
] as const;

type Props = {
  values: DayOfWeek[];
  onClick: (value: DayOfWeek) => void;
};

export const DaysOfWeeksMenuItems: FC<Props> = ({ values, onClick }) => {
  return (
    <Box display="flex" columnGap="8px">
      {daysOfWeeksItems.map(({ label, value }) => (
        <DaysOfWeeksMenuItem
          key={value}
          text={label}
          isActive={values.includes(value)}
          onClickBox={() => onClick(value)}
        />
      ))}
    </Box>
  );
};

const DaysOfWeeksMenuItem = ({
  text,
  isActive,
  onClickBox,
}: {
  text: string;
  isActive: boolean;
  onClickBox: () => void;
}) => {
  return (
    <Box
      sx={{
        height: "40px",
        padding: "8px 16px",
        borderRadius: "8px",
        backgroundColor: isActive ? "primary.light" : "transparent",
        border: (props) =>
          `solid 1px ${isActive ? props.palette.primary.main : props.palette.grey[200]}`,
        cursor: "pointer",
      }}
      onClick={onClickBox}
    >
      <Typography variant="body2" bold color="textSecondary">
        {text}
      </Typography>
    </Box>
  );
};
