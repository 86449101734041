import { z } from "zod";

import { BusinessHoursSetting } from "../../../../domain/BusinessHoursSetting";

import { BusinessHoursSettingSchema } from "../../../../domain/_gen/zodSchema";

export interface APISchemaBusinessHoursSettings {
  ["/api/business-hours-settings"]: {
    GET: {
      response: {
        data: BusinessHoursSetting[];
      };
    };
    POST: {
      body: z.infer<typeof saveBusinessHoursSettingsSchema>["body"];
      response: {
        data: BusinessHoursSetting;
      };
    };
  };
}

export const saveBusinessHoursSettingsSchema = z.object({
  body: z.object({
    id: z.string().optional(),
    inputFields: BusinessHoursSettingSchema.pick({
      daysOfWeeks: true,
      fromHours: true,
      fromMinutes: true,
      untilHours: true,
      untilMinutes: true,
    }),
  }),
});
