import { Box, Grid, Typography } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { useCallback } from "react";

import { TagCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { Choice } from "../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";
import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Loading } from "~/components/uiParts";
import { useEmployeeTags } from "~/hooks/employeeTag";

const TARGET = "tag";

type Inclusion = Exclude<TagCondition["inclusion"], undefined>;
const includeChoice = { label: "含む", value: "include" } as const;
const excludeChoice = { label: "含まない", value: "exclude" } as const;
const inclusionChoices: Choice<Inclusion>[] = [includeChoice, excludeChoice];
const defaultInclusionChoice = includeChoice;

type Props = CommonProps<TagCondition>;
export const TagConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: employeeTagsData, isLoading } = useEmployeeTags();

  const { onChangeTagIds, onChangeInclusion } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { tagChoices } = useChoices({
    allTags: employeeTagsData?.employeeTags,
  });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target, employeeInformationId) =>
              onChangeTarget(index, target, employeeInformationId)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <SelectMultipleConditionDropdown
            placeHolder="タグを選択"
            key={"tags"}
            selectedChoices={tagChoices.filter((c) => condition.tagIds?.includes(c.value))}
            choices={tagChoices}
            withSearch
            searchPlaceholder="タグ名で検索"
            onChange={onChangeTagIds}
          />
        </Grid>
        <Grid item xs={1} alignContent={"center"}>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography color={"textSecondary"}>を</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <SelectSingleConditionDropdown
            key="inclusion"
            selectedChoice={
              inclusionChoices.find((choice) => choice.value === condition.inclusion) ??
              defaultInclusionChoice
            }
            choices={inclusionChoices}
            onChange={onChangeInclusion}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeTagIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        tagIds: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );
  const onChangeInclusion = useCallback(
    (newChoice: Choice<Inclusion>) => {
      onChangeCondition(index, {
        ...condition,
        inclusion: newChoice.value,
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeTagIds,
    onChangeInclusion,
  };
};

const useChoices = ({ allTags }: { allTags: EmployeeTag[] | undefined }) => {
  if (!allTags) {
    return {
      tagChoices: [],
    };
  }

  return {
    tagChoices: allTags.map((e) => ({ label: e.name, value: e.id })),
  };
};
