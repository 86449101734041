import { saveBusinessHoursSettingsSchema } from "@onn/common";
import { useCallback } from "react";

import { z } from "zod";

import { mutateBusinessHoursSettings } from "./useBusinessHoursSettings";

import { apiClient } from "~/libs";

export const useSaveBusinessHoursSetting = () => {
  const saveBusinessHoursSetting = useCallback(
    async (body: z.infer<typeof saveBusinessHoursSettingsSchema>["body"]) => {
      await apiClient.post("/api/business-hours-settings", body);
      await mutateBusinessHoursSettings();
    },
    []
  );

  return { saveBusinessHoursSetting };
};
