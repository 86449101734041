import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextFieldDeprecated } from "~/components/uiParts";

type Props = {
  onSave: ({ email }: { email: string }) => void;
  onCancel: () => void;
  data?: string;
};

export const EmailEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<{ email: string }>({
    defaultValues: {
      email: data,
    },
    mode: "onChange",
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, "メールアドレスを入力してください")
          .email({ message: "メールアドレスの形式を正しく入力してください" }),
      })
    ),
  });

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                defaultValue={data}
                placeholder="example@onn.co.jp"
                variant="outlined"
                fullWidth
                name={field.name}
                error={!!error}
                helperText={error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
