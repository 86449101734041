import { v4 } from "uuid";

import {
  IOnnEventEvaluationDraft,
  onnEventEvaluationDraftSchema,
  OnnEventEvaluationFileFieldValueDraft,
  OnnEventEvaluationMultipleSelectFieldValueDraft,
  OnnEventEvaluationSingleSelectFieldValueDraft,
  OnnEventEvaluationTextFieldValueDraft,
  OnnEventEvaluationValueDraft,
} from "./schema";

export class OnnEventEvaluationDraft implements IOnnEventEvaluationDraft {
  static validator = onnEventEvaluationDraftSchema;

  id: string;
  tenantId: string;

  onnEventId: string;
  employeeId: string;

  onnEventEvaluationRankId?: string;
  onnEventEvaluationValues: OnnEventEvaluationValueDraft[];
  updatedEmployeeId: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationDraft>) {
    const parsedInit = OnnEventEvaluationDraft.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;

    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;

    this.onnEventEvaluationRankId = parsedInit.onnEventEvaluationRankId;
    this.onnEventEvaluationValues = parsedInit.onnEventEvaluationValues;

    this.updatedEmployeeId = parsedInit.updatedEmployeeId;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<OnnEventEvaluationDraft>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluationDraft {
    return new OnnEventEvaluationDraft({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(params: {
    onnEventEvaluationRankId?: string | null; // NOTE: null is used to remove the rank
    onnEventEvaluationValues?: OnnEventEvaluationValueDraft[];
    updatedEmployeeId: string;
  }): OnnEventEvaluationDraft {
    const propsToUpdate: Partial<
      Pick<OnnEventEvaluationDraft, "onnEventEvaluationRankId" | "onnEventEvaluationValues">
    > = {};
    if (params.onnEventEvaluationRankId !== undefined) {
      propsToUpdate.onnEventEvaluationRankId = params.onnEventEvaluationRankId || undefined;
    }
    if (params.onnEventEvaluationValues !== undefined) {
      propsToUpdate.onnEventEvaluationValues = params.onnEventEvaluationValues;
    }
    return new OnnEventEvaluationDraft({
      ...this,
      ...propsToUpdate,
      updatedEmployeeId: params.updatedEmployeeId,
      updatedAt: new Date(),
    });
  }

  filterAuthorizedFields(authorizedFieldIds: string[]) {
    return new OnnEventEvaluationDraft({
      ...this,
      onnEventEvaluationValues: this.onnEventEvaluationValues.filter((value) =>
        authorizedFieldIds.includes(value.fieldId)
      ),
    });
  }

  findDraftValueByFieldId<T extends OnnEventEvaluationValueDraft["type"]>({
    fieldId,
  }: {
    fieldId: string;
  }): ReturnTypeFindDraftValueByFieldId<T> {
    const values = this.onnEventEvaluationValues.filter((draftValue) => {
      return draftValue.fieldId === fieldId;
    });
    const value = values[0];
    if (!value) return null;
    // NOTE: 複数選択の場合は、複数の値が存在するため
    if (value.type === "MultipleSelectField") {
      return values as ReturnTypeFindDraftValueByFieldId<T>;
    } else {
      return value as ReturnTypeFindDraftValueByFieldId<T>;
    }
  }
}

type ReturnTypeFindDraftValueByFieldId<T extends OnnEventEvaluationValueDraft["type"]> =
  | (T extends "TextField"
      ? OnnEventEvaluationTextFieldValueDraft
      : T extends "FileField"
      ? OnnEventEvaluationFileFieldValueDraft
      : T extends "SingleSelectField"
      ? OnnEventEvaluationSingleSelectFieldValueDraft
      : T extends "MultipleSelectField"
      ? OnnEventEvaluationMultipleSelectFieldValueDraft[]
      : never)
  | null;
