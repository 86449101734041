import { v4 } from "uuid";

import { TextField } from "../../types";

import { BaseOnnEventEvaluationField } from "../BaseOnnEventEvaluationField/BaseOnnEventEvaluationField";

import { IOnnEventEvaluationTextField, onnEventEvaluationTextFieldSchema } from "./schema";

export class OnnEventEvaluationTextField
  extends BaseOnnEventEvaluationField
  implements IOnnEventEvaluationTextField
{
  static validator = onnEventEvaluationTextFieldSchema;

  static readonly type = TextField;
  type = OnnEventEvaluationTextField.type;

  hasInputTemplate: boolean;
  inputTemplate: string;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationTextField>, "type">) {
    const parsedInit = OnnEventEvaluationTextField.validator.parse(init);

    super(parsedInit);

    this.type = OnnEventEvaluationTextField.type;
    this.hasInputTemplate = parsedInit.hasInputTemplate;
    this.inputTemplate = parsedInit.inputTemplate;
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationTextField>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationTextField {
    return new OnnEventEvaluationTextField({
      ...params,
      id: params.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationTextField>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new OnnEventEvaluationTextField({
      id: v4(),
      tenantId: params.tenantId,
      label: params.label,
      order: params.order,
      onnEventId: params.onnEventId,
      hasInputTemplate: params.hasInputTemplate,
      inputTemplate: params.inputTemplate,
      accessControlType: params.accessControlType,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationTextField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt"
      >
    >
  ): OnnEventEvaluationTextField {
    return new OnnEventEvaluationTextField({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
