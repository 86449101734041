import { zodResolver } from "@hookform/resolvers/zod";
import {
  AnyOnnEventEvaluationField,
  AnyOnnEventEvaluationValue,
  APISchema,
  OnnEventEvaluationDraft,
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationRankV2,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import { UseFormReturn, useForm as useRHF } from "react-hook-form";

import { mutate } from "swr";

import { FieldValues } from "./FieldValues";
import { generateFormSchema } from "./schema";

import { useAutoSaveDraft } from "./useAutoSaveDraft";
import { useGenerateDefaultValues } from "./useGenerateDefaultValues";

import { useUpdateDraftOnnEventEvaluationFromFieldValues } from "./useUpdateDraftOnnEventEvaluationFromFieldValues";

import { mutateNewGraduateNextPlan } from "~/hooks/employee/useNewGraduateNextPlan";
import { useSubmitOnnEventEvaluation } from "~/hooks/onnEventEvaluation/useSubmitOnnEventEvaluation";
import { useUpdateOnnEventEvaluation } from "~/hooks/onnEventEvaluation/useUpdateOnnEventEvaluation";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type UpdateEndpoint = APISchema["/api/onn-event-evaluations"]["PATCH"];

export const useForm = ({
  selectableOnnEventEvaluationRanks,
  onnEventEvaluationFields,
  registeredOnnEventEvaluation,
  onnEventId,
  newGraduateId,
  onnEventEvaluationDraft,
  onCancel,
}: {
  selectableOnnEventEvaluationRanks: OnnEventEvaluationRankV2[];
  onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  registeredOnnEventEvaluation?: {
    onnEventEvaluationRankId?: string; // NOTE: 古い評価データはrankIdがない場合があるため、optional
    onnEventEvaluationValues: AnyOnnEventEvaluationValue[];
  };
  onnEventEvaluationDraft?: OnnEventEvaluationDraft;
  onnEventId: string;
  newGraduateId: string;
  onSave?: () => void;
  onCancel: () => void;
}) => {
  const { generateDefaultValuesWhenAlreadyEvaluated, generateDefaultValuesWhenNotEvaluated } =
    useGenerateDefaultValues();

  const form = useRHF<FieldValues>({
    defaultValues: registeredOnnEventEvaluation
      ? generateDefaultValuesWhenAlreadyEvaluated({
          onnEventEvaluationFields,
          registeredOnnEventEvaluationValues: registeredOnnEventEvaluation.onnEventEvaluationValues,
          onnEventEvaluationRankId: registeredOnnEventEvaluation.onnEventEvaluationRankId,
        })
      : generateDefaultValuesWhenNotEvaluated({
          onnEventEvaluationFields,
          onnEventEvaluationDraft,
        }),
    resolver: zodResolver(
      generateFormSchema({
        selectableOnnEventEvaluationRanks,
      })
    ),
    mode: "all",
  });

  const { dirtyFields, isSubmitting, isValid } = form.formState;

  const { updateDraftDebounce, isUpdatingDraft } = useAutoSaveDraft({
    form,
    mode: registeredOnnEventEvaluation ? "edit" : "new",
    onnEventId,
    targetEmployeeId: newGraduateId,
  });

  const { submit } = useSubmit({
    form,
    dirtyFields,
    onnEventId,
    newGraduateId,
    onCancel,
    mode: registeredOnnEventEvaluation ? "edit" : "new",
  });

  return {
    RHForm: form,
    submit,
    updateDraftDebounce,
    isUpdatingDraft,
    isSubmitting,
    isValid,
  };
};

const useSubmit = ({
  form,
  dirtyFields,
  onnEventId,
  newGraduateId,
  onSave,
  onCancel,
  mode,
}: {
  form: UseFormReturn<FieldValues>;
  dirtyFields: UseFormReturn<FieldValues>["formState"]["dirtyFields"];
  onnEventId: string;
  newGraduateId: string;
  onSave?: () => void;
  onCancel: () => void;
  mode: "new" | "edit";
}) => {
  const { updateOnnEventEvaluation } = useUpdateOnnEventEvaluation();
  const { submitOnnEventEvaluation } = useSubmitOnnEventEvaluation();
  const { enqueueSnackbar } = useSnackbar();
  const { updateDraftOnnEventEvaluationFromFieldValues } =
    useUpdateDraftOnnEventEvaluationFromFieldValues({
      onnEventId,
      targetEmployeeId: newGraduateId,
    });

  const submit = form.handleSubmit(async (formValues) => {
    try {
      if (mode === "new") {
        // NOTE: 新規評価の場合はdraftの内容をそのまま評価に反映させているため、draftの内容を更新してから評価を提出する
        await updateDraftOnnEventEvaluationFromFieldValues(formValues);
        await submitOnnEventEvaluation({
          onnEventId,
          targetEmployeeId: newGraduateId,
        });
      } else {
        const onnEventEvaluationValues: UpdateEndpoint["body"] = convertRHFInputValueToEndpointBody(
          {
            dirtyFields,
            inputValue: formValues,
            onnEventId,
            newGraduateId,
            mode,
          }
        );
        await updateOnnEventEvaluation(onnEventEvaluationValues);
      }
      enqueueSnackbar(`イベント評価の${mode === "new" ? "提出" : "更新"}をしました。`, {
        variant: "success",
      });
      mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduateId));
      // NOTE: 次の予定はイベントの評価状況によって表示内容が変わるため、このタイミングで再取得する
      mutateNewGraduateNextPlan({ newGraduateId });
      onSave && onSave();
      onCancel();
    } catch (e) {
      enqueueSnackbar("エラーが発生しました", { variant: "error" });
      captureException({
        error: e as Error,
        extras: {
          formValues,
        },
        tags: {
          type: "候補者評価の編集",
        },
      });
    }
  });

  return { submit };
};

const convertRHFInputValueToEndpointBody = ({
  dirtyFields,
  inputValue,
  onnEventId,
  newGraduateId,
  mode,
}: {
  dirtyFields: UseFormReturn<FieldValues>["formState"]["dirtyFields"];
  inputValue: FieldValues;
  onnEventId: string;
  newGraduateId: string;
  mode: "new" | "edit";
}) => {
  const onnEventEvaluationValues: UpdateEndpoint["body"]["onnEventEvaluationValues"] = [];
  inputValue.onnEventEvaluationValues.forEach((value, index) => {
    // NOTE: 未変更のフィールドは更新対象に含めない（不要にonnEventEvaluationXxxValueのupdatedEmployeeを更新しないように）
    const isDirtyField = !!dirtyFields.onnEventEvaluationValues?.[index];
    const shouldUpdate = mode === "edit" ? isDirtyField : undefined;

    switch (value.type) {
      case "TextField": {
        onnEventEvaluationValues.push({
          type: OnnEventEvaluationTextField.type,
          fieldId: value.fieldId,
          value: value.value,
          shouldUpdate,
        });
        break;
      }
      case "FileField": {
        onnEventEvaluationValues.push({
          type: OnnEventEvaluationFileField.type,
          fieldId: value.fieldId,
          filePaths: value.filePaths,
          shouldUpdate,
        });
        break;
      }
      case "SingleSelectField": {
        onnEventEvaluationValues.push({
          type: OnnEventEvaluationSingleSelectField.type,
          fieldId: value.fieldId,
          onnEventEvaluationSingleSelectOptionId: value.selectedOptionId,
          shouldUpdate,
        });
        break;
      }
      case "MultipleSelectField": {
        onnEventEvaluationValues.push({
          type: OnnEventEvaluationMultipleSelectField.type,
          fieldId: value.fieldId,
          onnEventEvaluationMultipleSelectOptionIds: value.selectedOptionIds,
          shouldUpdate,
        });
        break;
      }
      default: {
        const _exhaustiveCheck: never = value;
        return _exhaustiveCheck;
      }
    }
  });

  return {
    onnEventId,
    targetEmployeeId: newGraduateId,
    onnEventEvaluationRankId: inputValue.onnEventEvaluationRankId,
    onnEventEvaluationValues: onnEventEvaluationValues,
  };
};
