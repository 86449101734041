import { Box, Grid } from "@material-ui/core";

import {
  CheckBoxQuestion,
  FormRevision,
  hasOptions,
  OnnFormTask,
  RadioQuestion,
} from "@onn/common";
import React, { useMemo } from "react";

import { OnnTaskCondition } from "../../../types/condition";
import { ClosableContainer } from "../../parts/ClosableContainer";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";
import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Choice } from "~/components/types/choice";
import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useFormRevisions, useOnnTasks } from "~/hooks/onnTask";

const TARGET = "onnTask";

type Props = CommonProps<OnnTaskCondition>;

export const OnnTaskConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { data: onnTasks = [], isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
  });
  const { data: formRevisionsData, isLoading: isLoadingFormRevision } = useFormRevisions({
    onnFormTaskId: condition.taskId,
    isOnlyLatest: true,
  });
  const formRevisions = formRevisionsData?.formRevisions ?? [];
  const formRevision: FormRevision | undefined = formRevisions[0]; // 正常なら undefined にはならない

  // タスク指定
  type TaskChoice = Choice<OnnFormTask["id"]>;
  const onnTaskChoices = useMemo(
    () =>
      onnTasks.map<TaskChoice>((task) => ({
        label: task.title,
        value: task.id,
      })),
    [onnTasks]
  );
  const selectedTaskChoice = onnTaskChoices.find((choice) => choice.value === condition.taskId);
  const onChangeTask = (task: TaskChoice) => {
    onChangeCondition(index, {
      ...condition,
      taskId: task.value,
      questionAnswer: undefined,
    });
  };

  // 配信・回答ステータス指定
  type AnswerStatusChoice = Choice<OnnTaskCondition["answerStatuses"][number]>;
  const answerStatusChoices: AnswerStatusChoice[] = [
    { label: "未配信", value: "not_delivered" },
    { label: "未回答", value: "before_answer" },
    { label: "回答済み", value: "after_answer" },
  ] as const;
  const selectedAnswerStatusChoices = answerStatusChoices.filter((choice) => {
    return condition.answerStatuses?.includes(choice.value);
  });
  const onChangeAnswerStatuses = (answerStatuses: AnswerStatusChoice[]) => {
    onChangeCondition(index, {
      ...condition,
      answerStatuses: answerStatuses.map((choice) => choice.value),
    });
  };

  // 設問指定 (選択式の設問のみ)
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = React.useState<
    OnnTaskCondition["questionAnswer"]
  >(condition.questionAnswer);
  type QuestionChoiceWithType = Choice<string> & { type: "RADIO" | "CHECK_BOX" };
  const optionQuestions: (RadioQuestion | CheckBoxQuestion)[] =
    formRevision?.questions.filter((q) => hasOptions(q)) ?? [];
  const questionChoices: QuestionChoiceWithType[] = optionQuestions.map((q) => ({
    label: q.title,
    value: q.id,
    type: q.type,
  }));
  type QuestionAnswerChoice = Choice<string>;
  const selectedQuestionId = selectedQuestionAnswer?.questionId;
  const selectedQuestionChoice = questionChoices.find((q) => q.value === selectedQuestionId);
  const onChangeQuestionChoice = (question: QuestionChoiceWithType) => {
    setSelectedQuestionAnswer({
      questionId: question.value,
      questionType: question.type,
      answerIds: [], // 設問指定を変更時には回答指定をリセットする
    });
    onChangeCondition(index, {
      ...condition,
      questionAnswer: undefined, // answerIds が空なので condition に questionAnswer は入れない
    });
  };

  // 回答指定
  const questionAnswerChoices: QuestionAnswerChoice[] | undefined = selectedQuestionId
    ? optionQuestions
        .find((q) => q.id === selectedQuestionId)
        ?.options.map((option) => ({ label: option.text, value: option.id }))
    : undefined;
  const selectedQuestionAnswerIds = selectedQuestionAnswer?.answerIds ?? [];
  const selectedQuestionAnswerChoices = questionAnswerChoices?.filter((choice) =>
    selectedQuestionAnswerIds.includes(choice.value)
  );
  const onChangeQuestionAnswerChoices = (questionAnswers: QuestionAnswerChoice[]) => {
    // 設問が指定されていない状態で回答を指定することはない
    if (!selectedQuestionAnswer) {
      return;
    }
    const newAnswerIds = questionAnswers.map((choice) => choice.value);
    setSelectedQuestionAnswer({
      ...selectedQuestionAnswer,
      answerIds: newAnswerIds,
    });
    const questionAnswer =
      newAnswerIds.length > 0
        ? {
            ...selectedQuestionAnswer,
            answerIds: newAnswerIds,
          }
        : undefined;
    onChangeCondition(index, {
      ...condition,
      questionAnswer,
    });
  };

  // 詳細条件（設問&回答指定）の表示条件
  const shouldShowQuestionAnswerRow = optionQuestions.length > 0;
  const shouldOpenQuestionAnswerSelector = !!condition.questionAnswer;

  if (isLoadingOnnTasks) {
    return <Loading size="small" />;
  }
  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target, employeeInformationId) =>
              onChangeTarget(index, target, employeeInformationId)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSingleConditionDropdown
            placeHolder="タスクを選択"
            key={"event"}
            selectedChoice={selectedTaskChoice}
            choices={onnTaskChoices}
            onChange={onChangeTask}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="ステータスを選択"
            key={"answerStatuses"}
            selectedChoices={selectedAnswerStatusChoices}
            choices={answerStatusChoices}
            onChange={onChangeAnswerStatuses}
          />
        </Grid>
      </Grid>
      {shouldShowQuestionAnswerRow && (
        <Box width={"100%"} mt="8px">
          <ClosableContainer label="詳細条件" defaultShown={shouldOpenQuestionAnswerSelector}>
            <Box width="100%" mb="8px">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <SelectSingleConditionDropdown
                    placeHolder="設問を選択"
                    key={"onnTaskQuestion"}
                    selectedChoice={selectedQuestionChoice}
                    choices={questionChoices}
                    onChange={onChangeQuestionChoice}
                    isLoading={isLoadingFormRevision}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectMultipleConditionDropdown
                    placeHolder="回答を選択"
                    key={"onnTaskQuestionAnswer"}
                    selectedChoices={selectedQuestionAnswerChoices}
                    choices={questionAnswerChoices ?? []}
                    onChange={onChangeQuestionAnswerChoices}
                    isLoading={isLoadingFormRevision}
                  />
                </Grid>
              </Grid>
            </Box>
          </ClosableContainer>
        </Box>
      )}
    </Box>
  );
};
