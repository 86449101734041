import { v4 } from "uuid";

import { FileField } from "../../types";

import { BaseOnnEventEvaluationField } from "../BaseOnnEventEvaluationField/BaseOnnEventEvaluationField";

import { IOnnEventEvaluationFileField, onnEventEvaluationFileFieldSchema } from "./schema";

export class OnnEventEvaluationFileField
  extends BaseOnnEventEvaluationField
  implements IOnnEventEvaluationFileField
{
  static validator = onnEventEvaluationFileFieldSchema;

  static readonly type = FileField;
  type = OnnEventEvaluationFileField.type;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationFileField>, "type">) {
    const parsedInit = OnnEventEvaluationFileField.validator.parse(init);

    super(parsedInit);
    this.type = OnnEventEvaluationFileField.type;
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationFileField>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationFileField {
    return new OnnEventEvaluationFileField({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationFileField>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new OnnEventEvaluationFileField({
      id: v4(),
      tenantId: params.tenantId,
      label: params.label,
      order: params.order,
      onnEventId: params.onnEventId,
      accessControlType: params.accessControlType,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationFileField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt"
      >
    >
  ): OnnEventEvaluationFileField {
    return new OnnEventEvaluationFileField({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
