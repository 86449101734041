import { v4 } from "uuid";

import { Employee } from "../domain/Employee/Employee";
import { Role } from "../domain/Employee/Role";

import { LangType } from "../domain/shared/LangType";

const URL = "uploads/profile/onn-logo-dev.jpg";

export const createIMockEmployee = (mock: Partial<Employee> = {}): Employee => {
  return new Employee({
    id: mock.id || "uuid",
    tenantId: mock.tenantId || "1",
    spaceId: mock.spaceId || "space",
    email: mock.email ?? "sample@example.com",
    firstName: mock.firstName || "太郎",
    lastName: mock.lastName || "山田",
    invitedAt: mock.invitedAt,
    lastInvitedAt: mock.lastInvitedAt,
    invitationToken: mock.invitationToken || "token",
    accountCreateAt: mock.accountCreateAt || "2016-02-23",
    createdAt: mock.createdAt || new Date("2016-02-23"),
    joinAt: mock.joinAt === "" ? undefined : mock.joinAt || "2016-02-23",
    role: mock.role || Role.MEMBER,
    assignedAsNewcomer: mock.assignedAsNewcomer === undefined ? true : mock.assignedAsNewcomer,
    uid: mock.uid || undefined,
    lineUserId: mock.lineUserId,
    profileIconImageUrl:
      mock.profileIconImageUrl === "" ? undefined : mock.profileIconImageUrl || URL,
    mentorUserId: mock.mentorUserId || undefined,
    supportMemberEmployeeIds: mock.supportMemberEmployeeIds || [],
    deleted: mock.deleted ?? false,
    lastRefreshTime: mock.lastRefreshTime,
    isFollowedLineOfficialAccount: mock.isFollowedLineOfficialAccount ?? false,
    selectableAuthenticationFlowTypes: mock.selectableAuthenticationFlowTypes ?? ["line", "email"],
    selectedAuthenticationFlowType: mock.selectedAuthenticationFlowType ?? "email",
    currentAuthenticationType:
      mock.currentAuthenticationType === undefined ? undefined : mock.currentAuthenticationType,
    isNewGraduate: mock.isNewGraduate,
    lang: mock.lang ?? LangType.JA,
    uniqueId: mock.uniqueId,
  });
};

export const createIMockEmployees = ({
  commonValue,
  values,
}: {
  commonValue: Partial<Employee>;
  values: Partial<Employee>[];
}): Employee[] => {
  return values.map((value) =>
    createIMockEmployee({
      id: v4(),
      ...commonValue,
      ...value,
    })
  );
};

export const createIMockAdmin = (mock: Partial<Employee> = {}): Employee =>
  createIMockEmployee({
    role: Role.ADMIN,
    assignedAsNewcomer: false,
    isNewGraduate: false,
    ...mock,
  });

export const createIMockOnlyInterviewer = (mock: Partial<Employee> = {}): Employee =>
  createIMockEmployee({
    role: Role.ONLY_INTERVIEWER,
    assignedAsNewcomer: false,
    isNewGraduate: false,
    ...mock,
  });
