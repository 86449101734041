import { Box, Drawer } from "@material-ui/core";
import { Stack } from "@mui/material";
import {
  AnyOnnEventEvaluationField,
  AnyOnnEventEvaluationValue,
  Employee,
  OnnEvent,
  OnnEventEvaluationDraft,
  OnnEventEvaluationRankV2,
} from "@onn/common";
import React, { FC, useCallback, useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import { EditOnnEventEvaluationFooterS } from "../EditOnnEventEvaluationFooter/EditOnnEventEvaluationFooterS";

import { DraftSavingStatus } from "./components/DraftSavingStatus";

import { EditOnnEventEvaluationDrawerContext } from "./providers/EditOnnEventEvaluationDrawerProvider";

import { EditOnnEventEvaluationContent } from "~/components/domains/events/EditOnnEventEvaluation/EditOnnEventEvaluationContent/EditOnnEventEvaluationContent";
import { useForm } from "~/components/domains/events/EditOnnEventEvaluation/hooks/form/useForm";
import { IconButton, Loading, Typography } from "~/components/uiParts";
import { useBlurBackdrop } from "~/components/uiParts/Modal/Modal";
import { useGetExecutableChangeRecruitmentStatusActionSettings } from "~/hooks/actionSetting/useGetExecutableChangeRecruitmentStatusActionSettings";
import { useEmployee } from "~/hooks/employee";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventEvaluation } from "~/hooks/onnEventEvaluation/useOnnEventEvaluation";
import { useOnnEventEvaluationDraft } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationDraft";
import { useOnnEventEvaluationSetting } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationSetting";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { NotFound } from "~/pages/NotFound";

export const EditOnnEventEvaluationDrawer = () => {
  const { isOpen, onnEventId, newGraduateId, cancel } = useContext(
    EditOnnEventEvaluationDrawerContext
  );

  if (!isOpen || !onnEventId || !newGraduateId) return null;

  return (
    <CoreWrapper
      newGraduateId={newGraduateId}
      onnEventId={onnEventId}
      isOpen={isOpen}
      handleCancel={cancel}
    />
  );
};

const CoreWrapper = ({
  newGraduateId,
  onnEventId,
  isOpen,
  handleCancel,
}: {
  newGraduateId: string;
  onnEventId: string;
  isOpen: boolean;
  handleCancel: () => void;
}) => {
  const { data: onnEventEvaluationSetting, isLoading: isLoadingOnnEventEvaluationSetting } =
    useOnnEventEvaluationSetting({ onnEventId });
  const { data: onnEventEvaluationData, isLoading: isLoadingOnnEventEvaluation } =
    useOnnEventEvaluation({ onnEventId, targetEmployeeId: newGraduateId });
  const { data: onnEventEvaluationDraft, isLoading: isLoadingOnnEventEvaluationDraft } =
    useOnnEventEvaluationDraft({ onnEventId, targetEmployeeId: newGraduateId });
  const { data: onnEventData, isLoading: isLoadingOnnEvent } = useOnnEvent(onnEventId);
  const { data: employee, isLoading: isLoadingEmployee } = useEmployee(newGraduateId);

  const isLoading =
    isLoadingOnnEventEvaluationSetting ||
    isLoadingOnnEventEvaluation ||
    isLoadingOnnEventEvaluationDraft ||
    isLoadingOnnEvent ||
    isLoadingEmployee;
  const onnEvent = onnEventData?.onnEvent;

  if (isLoading) {
    return <Loading size="large" />;
  }
  if (!onnEvent || !employee) {
    return <NotFound />;
  }

  return (
    <Core
      newGraduate={employee}
      onnEvent={onnEvent}
      isOpen={isOpen}
      handleCancel={handleCancel}
      onnEventEvaluationSetting={onnEventEvaluationSetting}
      onnEventEvaluationData={onnEventEvaluationData}
      onnEventEvaluationDraft={onnEventEvaluationDraft ?? undefined}
    />
  );
};

const Core = ({
  newGraduate,
  onnEvent,
  isOpen,
  handleCancel,
  onnEventEvaluationData,
  onnEventEvaluationSetting,
  onnEventEvaluationDraft,
}: {
  newGraduate: Employee;
  onnEvent: OnnEvent;
  isOpen: boolean;
  handleCancel: () => void;
  onnEventEvaluationSetting?: {
    onnEventEvaluationRanks: OnnEventEvaluationRankV2[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  onnEventEvaluationData?: {
    onnEventEvaluationRank?: OnnEventEvaluationRankV2;
    onnEventEvaluationFields: AnyOnnEventEvaluationValue[];
  };
  onnEventEvaluationDraft?: OnnEventEvaluationDraft;
}) => {
  const { RHForm, submit, updateDraftDebounce, isUpdatingDraft, isSubmitting, isValid } = useForm({
    selectableOnnEventEvaluationRanks: onnEventEvaluationSetting?.onnEventEvaluationRanks ?? [],
    onnEventEvaluationFields: onnEventEvaluationSetting?.onnEventEvaluationFields ?? [],
    registeredOnnEventEvaluation: onnEventEvaluationData?.onnEventEvaluationRank // NOTE: onnEventEvaluationRankの有無で評価データが存在するかどうかを判断
      ? {
          onnEventEvaluationRankId: onnEventEvaluationData.onnEventEvaluationRank?.id,
          onnEventEvaluationValues: onnEventEvaluationData.onnEventEvaluationFields ?? [],
        }
      : undefined,
    onnEventId: onnEvent.id,
    newGraduateId: newGraduate.id,
    onnEventEvaluationDraft: onnEventEvaluationDraft ?? undefined,
    onCancel: handleCancel,
  });
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const classes = useBlurBackdrop();

  const {
    data: executableChangeRecruitmentStatusActionSettings,
    isValidating: isExecutableChangeRecruitmentStatusActionSettingsValidating,
  } = useGetExecutableChangeRecruitmentStatusActionSettings({
    newGraduateId: newGraduate.id,
    onnEventEvaluationRankId: RHForm.watch("onnEventEvaluationRankId"),
    onnEventId: onnEvent.id,
  });

  const onClickSubmit = useCallback(async () => {
    await submit();
  }, [submit]);
  const onClickClose = useCallback(async () => {
    updateDraftDebounce.flush();
    mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
    handleCancel();
  }, [handleCancel, newGraduate.id, updateDraftDebounce]);

  return (
    <FormProvider {...RHForm}>
      <Drawer
        anchor="right"
        open={isOpen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <Stack width={"80vw"} height={"100%"}>
          {/* Header */}
          <DrawerHeader
            onnEvent={onnEvent}
            newGraduate={newGraduate}
            isSavingDraft={isUpdatingDraft}
            onClickClose={onClickClose}
          />

          {/* Content */}
          <Box padding="16px 24px 0" flex="1">
            <EditOnnEventEvaluationContent
              onnEventEvaluationSetting={{
                onnEventEvaluationRanks: onnEventEvaluationSetting?.onnEventEvaluationRanks ?? [],
                onnEventEvaluationFields: onnEventEvaluationSetting?.onnEventEvaluationFields ?? [],
              }}
              isUploadingFile={isUploadingFile}
              setIsUploadingFile={setIsUploadingFile}
              sumHeightOfHeaderAndFooter={130} // 大体、header height + footer height (スクロールしてもフッターとヘッダーがずれない程度に調整)
            />
          </Box>

          {/* Footer */}
          <EditOnnEventEvaluationFooterS
            onClickSave={onClickSubmit}
            isLoading={isSubmitting}
            isDisabled={isUploadingFile || isSubmitting || !isValid}
            executableChangeRecruitmentStatusActionSettings={
              executableChangeRecruitmentStatusActionSettings ?? []
            }
            isExecutableChangeRecruitmentStatusActionSettingsValidating={
              isExecutableChangeRecruitmentStatusActionSettingsValidating
            }
          />
        </Stack>
      </Drawer>
    </FormProvider>
  );
};

const DrawerHeader: FC<{
  onnEvent: OnnEvent;
  newGraduate: Employee;
  isSavingDraft: boolean | undefined;
  onClickClose: () => void;
}> = ({ onnEvent, newGraduate, isSavingDraft, onClickClose }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding="16px 16px 0">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        columnGap={"8px"}
        flexGrow={1}
      >
        <Stack flexDirection="row" alignItems="end" columnGap={"8px"}>
          <Typography variant="body1" color="textPrimary" bold>
            {onnEvent.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {newGraduate.getName()}さん
          </Typography>
        </Stack>
        <DraftSavingStatus isSavingDraft={isSavingDraft} />
      </Stack>
      <StyledIconButtonForClose size="sm" icon="close" onClick={onClickClose} />
    </Box>
  );
};

const StyledIconButtonForClose = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;
