import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Stack } from "@mui/material";
import React, { ChangeEvent, ComponentProps, useState } from "react";

import styled from "styled-components";

import { ScenarioConditionTab } from "./parts/ScenarioConditionTab";

import { Typography } from "~/components/uiParts";

type TabValue = "scenario_condition";
const tabs: Array<{ label: string; value: TabValue }> = [
  // TODO[カスタムCSV高度化]: 順序設定できるようにする
  // {
  //   label: "出力順序",
  //   value: "order",
  // },
  {
    label: "出力条件",
    value: "scenario_condition",
  },
];

export const SettingStepContent = (
  props: ComponentProps<typeof ScenarioConditionTab>
): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabValue>("scenario_condition");

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    setActiveTab(newValue);
  };

  return (
    // Modalのスクロールバーに重なるため、右側にpaddingを追加して調整している
    <Stack rowGap="24px" pr="8px">
      <Typography align="center">CSVファイルに書き出す条件設定してください。</Typography>
      {/* TODO[カスタムCSV高度化]: 順序設定できるようにする */}
      {/* <Typography align="center">
        CSVファイルに書き出す項目の順序と条件設定してください。
      </Typography> */}

      <Box>
        <TabContext value={activeTab}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <StyledTabList
              onChange={handleChangeActiveTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
              {tabs.map((tab) => (
                <StyledTab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </StyledTabList>
          </Box>

          {/* TODO[カスタムCSV高度化]: 順序設定できるようにする */}
          {/* <StyledTabPanel value="order"></StyledTabPanel> */}

          <StyledTabPanel value="scenario_condition">
            <ScenarioConditionTab {...props} />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Stack>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    padding: 0 16px;
    height: 54px;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 16px 24px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 8px 0;
  }
`;
