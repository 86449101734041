import { z } from "zod";

import { OnnEventEvaluationFileValueSchema } from "../../../../../_gen/zodSchema/index";

export const onnEventEvaluationFileValueSchema = OnnEventEvaluationFileValueSchema.merge(
  z.object({
    createdEmployeeId: z.string().optional(),
    updatedEmployeeId: z.string().optional(),
  })
);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationFileValue
  extends z.infer<typeof onnEventEvaluationFileValueSchema> {}
