import { OnnEvent } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";

import { useModal } from "~/hooks/modal";

import {
  useOnnEventAnswersWithEmployee,
  useRemoveFromDeliveredNewGraduates,
} from "~/hooks/onnEvent";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { useSnackbar } from "~/hooks/shared";

export type Filter = "answered" | "noAnswered";

export const useTabPage = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { removeFromDeliveredNewGraduates, isLoading: isLoadingRemoveFromDeliveredNewGraduates } =
    useRemoveFromDeliveredNewGraduates();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const {
    data: onnEventAnswersWithEmployee,
    isLoading: isLoadingOnnEventAnswersWithEmployee,
    mutateOnnEventAnswers,
  } = useOnnEventAnswersWithEmployee({
    onnEventId: onnEvent.id,
  });

  const [selectedFilterIsAnswered, setSelectedFilterIsAnswered] = useState<Filter>("answered");
  // 回答済フィルターボタンクリック
  const onClickFilterButtonAnswered = () => setSelectedFilterIsAnswered("answered");
  // 未回答フィルターボタンクリック
  const onClickFilterButtonNoAnswered = () => setSelectedFilterIsAnswered("noAnswered");

  const onClickDeliverySetting = useCallback(() => {
    navigation(`/events/${onnEvent.id}/delivery_setting?from_page=detail`);
  }, [navigation, onnEvent.id]);

  const onSubmitRemove = useCallback(
    async (onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee) => {
      withBusinessHours(async (isForce) => {
        const res = await removeFromDeliveredNewGraduates({
          onnEventAnswer: onnEventAnswerWithEmployee,
          forceNotifyImmediately: isForce,
        }).catch(async (e) => {
          enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
            variant: "error",
          });
          await mutateOnnEventAnswers();
          throw e;
        });

        await mutateOnnEventAnswers();

        if (res.isNotified) {
          // NOTE: システムの応答はシンプルに保つため、一旦、削除対象者の情報は表示しない
          // - 対応する場合は他の部分もまとめて議論
          enqueueSnackbar("削除が完了しました。削除された旨を伝える通知が送信されます。", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("削除が完了しました。", { variant: "success" });
        }
      });
    },
    [withBusinessHours, removeFromDeliveredNewGraduates, mutateOnnEventAnswers, enqueueSnackbar]
  );

  const onClickOpenRemoveModal = useCallback(
    (onnEvent: OnnEvent, onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee) => {
      handleModal({
        name: "removeEmployeeFromOnnEventCandidateListModal",
        args: {
          onSubmit: onSubmitRemove,
          onnEvent,
          onnEventAnswerWithEmployee,
        },
      });
    },
    [handleModal, onSubmitRemove]
  );

  const answeredOnnEventAnswersWithEmployee = useMemo(() => {
    return (onnEventAnswersWithEmployee || []).filter((v) => v.isAnswered());
  }, [onnEventAnswersWithEmployee]);

  const noAnsweredOnnEventAnswersWithEmployee = useMemo(() => {
    return (onnEventAnswersWithEmployee || []).filter((v) => !v.isAnswered());
  }, [onnEventAnswersWithEmployee]);

  const onnEventAnswersToDisplay =
    selectedFilterIsAnswered === "answered"
      ? answeredOnnEventAnswersWithEmployee
      : noAnsweredOnnEventAnswersWithEmployee;

  return {
    onnEventAnswersWithEmployee,
    answeredOnnEventAnswersWithEmployee,
    noAnsweredOnnEventAnswersWithEmployee,
    isLoadingOnnEventAnswersWithEmployee,
    isLoadingRemoveFromDeliveredNewGraduates,
    onClickDeliverySetting,
    onClickOpenRemoveModal,
    onSubmitRemove, // モーダルを介さずに削除する場合がある
    filter: {
      selectedFilterIsAnswered,
      onClickFilterButtonAnswered,
      onClickFilterButtonNoAnswered,
    },
    onnEventAnswersToDisplay,
  };
};
