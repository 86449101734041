export type FileType = "word" | "excel" | "powerPoint" | "pdf" | "image" | "zip" | "csv";

export const DEFAULT_MAX_LINE_FILE_SIZE_MB = 10;
export const DEFAULT_MAX_IMAGE_FILE_SIZE_MB = 10;
export const DEFAULT_MAX_FILE_SIZE_MB = 30;
export const DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES: FileType[] = [
  "word",
  "excel",
  "powerPoint",
  "pdf",
  "image",
  "zip",
  "csv",
];

export const acceptValueForFileTypesRecord: Record<FileType, string[]> = {
  word: [".doc", ".docx"],
  excel: [".xls", ".xlsx"],
  powerPoint: [".ppt", ".pptx"],
  pdf: [".pdf"],
  image: [".gif", ".jpg", ".jpeg", ".png"],
  zip: ["application/gzip", ".zip"],
  csv: [".csv"],
};

export const fileTypeMatchers = {
  word: /.*\.(doc|docx)$/i,
  pdf: /.*\.(pdf)$/i,
  excel: /.*\.(xlsx|xls)$/i,
  powerPoint: /.*\.(pptx|ppt)$/i,
  image: /.*\.(gif|jpg|jpeg|png)$/i,
  zip: /.*\.(zip)$/i,
  csv: /.*\.(csv)$/i,
} as const satisfies Record<FileType, RegExp>;

export const FILE_PATH_NORMALIZATION_FORM = "NFC";
