import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";

type Props = {
  label: string;
  count?: number;
  isDisableCount?: boolean;
};

export const TitleWithCount: FC<Props> = ({ label, count, isDisableCount }) => {
  return (
    <Box pr={2} display="inline-flex">
      <Typography bold variant="h4" display="inline">
        {label}
      </Typography>
      {!isDisableCount && (
        <Tooltip title={`候補者数: ${count || 0}人`} placement="top">
          <>
            <Box width="8px" />
            <Box borderRadius={5} bgcolor="white" px={1}>
              <Typography variant="caption" bold>
                {count ?? "-"}
              </Typography>
            </Box>
          </>
        </Tooltip>
      )}
    </Box>
  );
};
