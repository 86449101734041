import { Box, List, Menu, MenuItem } from "@material-ui/core";
import { Employee, OnnFormTaskAnswer, OnnTask } from "@onn/common";
import React, { MouseEvent, memo, useCallback, useState } from "react";

import styled from "styled-components";

import { IconButton, TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";
import {
  AutoSizer,
  ListRowProps,
  List as VirtualizedList,
} from "~/components/uiParts/ReactVirtualized";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type ListItemProps = ListRowProps & { onClickRemoveMenu: () => void; employee: Employee };

const ListItem = memo(({ onClickRemoveMenu, employee, ...props }: ListItemProps) => {
  const { currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const shouldShowOptionBtn = currentUser.isAdmin() && !employee.deleted;

  return (
    <Box
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
      pb="16px"
    >
      <Box display="flex" justifyContent="space-between">
        <TooltipWhenTextTruncated text={employee.getName()}>
          {(ref) => (
            <Box width="248px" display="flex" alignItems="center" gridGap="12px">
              <UserIcon
                username={employee.getName()}
                profileIconImageUrl={employee.profileIconImageUrl}
                size="small"
                backgroundColor={employee.uid ? "primary" : "grey"}
              />
              <Typography variant="body1" color="textPrimary" bold noWrap ref={ref}>
                {employee.getName()}
              </Typography>
            </Box>
          )}
        </TooltipWhenTextTruncated>
        {shouldShowOptionBtn && (
          <Box width="40px">
            <IconButton icon="menuVert" onClick={handleOpenMenu} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "8px" }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  onClickRemoveMenu();
                }}
              >
                配信取消
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
});

const LIST_HEIGHT = 432;
const OVER_SCAN_ROW_COUNT = 10;
const ROW_HEIGHT = 56;

export const OnnFormTaskAnswersList = memo(
  ({
    onnTask,
    onnFromTaskAnswers,
    newGraduatesMap,
    deleteOnnFormTaskAnswer,
  }: {
    onnTask: OnnTask;
    onnFromTaskAnswers: OnnFormTaskAnswer[];
    newGraduatesMap: Map<string, Employee>;
    deleteOnnFormTaskAnswer: (onnFromTaskAnswer: OnnFormTaskAnswer) => Promise<void>;
  }) => {
    const { handleModal } = useModal();
    const handleClickRemoveMenu = useCallback(
      (onnFromTaskAnswer: OnnFormTaskAnswer, employee: Employee) => {
        // TODO：packages/front/src/hooks/onnFormTaskAnswer/useOnClickCancelDelivery.ts を使用する
        //  未回答の場合はモーダルで確認を取らずに削除する
        if (!onnFromTaskAnswer.isAnswered()) {
          deleteOnnFormTaskAnswer(onnFromTaskAnswer);
          return;
        }

        handleModal({
          name: "removeEmployeeFromOnnTaskAnswersModal",
          args: {
            onSubmit: () => deleteOnnFormTaskAnswer(onnFromTaskAnswer),
            taskTitle: onnTask.title,
            employeeName: employee.getName(),
          },
        });
      },
      [deleteOnnFormTaskAnswer, handleModal, onnTask.title]
    );

    return (
      <StyledList
        $height={
          onnFromTaskAnswers.length < LIST_HEIGHT / ROW_HEIGHT
            ? onnFromTaskAnswers.length * ROW_HEIGHT
            : LIST_HEIGHT
        }
      >
        {
          <AutoSizer
            disableHeight // 子要素の高さが固定長であるため高さを計算しない
          >
            {(size) => (
              <VirtualizedList
                height={
                  onnFromTaskAnswers.length < LIST_HEIGHT / ROW_HEIGHT
                    ? onnFromTaskAnswers.length * ROW_HEIGHT
                    : LIST_HEIGHT
                }
                width={size.width}
                overscanRowCount={OVER_SCAN_ROW_COUNT}
                rowCount={onnFromTaskAnswers.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={(props) => {
                  const onnFromTaskAnswer = onnFromTaskAnswers[
                    props.index
                  ] as (typeof onnFromTaskAnswers)[number];
                  const employee = newGraduatesMap.get(onnFromTaskAnswer.employeeId);
                  if (!employee) return null;

                  return (
                    <ListItem
                      {...props}
                      onClickRemoveMenu={() => handleClickRemoveMenu(onnFromTaskAnswer, employee)}
                      employee={employee}
                    />
                  );
                }}
              />
            )}
          </AutoSizer>
        }
      </StyledList>
    );
  }
);

const StyledList = styled(List)<{ $height: number }>`
  padding: 0px;
  height: ${(props) => props.$height}px;
  overflow: auto;
`;
