import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { FieldValues } from "./FieldValues";

import { useUpdateDraftOnnEventEvaluationFromFieldValues } from "./useUpdateDraftOnnEventEvaluationFromFieldValues";

/**
 * 下書きを自動保存するhooks
 */
export const useAutoSaveDraft = ({
  form,
  mode,
  onnEventId,
  targetEmployeeId,
}: {
  form: UseFormReturn<FieldValues>;
  mode: "new" | "edit";
  onnEventId: string;
  targetEmployeeId: string;
}) => {
  const { watch } = form;
  const { updateDraftOnnEventEvaluationFromFieldValues, isUpdatingDraft } =
    useUpdateDraftOnnEventEvaluationFromFieldValues({
      onnEventId,
      targetEmployeeId,
    });
  const updateDraftDebounce = useMemo(() => {
    return debounce((fieldValues: FieldValues) => {
      updateDraftOnnEventEvaluationFromFieldValues(fieldValues);
    }, 1000);
  }, [updateDraftOnnEventEvaluationFromFieldValues]);

  const [isFirstChanged, setIsFirstChanged] = useState(false);
  useEffect(() => {
    // NOTE: 評価編集の場合はすでにデータが存在するため、下書きの自動保存は行わない
    if (mode === "edit") return;
    const { unsubscribe } = watch((inputValue) => {
      // NOTE: なぜか初回レンダリング時にwatchが発火するので初回の変更は無視する
      if (!isFirstChanged) {
        setIsFirstChanged(true);
        return;
      }
      updateDraftDebounce(inputValue as FieldValues);
    });
    return () => unsubscribe();
  }, [isFirstChanged, mode, updateDraftDebounce, watch]);

  return { updateDraftDebounce, isUpdatingDraft };
};
