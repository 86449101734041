import { Menu, MenuItem } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";

import { IconButton, Loading, Typography } from "~/components/uiParts";
import {
  useEmployeeActiveLogsByType,
  useGenerateEmployeeIdToLatestEmployeeActiveLogMap,
} from "~/hooks/employeeActiveLog";
import { useModal } from "~/hooks/modal";
import {
  OnnEventAnswerWithEmployee,
  useDeleteOnnEvent,
  useOutputResultAnswerCsv,
} from "~/hooks/onnEvent";

type Props = {
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  onnEvent: OnnEvent;
  numberOfDistribution: number;
  onClickAssignButton: (onnEventId: string) => void;
};

export const OnnEventManageMenu: FC<Props> = ({
  onnEvent,
  numberOfDistribution,
  onnEventAnswers,
  onClickAssignButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigation = useNavigate();
  const { handleModal } = useModal();

  // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = (e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    callback();
  };

  const { data: employeeActiveLogs = [] } = useEmployeeActiveLogsByType({
    onnEventId: onnEvent.id,
    type: "VISITED_ONN_EVENT_LANDING_PAGE",
  });

  const { generateEmployeeIdToLatestEmployeeActiveLogMap } =
    useGenerateEmployeeIdToLatestEmployeeActiveLogMap();

  const employeeIdToLatestEmployeeActiveLogMap = useMemo(
    () => generateEmployeeIdToLatestEmployeeActiveLogMap({ employeeActiveLogs }),
    [employeeActiveLogs, generateEmployeeIdToLatestEmployeeActiveLogMap]
  );

  const { csvData, filename, isLoading } = useOutputResultAnswerCsv({
    onnEvent,
    onnEventAnswers,
    employeeIdToLatestEmployeeActiveLogMap,
  });
  const { execDeleteOnnEvent } = useDeleteOnnEvent();
  const wasDelivered = numberOfDistribution !== 0;
  const menuOptions = useMemo(() => {
    return [
      {
        id: "edit",
        title: "編集",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            navigation(`/events/${onnEvent.id}/edit/?from_page=list`);
          }),
      },
      // NOTE: 新面談タイプではイベントに担当者が存在しないため非表示
      onnEvent.isNewInterviewEvent()
        ? {
            id: "slot_default_value_setting",
            title: "共通設定",
            func: (e: MouseEvent<HTMLElement>) =>
              handleClickHF(e, () =>
                navigation(`/events/${onnEvent.id}/slot_default_value_setting/?from_page=list`)
              ),
          }
        : [],
      {
        id: "evaluation_setting",
        title: "評価フォーム設定",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () =>
            navigation(`/events/${onnEvent.id}/evaluation_setting/?from_page=list`)
          ),
      },
      // NOTE: 新面談タイプではイベントに担当者が存在しないため非表示
      onnEvent.isNewInterviewEvent()
        ? []
        : {
            id: "event_assignee",
            title: "担当者変更",
            func: (e: MouseEvent<HTMLElement>) =>
              handleClickHF(e, () => onClickAssignButton(onnEvent.id)),
          },
      wasDelivered
        ? {
            id: "delivery_setting",
            title: "配信設定",
            func: (e: MouseEvent<HTMLElement>) =>
              handleClickHF(e, () =>
                navigation(`/events/${onnEvent.id}/delivery_setting/?from_page=list`)
              ),
          }
        : [],
      wasDelivered
        ? {
            id: "csv_download",
            title: "CSVダウンロード",
            func: (e: MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              setAnchorEl(null);
            },
          }
        : [],
      // NOTE: 初回リリースでは実装しない
      // wasDelivered
      //   ? {
      //       title: "招待URLコピー",
      //       func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => 0),
      //     }
      //   : [],
      {
        id: "delete",
        title: "削除",
        func: (e: MouseEvent<HTMLElement>) => {
          handleClickHF(e, () => {
            handleModal({
              name: "eventDeleteConfirmModal",
              args: {
                eventTitle: onnEvent.title,
                onSubmit: async () => {
                  await execDeleteOnnEvent(onnEvent.id);
                },
              },
            });
          });
        },
      },
    ].flat();
  }, [onnEvent, wasDelivered, navigation, onClickAssignButton, handleModal, execDeleteOnnEvent]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => {
          if (option.id === "csv_download") {
            if (isLoading)
              return (
                <MenuItem key={option.id}>
                  <Loading size="small" fullHeight />
                </MenuItem>
              );
            return (
              <StyledCSVLink key={option.title} data={csvData} filename={filename}>
                <MenuItem onClick={option.func}>
                  <Typography variant="body2">{option.title}</Typography>
                </MenuItem>
              </StyledCSVLink>
            );
          }
          return (
            <MenuItem key={option.title} onClick={option.func}>
              <Typography variant="body2">{option.title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const StyledCSVLink = styled(EscapedCSVLink)`
  text-decoration: none;
`;
