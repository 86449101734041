import { Box } from "@material-ui/core";
import { ChangeRecruitmentStatusActionSetting } from "@onn/common";
import React, { FC, useState } from "react";

import { ConfirmChangeRecruitmentStatusCheckBox } from "./components/ConfirmChangeRecruitmentStatusCheckBox";

import { Button, Loading } from "~/components/uiParts";

export const EditOnnEventEvaluationFooterS: FC<{
  onClickSave: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  executableChangeRecruitmentStatusActionSettings: ExcludeMethods<ChangeRecruitmentStatusActionSetting>[];
  isExecutableChangeRecruitmentStatusActionSettingsValidating: boolean;
}> = (props) => {
  const [isExecuteChangeRecruitmentStatusConfirmed, setIsExecuteChangeRecruitmentStatusConfirmed] =
    useState<boolean>(false);
  const shouldDisplayChangeRecruitmentStatusConfirmationCheckbox =
    !!props.executableChangeRecruitmentStatusActionSettings?.length;

  return (
    <Box padding="16px">
      {shouldDisplayChangeRecruitmentStatusConfirmationCheckbox && (
        <Box width="100%" textAlign="center" mb="24px">
          {props.isExecutableChangeRecruitmentStatusActionSettingsValidating ? (
            <Loading size="small" />
          ) : (
            <ConfirmChangeRecruitmentStatusCheckBox
              executableChangeRecruitmentStatusActionSettings={
                props.executableChangeRecruitmentStatusActionSettings
              }
              isExecuteChangeRecruitmentStatusConfirmed={isExecuteChangeRecruitmentStatusConfirmed}
              setIsExecuteChangeRecruitmentStatusConfirmed={
                setIsExecuteChangeRecruitmentStatusConfirmed
              }
            />
          )}
        </Box>
      )}
      <Box width="100%" display="flex" justifyContent="end">
        <Button
          color="primary"
          borderRadius="regular"
          variant="contained"
          onClick={props.onClickSave}
          disabled={props.isDisabled}
        >
          提出
        </Button>
      </Box>
    </Box>
  );
};
