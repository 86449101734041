import { Employee, Failure, OnnEvent, Result, Success } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { onnEventActionTypes } from "../shared";

import {
  IUpdateDeliverySettingOfOnnEventRequest,
  updateDeliverySettingOfOnnEventRequestSchema,
} from "./schema";
export class UpdateDeliverySettingOfOnnEventRequest
  implements IUpdateDeliverySettingOfOnnEventRequest
{
  static readonly validator = updateDeliverySettingOfOnnEventRequestSchema;

  static actionType = onnEventActionTypes.updateDeliverySettingOfOnnEvent;
  onnEventId: OnnEvent["id"];
  newGraduatesIds?: Employee["id"][];
  deadlineDate?: Date;
  scheduledDate?: Date;
  canAnswerAfterDeadline?: boolean;
  forceNotifyImmediately?: boolean;

  constructor(init: UpdateDeliverySettingOfOnnEventRequest) {
    this.onnEventId = init.onnEventId;
    this.newGraduatesIds = init.newGraduatesIds;
    this.deadlineDate = init.deadlineDate;
    this.scheduledDate = init.scheduledDate;
    this.canAnswerAfterDeadline = init.canAnswerAfterDeadline;
    this.forceNotifyImmediately = init.forceNotifyImmediately;
  }

  public static createRequestBody({
    onnEventId,
    newGraduatesIds,
    deadlineDate,
    scheduledDate,
    canAnswerAfterDeadline,
    forceNotifyImmediately,
  }: {
    onnEventId: OnnEvent["id"];
    newGraduatesIds: Employee["id"][];
    deadlineDate: OnnEvent["deadlineDate"] | null;
    scheduledDate: OnnEvent["scheduledDate"] | null;
    canAnswerAfterDeadline: OnnEvent["canAnswerAfterDeadline"];
    forceNotifyImmediately?: boolean;
  }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      onnEventId,
      newGraduatesIds,
      deadlineDate,
      scheduledDate,
      canAnswerAfterDeadline,
      forceNotifyImmediately,
    });
  }

  public static validate(
    data: ToCloudFunctionsCompatible<UpdateDeliverySettingOfOnnEventRequest>
  ): Result<UpdateDeliverySettingOfOnnEventRequest, Error> {
    const requestData = UpdateDeliverySettingOfOnnEventRequest.convertUnixTimeToDate(data);
    const parseResult = UpdateDeliverySettingOfOnnEventRequest.validator.safeParse(requestData);
    if (!parseResult.success) {
      return new Failure(new Error(parseResult.error.message));
    }

    return new Success(new UpdateDeliverySettingOfOnnEventRequest(parseResult.data));
  }

  public static convertUnixTimeToDate(
    data: ToCloudFunctionsCompatible<UpdateDeliverySettingOfOnnEventRequest>
  ) {
    return {
      ...data,
      deadlineDate: data.deadlineDate ? new Date(data.deadlineDate) : undefined,
      scheduledDate: data.scheduledDate ? new Date(data.scheduledDate) : undefined,
    };
  }
}
