import { Box, DialogContent } from "@material-ui/core";
import { Employee, OnnEvent, OnnEventAnswer } from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { useWithBusinessHoursConfirmationModal } from "../../businessHours/BusinessHoursConfirmationModal";

import { Button, Modal, Typography } from "~/components/uiParts";
import {
  useOnnEventAnswersWithEmployee,
  useRemoveFromDeliveredNewGraduates,
} from "~/hooks/onnEvent";
import { mutateOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { useSnackbar } from "~/hooks/shared";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
  isAlreadyAnswered: boolean;
  employee: Employee;
  onnEventAnswer: OnnEventAnswer;
};

export const RemoveEmployeeFromOnnEventInterviewModal: FC<Props> = ({
  open,
  onCancel,
  onnEvent,
  isAlreadyAnswered,
  employee,
  onnEventAnswer,
}) => {
  const [sending, setSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { removeFromDeliveredNewGraduates } = useRemoveFromDeliveredNewGraduates();

  const { mutateOnnEventAnswers } = useOnnEventAnswersWithEmployee({
    onnEventId: onnEvent.id,
  });

  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const handleSubmit = useCallback(async () => {
    withBusinessHours(async (isForce) => {
      setSending(true);

      try {
        const res = await removeFromDeliveredNewGraduates({
          onnEventAnswer,
          forceNotifyImmediately: isForce,
        });

        if (res.isNotified) {
          enqueueSnackbar(
            "削除が完了しました。該当のメンバーには削除された旨を伝える通知が送信されています。",
            { variant: "success" }
          );
        } else {
          enqueueSnackbar("削除が完了しました。", { variant: "success" });
        }
        mutateOnnEventAnswers();
        mutateOnnEventAnswersForDisplay(onnEvent.id);
        onCancel();
      } catch (_e) {
        enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
          variant: "error",
        });
      } finally {
        setSending(false);
      }
    });
  }, [
    enqueueSnackbar,
    mutateOnnEventAnswers,
    onCancel,
    onnEvent.id,
    onnEventAnswer,
    removeFromDeliveredNewGraduates,
    withBusinessHours,
  ]);

  const Content = (
    <StyledDialogContent>
      <StyledBox>
        <StyledTypography display="block" variant="body1" color="textSecondary">
          {isAlreadyAnswered ? `${employee.getName()}さんはすでに回答を完了しています。\n` : ""}
          {`「${onnEvent.title}」の配信対象からを削除しますか？`}
        </StyledTypography>
        <Typography display="block" variant="caption" color="textSecondary">
          {`削除後、自動でイベントの対象から削除された旨が該当のメンバーに通知されます。\nこの操作は取り消すことができません。`}
        </Typography>
      </StyledBox>
    </StyledDialogContent>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        disabled={sending}
        onClick={handleSubmit}
      >
        削除
      </Button>
    </StyledButtonContainer>
  );

  return <Modal open={open} title="削除" content={Content} footer={Footer} onCancel={onCancel} />;
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    margin: 0 auto;
  }
`;

const StyledBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
  }
`;
