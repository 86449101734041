import { Employee, OnnFormTask, OnnTask } from "@onn/common";
import { orderBy } from "lodash";
import React, { FC } from "react";

import { AssigneeCell } from "./TableRowItems/AssigneeCell";
import { NumberOfDistributionCell } from "./TableRowItems/NumberOfDistributionCell";
import { NumberOfResponses } from "./TableRowItems/NumberOfResponses";
import { OnnTaskManageCell } from "./TableRowItems/OnnTaskManageCell/OnnTaskManageCell";
import { TitleCell } from "./TableRowItems/TitleCell";
import { ToDetailButtonCell } from "./TableRowItems/ToDetailButtonCell";

import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";

type Props = {
  onnTasks: OnnTask[];
  isLoading: boolean;
  currentUser: Employee;
  curriedOnClickZipDL: ({
    onnFormTaskId,
    onnFormTaskTitle,
    handleCloseMenu,
  }: {
    onnFormTaskId: string;
    onnFormTaskTitle: string;
    handleCloseMenu: () => void;
  }) => (e: React.MouseEvent<HTMLElement>) => Promise<void>;
};

export const OnnTaskTable: FC<Props> = ({
  onnTasks,
  isLoading,
  currentUser,
  curriedOnClickZipDL,
}) => {
  // 作成日時で新しい順に並び替え
  const sortedOnnTasks = orderBy(onnTasks, "createdAt", "desc");

  const widthOptions = currentUser.isAdmin()
    ? ["50%", "16%", "8%", "8%", "10%", "8%"]
    : ["68%", "16%", "8%", "8%"];
  return (
    <VirtualizedTableV2<OnnFormTask>
      rowHeight={90}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "担当者",
        },
        {
          text: "配信対象",
        },
        {
          text: "回答数",
        },
        currentUser.isAdmin()
          ? {
              text: "",
            }
          : [],
        currentUser.isAdmin()
          ? {
              text: "",
            }
          : [],
      ].flat()}
      rows={sortedOnnTasks}
      rowRenderer={({ key, index, style, rowData: task }) => {
        const contents = [
          <TitleCell
            key={task.id}
            title={task.title}
            scheduledDate={task.scheduledDate}
            deadlineDate={task.deadlineDate}
          />,
          <AssigneeCell key={task.id} assigneeId={task.assigneeId} />,
          <NumberOfDistributionCell key={task.id} onnTaskId={task.id} />,
          <NumberOfResponses key={task.id} onnTaskId={task.id} />,
          currentUser.isAdmin() ? <ToDetailButtonCell key={task.id} onnTask={task} /> : [],
          currentUser.isAdmin() ? (
            <OnnTaskManageCell
              key={task.id}
              taskId={task.id}
              assigneeId={task.assigneeId}
              taskName={task.title}
              tenantId={task.tenantId}
              isExceededScheduledDate={task.isExceededScheduledDate()}
              isExceededDeadlineDate={task.isExceededDeadlineDate()}
              curriedOnClickZipDL={curriedOnClickZipDL}
            />
          ) : (
            []
          ),
        ].flat();

        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/onn_tasks/${task.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};
