import { v4 } from "uuid";

import { TextField } from "../../types";

import { IOnnEventEvaluationTextValue, onnEventEvaluationTextValueSchema } from "./schema";

export class OnnEventEvaluationTextValue implements IOnnEventEvaluationTextValue {
  static validator = onnEventEvaluationTextValueSchema;

  type = TextField;
  id: string;
  tenantId: string;
  onnEventEvaluationTextFieldId: string;
  value: string;
  employeeId: string;
  createdEmployeeId?: string;
  updatedEmployeeId?: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationTextValue>, "type">) {
    const parsedInit = OnnEventEvaluationTextValue.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationTextFieldId = parsedInit.onnEventEvaluationTextFieldId;
    this.value = parsedInit.value;
    this.employeeId = parsedInit.employeeId;
    this.createdEmployeeId = parsedInit.createdEmployeeId;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドバリューデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationTextValue>, "createdAt" | "updatedAt" | "type">
  ): OnnEventEvaluationTextValue {
    return new OnnEventEvaluationTextValue({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationTextValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): OnnEventEvaluationTextValue {
    return new OnnEventEvaluationTextValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<OnnEventEvaluationTextValue, "value" | "updatedEmployeeId">
  ): OnnEventEvaluationTextValue {
    return new OnnEventEvaluationTextValue({
      ...this,
      value: params.value,
      updatedEmployeeId: params.updatedEmployeeId,
      updatedAt: new Date(),
    });
  }

  getFieldId() {
    return this.onnEventEvaluationTextFieldId;
  }
}
