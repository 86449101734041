import { z } from "zod";

export const OnnEventEvaluationFieldAccessControlTypeSchema = z.enum([
  "ALL",
  "ADMIN_ONLY",
  "EDIT_ADMIN_ONLY",
]);

export type OnnEventEvaluationFieldAccessControlTypeType = `${z.infer<
  typeof OnnEventEvaluationFieldAccessControlTypeSchema
>}`;

export default OnnEventEvaluationFieldAccessControlTypeSchema;
