import { Box, Grid } from "@material-ui/core";
import React, { FC, memo } from "react";

import { NewGraduateTab } from "./parts/NewGraduateTab";
import { useNewGraduateTable } from "./useNewGraduateTable";
import { useViewModel } from "./useViewModel";

import { EditOnnEventEvaluationDrawer } from "~/components/domains/events/EditOnnEventEvaluation/EditOnnEventEvaluationDrawer/EditOnnEventEvaluationDrawer";
import { EditOnnEventEvaluationDrawerProvider } from "~/components/domains/events/EditOnnEventEvaluation/EditOnnEventEvaluationDrawer/providers/EditOnnEventEvaluationDrawerProvider";
import { Icon, Loading, TitleWithCount, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const NewGraduateTopIndex: FC = memo(() => {
  // Note: Space切り替え時にページが持っているstateをリセットするため、Space名をkeyに置く
  // See: https://ja.react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
  const { currentSpace } = useCurrentSpace();

  const {
    searchNewGraduatesPerPageData,
    searchResultAllIds,
    keywordsString,
    keywordsStringSearchErrorText,
    conditions,
    logicType,
    validConditionsCount,
    searchResultCount,
    onSearchConfirm,
    onResetSearchConditions,
    onChangeKeywordsString,
    onLoadMore,
    mutateAllPagesOfSearchNewGraduatesPerPage,
  } = useNewGraduateTable();

  if (
    searchNewGraduatesPerPageData == null ||
    searchResultCount == null ||
    searchResultAllIds == null
  ) {
    return <Loading size="large" fullHeight />;
  }

  return (
    <div key={currentSpace.id}>
      <EditOnnEventEvaluationDrawerProvider>
        <EditOnnEventEvaluationDrawer />
        <NewGraduateTopIndexCore
          searchNewGraduatesPerPageData={searchNewGraduatesPerPageData}
          searchResultAllIds={searchResultAllIds}
          keywordsString={keywordsString}
          keywordsStringSearchErrorText={keywordsStringSearchErrorText}
          onChangeKeywordsString={onChangeKeywordsString}
          conditions={conditions}
          logicType={logicType}
          validConditionsCount={validConditionsCount}
          searchResultCount={searchResultCount}
          onSearchConfirm={onSearchConfirm}
          onResetSearchConditions={onResetSearchConditions}
          onLoadMore={onLoadMore}
          mutateAllPagesOfSearchNewGraduatesPerPage={mutateAllPagesOfSearchNewGraduatesPerPage}
        />
      </EditOnnEventEvaluationDrawerProvider>
    </div>
  );
});

const NewGraduateTopIndexCore = memo(
  ({
    searchNewGraduatesPerPageData,
    searchResultAllIds,
    keywordsString,
    keywordsStringSearchErrorText,
    onChangeKeywordsString,
    conditions,
    logicType,
    validConditionsCount,
    searchResultCount,
    onSearchConfirm,
    onResetSearchConditions,
    onLoadMore,
    mutateAllPagesOfSearchNewGraduatesPerPage,
  }: ReturnType<typeof useNewGraduateTable> & {
    searchNewGraduatesPerPageData: NonNullable<
      ReturnType<typeof useNewGraduateTable>["searchNewGraduatesPerPageData"]
    >;
    searchResultCount: NonNullable<ReturnType<typeof useNewGraduateTable>["searchResultCount"]>;
    searchResultAllIds: NonNullable<ReturnType<typeof useNewGraduateTable>["searchResultAllIds"]>;
  }) => {
    const {
      onResetSelectedNewGraduateIds,
      onSelectNewGraduateId,
      selectedNewGraduateIds,
      toggleSelectAll,
      allSelectionState,
      onConfirmAddTags,
      handleEmployeeInformationSettingButton,
    } = useViewModel({
      searchResultAllIds,
      mutateAllPagesOfSearchNewGraduatesPerPage,
    });
    const { currentUser } = useCurrentUser();

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <TitleWithCount label="候補者" count={searchResultCount} />
              {currentUser.isAdmin() && (
                <CustomEmployeeInformationSettingButton
                  onClick={handleEmployeeInformationSettingButton}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <NewGraduateTab
          searchResultCount={searchResultCount}
          searchNewGraduatesPerPageData={searchNewGraduatesPerPageData}
          mutateAllPagesOfSearchNewGraduatesPerPage={mutateAllPagesOfSearchNewGraduatesPerPage}
          keywordsString={keywordsString}
          keywordsStringSearchErrorText={keywordsStringSearchErrorText}
          onChangeKeywordsString={onChangeKeywordsString}
          conditions={conditions}
          logicType={logicType}
          validConditionsCount={validConditionsCount}
          onSearchConfirm={onSearchConfirm}
          onResetSearchConditions={onResetSearchConditions}
          onResetSelectedNewGraduateIds={onResetSelectedNewGraduateIds}
          onSelectNewGraduateId={onSelectNewGraduateId}
          selectedNewGraduateIds={selectedNewGraduateIds}
          toggleSelectAll={toggleSelectAll}
          allSelectionState={allSelectionState}
          onConfirmAddTags={onConfirmAddTags}
          onLoadMore={onLoadMore}
        />
      </>
    );
  }
);

const CustomEmployeeInformationSettingButton: FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "80px",
        height: "40px",
      }}
      style={{
        cursor: "pointer",
        columnGap: "4px",
      }}
      onClick={onClick}
    >
      <Icon icon="setting" size="ssm" color="grey400" />
      <Typography variant="body2" color="textSecondary" bold>
        設定
      </Typography>
    </Box>
  );
};
