import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT VALUE SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationSingleSelectFieldId: z.string(),
  onnEventEvaluationSingleSelectOptionId: z.string().nullish(),
  employeeId: z.string(),
  createdEmployeeId: z.string().nullish(),
  updatedEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationSingleSelectValue = z.infer<
  typeof OnnEventEvaluationSingleSelectValueSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectValueOptionalDefaultsSchema =
  OnnEventEvaluationSingleSelectValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationSingleSelectValueOptionalDefaults = z.infer<
  typeof OnnEventEvaluationSingleSelectValueOptionalDefaultsSchema
>;

export default OnnEventEvaluationSingleSelectValueSchema;
