import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK RELATION SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  onnEventEvaluationRankId: z.string().nullish(),
  updatedEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationRankRelation = z.infer<typeof OnnEventEvaluationRankRelationSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION RANK RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationRankRelationOptionalDefaultsSchema =
  OnnEventEvaluationRankRelationSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationRankRelationOptionalDefaults = z.infer<
  typeof OnnEventEvaluationRankRelationOptionalDefaultsSchema
>;

export default OnnEventEvaluationRankRelationSchema;
