import { Box, Menu, MenuItem } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { AnyTarget } from "../../../../types/condition";
import { GroupMenuItem } from "../../../../types/group";

import { Typography } from "~/components/uiParts";

export const GroupMenu = ({
  group,
  anchorEl,
  onClose,
  onClickTarget,
}: {
  group: GroupMenuItem[] | undefined;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onClickTarget: (target: AnyTarget, employeeInformationId?: string) => void;
}) => {
  if (!group) return <></>;

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      MenuListProps={{
        style: {
          minWidth: "240px",
          width: "unset",
          padding: "8px",
        },
      }}
    >
      <Stack rowGap={1}>
        {group.map((item) => (
          <Box key={item.employeeInformationId || item.target}>
            <StyledMenuItem
              key={item.target}
              onClick={() => onClickTarget(item.target, item.employeeInformationId)}
            >
              <Typography variant="body2" color="textPrimary">
                {item.label}
              </Typography>
            </StyledMenuItem>
          </Box>
        ))}
      </Stack>
    </Menu>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 8px;
  }
`;
