import { IRecruitmentProcessRecordSchema } from "./schema";

export type RecruitmentProcessStatus = Exclude<
  IRecruitmentProcessRecordSchema["progressStatus"],
  undefined
>;
export const progressStatusLabelMap: Record<RecruitmentProcessStatus, string> = {
  PASSED: "合格",
  WITHDREW: "辞退",
  REJECTED: "不採用",
  CANCELED: "取消し",
} as const;
