import { Box, Stack } from "@mui/material";
import { AnyOnnEventEvaluationField, OnnEvent, OnnEventEvaluationRankV2 } from "@onn/common";
import React, { FC, useCallback, useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OnnEventEvaluationFormForm } from "./components/OnnEventEvaluationFormForm/OnnEventEvaluationFormForm";
import { OnnEventEvaluationRankForm } from "./components/OnnEventEvaluationRankForm/OnnEventEvaluationRankForm";
import { useForm } from "./hooks/form/useForm";

import { AnyOnnEventEvaluationFieldWithValueCount } from "./types";

import { Loading, Typography } from "~/components/uiParts";
import { ActionFooter } from "~/components/uiParts/ActionFooter";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventEvaluationSetting } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationSetting";
import { useOnnEventEvaluationWithEvaluationValueCount } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationWithEvaluationValueCount";
import { useOnnEventEvaluationRanksPerOnnEvent } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanksPerOnnEvent";
import { NotFound } from "~/pages/NotFound";
import { captureException } from "~/util";

export const Page = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { data: onnEventData, isLoading: isLoadingOnnEvent } = useOnnEvent(onnEventId);
  const {
    onnEventEvaluationSettingWithValueCount = [],
    isLoading: isLoadingOnnEventEvaluationSettingWithValueCount,
  } = useOnnEventEvaluationSettingWithValueCount(onnEventId || "");
  const { data: rankData, isLoading: isLoadingRankData } = useOnnEventEvaluationRanksPerOnnEvent({
    onnEventId: onnEventId || "",
    includeUndeletableRankIds: true,
  });

  const isLoading =
    isLoadingOnnEvent || isLoadingOnnEventEvaluationSettingWithValueCount || isLoadingRankData;

  if (isLoading) return <Loading size="large" fullHeight />;
  if (!onnEventId || !onnEventData) {
    return <NotFound />;
  }
  return (
    <PageCore
      onnEventEvaluationFields={onnEventEvaluationSettingWithValueCount || []}
      onnEventId={onnEventId}
      onnEventType={onnEventData.onnEvent.type}
      onnEventEvaluationRanks={rankData?.onnEventEvaluationRanks || []}
      undeletableOnnEventEvaluationRankIds={rankData?.undeletableOnnEventEvaluationRankIds || []}
    />
  );
};

const PageCore: FC<{
  onnEventEvaluationFields: AnyOnnEventEvaluationFieldWithValueCount[];
  onnEventId: string;
  onnEventType: OnnEvent["type"];
  onnEventEvaluationRanks: OnnEventEvaluationRankV2[];
  undeletableOnnEventEvaluationRankIds: string[];
}> = ({
  onnEventEvaluationFields,
  onnEventId,
  onnEventType,
  onnEventEvaluationRanks,
  undeletableOnnEventEvaluationRankIds,
}) => {
  const onnEventEvaluationFileField = useOnnEventEvaluationFileField(onnEventEvaluationFields);
  const { RHForm, isSubmitButtonDisabled, submit } = useForm({
    onnEventEvaluationRanks,
    onnEventEvaluationFields,
    onnEventId,
    onnEventEvaluationFileField,
    onnEventType,
  });

  const { onClickCancel } = useOnClickCancel({ onnEventId });

  const { isSubmitting } = RHForm.formState;

  return (
    <FormProvider {...RHForm}>
      {/* アクションフッター分の padding-bottom 80px */}
      <Stack pb={"80px"}>
        <Box mb={4}>
          <Typography variant="h4" color="textPrimary" bold>
            評価フォーム設定
          </Typography>
        </Box>
        <Stack spacing="32px">
          <OnnEventEvaluationRankForm
            undeletableOnnEventEvaluationRankIds={undeletableOnnEventEvaluationRankIds}
          />
          <OnnEventEvaluationFormForm onnEventEvaluationFileField={onnEventEvaluationFileField} />
        </Stack>
        <ActionFooter
          cancelButtonText={"キャンセル"}
          submitButtonText={"変更を保存"}
          onClickCancel={onClickCancel}
          onClickConfirmSave={submit}
          isSaving={isSubmitting}
          isDisabledSaveButton={isSubmitButtonDisabled}
        />
      </Stack>
    </FormProvider>
  );
};

/**
 * アクションフッターの「キャンセル」をクリックした時の処理をまとめたhooks
 */
const useOnClickCancel = ({ onnEventId }: { onnEventId: string }) => {
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from_page");
  const navigation = useNavigate();

  const onClickCancel = useCallback(() => {
    if (fromPage === "detail") {
      navigation(`/events/${onnEventId}`);
    } else {
      navigation(`/events`);
    }
  }, [fromPage, navigation, onnEventId]);

  return { onClickCancel };
};

/**
 * ファイルタイプフィールドを取得するhooks
 *
 *  - ファイルタイプフィールドが存在しない場合にはエラーを通知する
 */
const useOnnEventEvaluationFileField = (onnEventEvaluationFields: AnyOnnEventEvaluationField[]) => {
  const params = useParams();
  const onnEventId = params.id;

  const onnEventEvaluationFileFields = onnEventEvaluationFields.filter(
    (f) => f.type === "FileField"
  );
  const onnEventEvaluationFileField = onnEventEvaluationFileFields[0];

  // NOTE: 1イベントにつき、関連ファイルのFieldは1つだけ存在する想定のため、それ以外の場合はエラーを通知する
  useEffect(() => {
    if (onnEventEvaluationFileFields.length > 1) {
      captureException({
        error: new Error("関連ファイルのFieldが複数存在します"),
        extras: { onnEventEvaluationFields, onnEventId },
        tags: { type: "useOnnEventEvaluationFileField" },
      });
    }
    if (!onnEventEvaluationFileField) {
      captureException({
        error: new Error("関連ファイルのFieldが見つかりません"),
        extras: { onnEventEvaluationFields, onnEventId },
        tags: { type: "useOnnEventEvaluationFileField" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onnEventEvaluationFields.length, onnEventId]);

  return onnEventEvaluationFileField;
};

const useOnnEventEvaluationSettingWithValueCount = (onnEventId: string) => {
  const { data: onnEventEvaluationSetting, isLoading: isLoadingEvaluationSetting } =
    useOnnEventEvaluationSetting({
      onnEventId: onnEventId || "",
    });
  const {
    data: onnEventEvaluationWithEvaluationValueCount = [],
    isLoading: isLoadingOnnEventEvaluationWithEvaluationValueCount,
  } = useOnnEventEvaluationWithEvaluationValueCount({
    onnEventId: onnEventId || "",
  });

  const isLoading =
    isLoadingEvaluationSetting || isLoadingOnnEventEvaluationWithEvaluationValueCount;

  const onnEventEvaluationSettingWithValueCount =
    onnEventEvaluationSetting?.onnEventEvaluationFields.map((field) => {
      const valueCount =
        onnEventEvaluationWithEvaluationValueCount.find((v) => v.fieldId === field.id)?.count || 0;
      return Object.assign(field, { valueCount });
    });

  return {
    onnEventEvaluationSettingWithValueCount,
    isLoading,
  };
};
