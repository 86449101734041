import { ChangeRecruitmentStatusActionSetting } from "@onn/common";
import React, { useContext } from "react";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

export const ConfirmChangeRecruitmentStatusCheckBox = ({
  executableChangeRecruitmentStatusActionSettings,
  isExecuteChangeRecruitmentStatusConfirmed,
  setIsExecuteChangeRecruitmentStatusConfirmed,
}: {
  executableChangeRecruitmentStatusActionSettings: ExcludeMethods<ChangeRecruitmentStatusActionSetting>[];
  isExecuteChangeRecruitmentStatusConfirmed: boolean;
  setIsExecuteChangeRecruitmentStatusConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { recruitmentStatuses, scenarios } = useContext(ScenarioContext);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isExecuteChangeRecruitmentStatusConfirmed}
          onChange={(event) => {
            setIsExecuteChangeRecruitmentStatusConfirmed(event.target.checked);
          }}
        />
      }
      label={
        <Typography variant="caption" color="textSecondary">
          この評価で保存した場合、候補者は
          {executableChangeRecruitmentStatusActionSettings
            .flatMap((setting) => {
              const recruitmentStatus = recruitmentStatuses.find(
                (status) => status.id === setting.recruitmentStatusId
              );
              if (!recruitmentStatus) {
                return [];
              }

              const scenario = scenarios.find(
                (scenario) => scenario.id === recruitmentStatus.scenarioId
              );
              if (!scenario) {
                return [];
              }

              const statusLabel = recruitmentStatus.label;
              const scenarioName = scenario.name;

              return `「${scenarioName}／${statusLabel}」`;
            })
            .join("と")}
          に変更されます
        </Typography>
      }
    />
  );
};
