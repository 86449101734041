import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { EmployeeInformationDateTypeFieldCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { CommonProps } from "./type";

import { DatePickerV2 } from "~/components/uiParts";

const TARGET = "employeeInformationDateTypeField";

type Props = CommonProps<EmployeeInformationDateTypeFieldCondition>;
export const EmployeeInformationDateFieldConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
          employeeInformationId={condition.employeeInformationId}
        />
      </Box>
      <Box flex={9}>
        <StyledDatePicker
          fullWidth
          value={condition.searchDate || null}
          onChange={(date) => {
            onChangeCondition(index, {
              ...condition,
              searchDate: date || undefined,
            });
          }}
        />
      </Box>
    </Box>
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    padding: 8px 12px 8px 12px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;
