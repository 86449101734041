import {
  generatePagePath,
  NewGraduateToDisplay,
  OnnFormTaskAnswer,
  OnnTaskDeliveryCancellationLog,
  RecruitmentProcessRecordOnnTaskItem,
} from "@onn/common";
import { compact, orderBy } from "lodash";
import { useCallback } from "react";

import { RecruitmentProcessRecordOnnTaskItemRowProps } from "../components/RecruitmentProcessArea/RecruitmentProcessTable/body/rows/RecruitmentProcessRecordOnnTaskItemRow/RecruitmentProcessRecordOnnTaskItemRow";

import { useCurrentUser } from "~/hooks/employee";
import { OnnTaskWithRelation } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const useConvertToOnnTaskItemRowProps = () => {
  const { generateOnnTaskTitleCell } = useGenerateOnnTaskTitleCell();
  const { calculateRowUpdatedAt } = useCalculateRowUpdatedAt();

  const convertToOnnTaskItemRowProps = useCallback(
    ({
      onnTaskWithRelation,
      newGraduate,
      processRecordItem,
    }: {
      onnTaskWithRelation: OnnTaskWithRelation;
      newGraduate: NewGraduateToDisplay;
      processRecordItem: RecruitmentProcessRecordOnnTaskItem;
    }): RecruitmentProcessRecordOnnTaskItemRowProps => {
      return {
        type: "onnTask",
        onnTaskId: onnTaskWithRelation.onnTask.id,
        titleCell: generateOnnTaskTitleCell({
          employeeId: newGraduate.id,
          onnTaskId: onnTaskWithRelation.onnTask.id,
          onnTaskAnswer: onnTaskWithRelation.answer,
          onnTaskTitle: onnTaskWithRelation.onnTask.title,
        }),
        statusCell: {
          isAlreadyAnswered: !!onnTaskWithRelation.answer?.isAnswered(),
          lastReadAt: onnTaskWithRelation.answer?.lastReadAt || undefined,
        },
        assigneeCell: {
          iconUrl: newGraduate.profileIconImageUrl,
          name: newGraduate.getName(),
          isShowNoneCell: !onnTaskWithRelation.answer, // NOTE: 未配信の場合はNoneCellを表示する
        },
        relatedFilesCell: {
          filePaths: onnTaskWithRelation.answer?.extractRelatedFilePaths() || [],
        },
        relatedInformationCell: {
          deadlineDate: onnTaskWithRelation.onnTask.deadlineDate || undefined,
          isShowNoneCell: !onnTaskWithRelation.answer, // NOTE: 未配信の場合はNoneCellを表示する
        },
        updatedAtCell: {
          updatedAt: calculateRowUpdatedAt({
            onnFormTaskAnswer: onnTaskWithRelation.answer,
            latestDeliveryCancellationLog: onnTaskWithRelation.latestDeliveryCancellationLog,
            processRecordItem,
          }),
        },
      };
    },
    [calculateRowUpdatedAt, generateOnnTaskTitleCell]
  );

  return { convertToOnnTaskItemRowProps };
};

const useGenerateOnnTaskTitleCell = () => {
  const { currentUser } = useCurrentUser();

  const generateOnnTaskTitleCell = useCallback(
    ({
      employeeId,
      onnTaskId,
      onnTaskAnswer,
      onnTaskTitle,
    }: {
      employeeId: string;
      onnTaskId: string;
      onnTaskAnswer?: OnnFormTaskAnswer;
      onnTaskTitle: string;
    }) => {
      const href = onnTaskAnswer
        ? generatePagePath.employee.taskTab(employeeId, { onnTaskId })
        : generatePagePath.onnTask.detail(onnTaskId);

      return {
        href,
        onnTaskTitle,
        disableLink: currentUser.isOnlyInterviewer(),
      };
    },
    [currentUser]
  );

  return { generateOnnTaskTitleCell };
};

const useCalculateRowUpdatedAt = () => {
  /**
   * 更新日時列の値を計算する
   */
  const calculateRowUpdatedAt = useCallback(
    ({
      onnFormTaskAnswer,
      latestDeliveryCancellationLog,
      processRecordItem,
    }: {
      onnFormTaskAnswer?: OnnFormTaskAnswer;
      latestDeliveryCancellationLog?: OnnTaskDeliveryCancellationLog;
      processRecordItem: RecruitmentProcessRecordOnnTaskItem;
    }) => {
      const targets = compact([
        onnFormTaskAnswer
          ? onnFormTaskAnswer.lastAnsweredAt
            ? onnFormTaskAnswer.lastAnsweredAt
            : onnFormTaskAnswer.createdAt
          : null,

        latestDeliveryCancellationLog?.createdAt,
        processRecordItem.createdAt,
      ]);

      return orderBy(targets, (d) => d.getTime(), "desc")[0];
    },
    []
  );

  return { calculateRowUpdatedAt };
};
