import { z } from "zod";

import { OnnEventEvaluationRankRelationSchema } from "../../../../../domain/_gen/zodSchema";

export const onnEventEvaluationRankRelationSchema = OnnEventEvaluationRankRelationSchema.merge(
  z.object({
    onnEventEvaluationRankId: z.string().optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationRankRelation
  extends z.infer<typeof onnEventEvaluationRankRelationSchema> {}
