import { EmployeeInformation, NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import { InformationValueViewer } from "./parts/InformationValueViewer";
import { ViewerAndEditorManagerRenderer } from "./parts/ViewerAndEditorManagerRenderer";

import { EmployeeDefaultInformationTable } from "~/components/domains/employees/EmployeeDefaultInformationTable/EmployeeDefaultInformationTable";
import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useClipboard } from "~/hooks/shared";

type Props = {
  newGraduate: NewGraduateToDisplay;
  employeeInformation?: EmployeeInformation | null;
};

export const EmployeeInformationTable: FC<Props> = ({ newGraduate, employeeInformation }) => {
  const { handleCopy } = useClipboard();

  const canEditEmail = newGraduate.canEditEmail();

  return (
    <EmployeeDefaultInformationTable
      nameCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="name"
          text="氏名"
          data={newGraduate}
        />
      }
      kanaNameCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="kanaName"
          text="フリガナ"
          data={employeeInformation?.value.kanaName}
        />
      }
      genderCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="gender"
          text="性別"
          data={employeeInformation?.value.gender}
        />
      }
      dateOfBirthCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="dateOfBirth"
          text="生年月日"
          data={employeeInformation?.value.dateOfBirth}
        />
      }
      emailCellComponent={
        canEditEmail ? (
          <ViewerAndEditorManagerRenderer
            employeeId={newGraduate.id}
            type="email"
            text="メールアドレス"
            data={newGraduate.email}
          />
        ) : newGraduate.email ? (
          <InformationValueViewer
            label={<Typography variant="body2">{newGraduate.email}</Typography>}
            onClickCopy={() => handleCopy(newGraduate.email, "メールアドレス")}
          />
        ) : (
          <Typography variant="body2" style={{ color: theme.palette.grey[200] }}>
            未登録
          </Typography>
        )
      }
      phoneNumberCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="phoneNumber"
          text="電話番号（携帯）"
          data={employeeInformation?.value.phoneNumber}
        />
      }
      homePhoneNumberCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="homePhoneNumber"
          text="電話番号（自宅）"
          data={employeeInformation?.value.homePhoneNumber}
        />
      }
      graduationYearAndMonthCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="graduationYearAndMonth"
          text="卒業年月"
          data={employeeInformation?.value.graduationYearAndMonth}
        />
      }
      affiliationCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="affiliation"
          text="所属"
          data={employeeInformation?.value.affiliation}
        />
      }
      addressCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="address"
          text="自宅(現住所)"
          data={employeeInformation?.value.address}
        />
      }
      hometownAddressCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="hometownAddress"
          text="帰省先住所"
          data={employeeInformation?.value.hometownAddress}
        />
      }
      uniqueIdCellComponent={
        <InformationValueViewer
          label={
            newGraduate.uniqueId ? (
              <Typography variant="body2">{newGraduate.uniqueId}</Typography>
            ) : undefined
          }
          onClickCopy={() => handleCopy(newGraduate.uniqueId, "Onn 固定ID")}
        />
      }
      externalIdCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="externalId"
          text="予備ID"
          data={employeeInformation?.value.externalId}
        />
      }
      resumeFilePathsCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="resumeFilePaths"
          text="履歴書"
          data={employeeInformation?.value.resumeFilePaths}
        />
      }
      entrySheetFilePathsCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="entrySheetFilePaths"
          text="エントリーシート"
          data={employeeInformation?.value.entrySheetFilePaths}
        />
      }
      spiFilePathsCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="spiFilePaths"
          text="SPI"
          data={employeeInformation?.value.spiFilePaths}
        />
      }
      offerAcceptanceDeadlineCellComponent={
        <ViewerAndEditorManagerRenderer
          employeeId={newGraduate.id}
          type="offerAcceptanceDeadline"
          text="内定承諾期日"
          data={employeeInformation?.value.offerAcceptanceDeadline}
        />
      }
    />
  );
};
