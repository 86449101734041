import { Box, Stack } from "@mui/material";
import React from "react";

import { Checkbox, TooltipWhenTextTruncatedOneLine, Typography } from "~/components/uiParts";

export type MenuItem<T> = {
  key: T;
  label: string;
  isSelected: boolean;
  indeterminate: boolean;
  sampleValue?: string;
};

type Props<T> = {
  toggleIsSelected: (key: T) => void;
  menuItem: MenuItem<T>;
  bottomNode?: React.ReactNode;
};

export const SelectConditionMenuItem = <T extends string>({
  toggleIsSelected,
  menuItem,
  bottomNode,
}: Props<T>) => {
  return (
    <Stack
      py="24px"
      pr="16px"
      pl="40px"
      width="100%"
      onClick={() => toggleIsSelected(menuItem.key)}
      sx={{
        cursor: "pointer",
        borderTop: ({ palette }) => `1px solid ${palette.grey[100]}`,
      }}
    >
      <Box display="flex" columnGap="8px" alignItems="center">
        <Checkbox
          checked={menuItem.isSelected}
          indeterminate={menuItem.indeterminate}
          style={{ padding: 0 }}
        />
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          width="calc(100% - 20px)" // checkboxの分を引く
          position="relative"
        >
          <Typography variant="body2" style={{ width: "50%" }}>
            {menuItem.label}
          </Typography>
          {menuItem.sampleValue && (
            <TooltipWhenTextTruncatedOneLine
              typographyProps={{
                variant: "body2",
                color: "textSecondary",
                style: { width: "50%" },
              }}
              text={`例) ${menuItem.sampleValue}`}
            />
          )}
        </Box>
      </Box>
      {bottomNode}
    </Stack>
  );
};
