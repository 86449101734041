import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateOnnEventEvaluationSetting } from "./useOnnEventEvaluationSetting";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/onn-event-evaluations/settings"]["POST"];

export const useEditOnnEventEvaluationSetting = () => {
  const { mutateOnnEventEvaluationSetting } = useMutateOnnEventEvaluationSetting();

  const editOnnEventEvaluationSetting = useCallback(
    async (body: EndPoint["body"]) => {
      await apiClient.post(`/api/onn-event-evaluations/settings`, body);
      mutateOnnEventEvaluationSetting({ onnEventId: body.onnEventId });
    },
    [mutateOnnEventEvaluationSetting]
  );

  return { editOnnEventEvaluationSetting };
};
