import { FormControl, FormHelperText, MenuItem, Select, SelectProps } from "@mui/material";
import React, { ComponentProps, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { TooltipWhenTextTruncatedOneLine } from "../TooltipWhenTextTruncatedOneLine";

import { Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";
type IconProps = ComponentProps<typeof Icon>;

// NOTE: 必要になったら渡せる項目を増やす
type Props<T extends string | number> = {
  selected?: T;
  menuItems: {
    value: T;
    name: string;
    content?: ReactNode;
    disabled?: boolean;
  }[];
  errorText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  labelWhenNoSelected?: string;
  onChange: SelectProps<T>["onChange"];
  className?: string; // NOTE: styled-components でスタイルを上書きするためのクラス名
} & Partial<Pick<IconProps, "icon">>;

export const SelectFormV2 = <T extends string | number>({
  selected,
  menuItems,
  errorText,
  fullWidth,
  disabled,
  labelWhenNoSelected,
  onChange,
  className,
  icon,
}: Props<T>) => {
  const renderValue = useCallback(
    (value: SelectProps<T>["value"]) => {
      const menuItem = menuItems.find((v) => v.value === value);
      const shouldDisplayLabelWhenNoSelected = !(value && menuItem?.name) && !!labelWhenNoSelected;

      return (
        menuItem?.content || (
          <TooltipWhenTextTruncatedOneLine
            typographyProps={{
              variant: "body2",
              color: shouldDisplayLabelWhenNoSelected ? "textSecondary" : "textPrimary",
              style: { textAlign: "left" },
            }}
            text={(value && menuItem?.name) || labelWhenNoSelected || "-"}
          ></TooltipWhenTextTruncatedOneLine>
        )
      );
    },
    [menuItems, labelWhenNoSelected]
  );

  return (
    <StyledFormControl
      variant="outlined"
      error={Boolean(errorText)}
      fullWidth={fullWidth}
      disabled={disabled}
      className={className}
    >
      <Select<T>
        displayEmpty
        fullWidth
        startAdornment={icon && <Icon icon={icon} size="sm" color="grey" />}
        renderValue={renderValue}
        value={selected}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: "8px",
            },
          },
        }}
        sx={{
          "& .MuiSelect-icon": {
            color: theme.palette.grey[400], // NOTE: 逆三角形のアイコンの色を設定
          },
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            value={item.value}
            key={index}
            disabled={item.disabled}
            style={{
              height: "40px",
            }}
          >
            {item.content ? (
              item.content
            ) : (
              <Typography variant="body2" color="textPrimary">
                {item.name}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  .MuiInputBase-formControl {
    height: 40px;
  }
  .MuiSelect-select {
    height: 24px;
    padding: 8px 16px;
  }
  .MuiFormHelperText-contained {
    font-size: 10px;
  }
`;
