import {
  OnnEventEvaluationFieldAccessControlType,
  onnEventEvaluationFieldAccessControlTypesTuple,
} from "@onn/common";
import React, { ComponentProps } from "react";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

const menuItems = onnEventEvaluationFieldAccessControlTypesTuple.map((accessControlType) => {
  let name;
  switch (accessControlType) {
    case "ALL":
      name = "閲覧：全員｜編集：全員";
      break;
    case "ADMIN_ONLY":
      name = "閲覧：管理者のみ｜編集：管理者のみ";
      break;
    case "EDIT_ADMIN_ONLY":
      name = "閲覧：全員｜編集：管理者のみ";
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = accessControlType;
      return accessControlType;
  }
  return {
    value: accessControlType,
    name,
  };
});

type AccessControlTypesSelectFormProps = Omit<
  ComponentProps<typeof SelectFormV2<OnnEventEvaluationFieldAccessControlType>>,
  "menuItems"
>;

export const AccessControlTypesSelectForm: React.FC<AccessControlTypesSelectFormProps> = (
  props
) => {
  return (
    <SelectFormV2<OnnEventEvaluationFieldAccessControlType>
      {...props}
      fullWidth
      menuItems={menuItems}
    />
  );
};
