import { APISchema } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-evaluations/count"]["GET"];

const generateKey = (arg: { tenantId: string; onnEventId: string }) => {
  return {
    endpoint: "/api/onn-event-evaluations/count",
    ...arg,
  } as const;
};
export const useOnnEventEvaluationWithEvaluationValueCount = ({
  onnEventId,
}: {
  onnEventId: string;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
    }),
    async ({ endpoint, onnEventId }) => {
      const query: Endpoint["query"] = { onnEventId };
      const response = await apiClient.get(endpoint, query);
      return response.data;
    }
  );
};
