import { APISchema, BriefingSessionCategory, OnnEvent } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/onn-events/id"]["GET"];

const generateKey = (onnEventId?: string) => {
  if (!onnEventId) return null;
  return {
    endpoint: "/api/onn-events/id",
    onnEventId,
  } as const;
};

export const useOnnEvent = (onnEventId?: string) => {
  return useSWR(generateKey(onnEventId), async ({ endpoint, onnEventId }) => {
    const requestQuery: EndPoint["query"] = {
      id: onnEventId,
    };
    const response = await apiClient.get(endpoint, requestQuery);

    return {
      onnEvent: new OnnEvent(response.onnEvent),
      briefingSessionCategories: response.briefingSessionCategories?.map(
        (briefingSessionCategorie) => new BriefingSessionCategory(briefingSessionCategorie)
      ),
    };
  });
};

export const mutateOnnEvent = (onnEventId: string) => {
  mutate(generateKey(onnEventId));
};
